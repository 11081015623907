import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from "./screens/login";
import Recovery from './screens/recovery';
import NewPassword from './screens/recovery/new-password';
import Home from './screens/home';
import Layout from './screens/layout';
import Users from './screens/users';
import Changepass from './screens/changepass';
import Notifications from './screens/ddjj';
import Documents from './screens/ddjj/documents';
import Funds from './screens/setting/funds';
import Tickers from './screens/setting/tickers';
import Accounts from "./screens/setting/accounts";
import Operations from './screens/operations/operations';
import Reports from "./screens/reports/reports";
import ReportsMiddle from "./screens/reports/reports-middle"
import MesaDashboard from "./screens/mesa/mesa-dashboard";
import MesaOperations from "./screens/mesa/mesa-operations";
import MesaDayOperations from "./screens/mesa/mesa-day-operations";
import MesaEscoOperation from "./screens/mesa/mesa-esco-operation";

import { MyContext } from "./context/MyContext";


function App() {
  const userProfile = localStorage.getItem("userProfile");
  const [toggleMenu, setToggleMenu] = useState(true);
  // if (!userProfile) { navigate("/") }
  return (
    <div className="App">
      { !userProfile ? 
        <Router>
          <Routes>
            <Route path='/' element={<Login/>}></Route>
            <Route path='/register' element={<Recovery/>}></Route>
            <Route path='/newpassword' element={<NewPassword/>}></Route>
          </Routes>
        </Router>
      :
      <MyContext.Provider value={{ toggleMenu, setToggleMenu }}>
        <Router>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route element={<Home />} />
              <Route path="users" element={<Users />} />
              <Route path="changepass" element={<Changepass />} />
              <Route path="notifications" element={<Notifications />} />
              <Route path="documents" element={<Documents />} />
              <Route path="operations" element={<Operations />} />
              {/* MESA */}
              <Route index path="mesaDashboard" element={<MesaDashboard />} />
              <Route path="mesaOperations" element={<MesaOperations />} />
              <Route path="mesaDayOperations" element={<MesaDayOperations />} />
              <Route path="mesaEscoOperations" element={<MesaEscoOperation />} />
              {/* REPORTS */}
              <Route path="reportOperation" element={<Reports />} />
              <Route path="reportOperationMiddle" element={<ReportsMiddle />} />
              {/* SETTINGS */}
              <Route path="funds" element={<Funds />} />
              <Route path="tickers" element={<Tickers />} />
              <Route path="accounts" element={<Accounts />} />
            </Route>
          </Routes>
        </Router>
      </MyContext.Provider>
      }
    </div>
  );
}

export default App;
