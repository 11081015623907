import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom"
import Swal from "sweetalert2";
import axios from 'axios'
import { NumericFormat } from 'react-number-format';
import Loading from "../../components/loading";
import Sheet from '@mui/joy/Sheet';
import ModalViewDayBonos from "./modals/modalViewDayBonos"
import ModalViewDayObservations from "./modals/modalViewDayObservation";
import Pusher from 'pusher-js';
//SERVICES  
import OperationService from "../../services/operationService";
// import ListService from "../../services/listService";


function MesaDayOperationsBonos() {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const initialized = useRef(false)
    const [listOperations, setListOperations] = useState([])
    const [modalViewOpen, setModalViewOpen] = useState(false);
    const [modalViewObservationsOpen, setModalViewObservationsOpen] = useState(false);
    const [bonoSelected, setBonoSelected] = useState('');
    // const userProfile = JSON.parse(localStorage.getItem("userProfile"));

     // Search Form
     let defaultDate = new Date().toISOString().slice(0,10);
     const [searchModel, setSearchModel] = useState({
         account: '',
         status: '',
         dateFrom: defaultDate,
         dateTo: defaultDate,
         user: '',
     })
 

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true
            doSearch()

            const pusher = new Pusher(
                process.env.REACT_APP_PUSHER, 
                { cluster: process.env.REACT_APP_PUSHER_CLUSTER }
            )

            Pusher.log = (msg) => {
                console.log(msg);
              };

            const channel = pusher.subscribe('operations-channel')
            channel.bind('pending',function(data) {
                console.log("PENDING: ",data)
                // Code that runs when channel1 listens to a new message
            })
            channel.bind('blocked',function(data) {
                console.log("Block: ",data)
                // Code that runs when channel1 listens to a new message
            })

        }
    }, [false]);

    const doSearch = async (event) => {
        try {  
            setLoading(true)
            const response = await OperationService.getOperationsDay()
            setListOperations(response.data)            
        } catch (err) {
            if(err.response.status !== 401){
                Swal.fire({
                    title: 'Ooops?',
                    text: err.response.data.errors[0].detail,
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#f13939'
                })
            }else{
                localStorage.removeItem("userProfile");
                navigate('/')
                window.location.reload();
            }
        } finally{ setLoading(false) }
    }
    
    const doView = async (obj) => {
        if(obj !== null){
            setBonoSelected(obj)
            setModalViewOpen(true)
        }
    }

    const doConfirmOpe = async (obj) => {
        if(obj !== null){
            Swal.fire({
                title: 'Atención!',
                text: "Ud. esta seguro que quiere FINALIZAR la operación ?",
                icon: 'warning',
                confirmButtonText: "Continuar",
                confirmButtonColor: "#83766d",
                showCancelButton: true,
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) { doChangeStatus(obj, 3); }
            });
            
        }
    }

    const doCancelOpe = async (obj) => {
        if(obj !== null){
            if(obj !== null){
                Swal.fire({
                    title: 'Atención!',
                    text: "Ud. esta seguro que quiere RECHAZAR la operación ?",
                    icon: 'warning',
                    confirmButtonText: "Continuar",
                    confirmButtonColor: "#83766d",
                    showCancelButton: true,
                    reverseButtons: true
                }).then((result) => {
                    if (result.isConfirmed) { doChangeStatus(obj, 4); }
                });
                
            }
        }
    }

    const doUnlock = async (obj) => {
        if(obj !== null){
            Swal.fire({
                title: "Atención!",
                text: "Ud. esta seguro que quiere DESBLOQUEAR la operación ?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Aceptar",
                confirmButtonColor: "#83766d",
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) { doChangeStatus(obj, 1) }
            });
        }
    }

    const doNote = async (obj) => {
        if(obj !== null){
            setBonoSelected(obj)
            setModalViewObservationsOpen(true)
        }
    }

    const doChangeStatus = async (obj, statusid) => {
        if(obj !== null){
            try {
                setLoading(true)
                const response = await OperationService.putOperationStatus(obj.id,{operation_state_id: statusid})
            } catch (err) {
                if(err.response.status !== 401){
                    Swal.fire({
                        title: 'Ooops?',
                        text: err.response.data.errors[0].detail,
                        icon: 'error',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#f13939'
                    })
                }else{
                    localStorage.removeItem("userProfile");
                    navigate('/')
                    window.location.reload();
                }
            } finally{ setLoading(false) }
        }
    }

    if(loading){
        return(
            <Loading></Loading>
        )
    }


    return ( 
        <div className="row">

            <div className="row">
                <Sheet sx={{ height: 580, overflow: 'auto', backgroundColor: 'transparent', padding: '2px' }} className="table-responsive">
                    <table className="table table-striped table-hover" >
                        <thead style={{backgroundColor: '#FFFFFF'}}>
                            <tr className="text-nowrap">
                                <th>#</th>
                                <th>Comintente</th>
                                <th>Mercado</th>
                                <th>Precio</th>
                                <th>Tipo Operación</th>
                                <th>Plazo</th>
                                <th>Especie</th>
                                <th>Valor Nominal</th>
                                <th>Monto</th>
                                <th>Oficial</th>
                                <th>Estado</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody className="table-border-bottom-0">
                            {listOperations.map(operation => {
                                return (
                                    <tr key={operation.id}>  
                                        <td>{operation.id}</td>
                                        <td>
                                            <div className="d-flex justify-content-start align-items-center user-name">
                                                <div className="d-flex flex-column">
                                                    <a className="text-body text-truncate">
                                                        <span className="fw-medium">
                                                            {operation.account.name.length > 30 ?
                                                                `${operation.account.name.substring(0, 30)}...` : operation.account.name
                                                            }
                                                        </span>
                                                    </a>
                                                    <small className="text-muted"><span className="badge bg-label-dark">{operation.account.account}</span></small>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex justify-content-start align-items-center user-name">
                                                <div className="d-flex flex-column">
                                                    <a className="text-body text-truncate">
                                                        <span className="fw-medium">{operation.market.name}</span>
                                                    </a>
                                                    <small className="text-muted">
                                                        {operation.segment_id === 1 && <span className="badge bg-label-primary me-1">{operation.segment.name}</span>}
                                                        {operation.segment_id === 2 && <span className="badge bg-label-info me-1">{operation.segment.name}</span>}
                                                        {operation.segment_id === 3 && <span className="badge bg-label-secondary me-1">{operation.segment.name}</span>}
                                                    </small>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex justify-content-start align-items-center user-name">
                                                <div className="d-flex flex-column">
                                                    <a className="text-body text-truncate">
                                                        <span className="fw-medium">{operation.price_type.name}</span>
                                                    </a>
                                                    <small className="text-muted">
                                                        <NumericFormat 
                                                            displayType="text" 
                                                            value={operation.limit_price} 
                                                            decimalScale={4} 
                                                            allowLeadingZeros thousandSeparator="." 
                                                            decimalSeparator="," 
                                                            prefix={'$'}  
                                                        /> 
                                                    </small>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            {operation.operation_type_id === 1 && <span className="badge bg-label-success me-1">{operation.operation_type.name}</span>}
                                            {operation.operation_type_id === 2 && <span className="badge bg-label-danger me-1">{operation.operation_type.name}</span>}
                                        </td>
                                        <td>{operation.settlement_term.name}</td>
                                        <td>
                                            <div className="d-flex justify-content-start align-items-center user-name">
                                                <div className="d-flex flex-column">
                                                    <a className="text-body text-truncate">
                                                        <span className="fw-medium">{operation.ticker}</span>
                                                    </a>
                                                    <small className="text-muted"><span className="badge bg-label-dark">{operation.currency.name}</span></small>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="text-end">
                                            <NumericFormat 
                                                displayType="text" 
                                                value={operation.qty} 
                                                allowLeadingZeros thousandSeparator="." 
                                                decimalSeparator="," 
                                            /> 
                                        </td>
                                        <td className="text-end">
                                            <NumericFormat 
                                                displayType="text" 
                                                value={operation.price} 
                                                decimalScale={4} 
                                                allowLeadingZeros thousandSeparator="." 
                                                decimalSeparator="," 
                                                prefix={'$'}  
                                            /> 
                                        </td>
                                        <td>{operation.user.full_name}</td>
                                        <td>
                                            {operation.operation_state_id === 1 && <span className="badge bg-label-secondary me-1">{operation.operation_state.display_name}</span>}
                                            {operation.operation_state_id === 2 && <span className="badge bg-label-warning me-1">{operation.operation_state.display_name}</span>}
                                            {operation.operation_state_id === 3 && <span className="badge bg-label-success me-1">{operation.operation_state.display_name}</span>}
                                            {operation.operation_state_id === 4 && <span className="badge bg-label-danger me-1">{operation.operation_state.display_name}</span>}
                                        </td>
                                        <td  className="text-start">
                                            {operation.operation_state_id === 1 &&
                                                <a href="#" title="Tomar Operación" onClick={() => { doChangeStatus(operation, 2) } }>
                                                    <i className='bx bxs-hand'></i>
                                                </a> 
                                            }
                                            {operation.operation_state_id === 2 &&
                                                <>
                                                    <a href="#" title="Confirmar Operación" onClick={() => { doConfirmOpe(operation) } }>
                                                        <i className='bx bx-check-circle' style={{color:'green'}}></i>
                                                    </a> 
                                                    <a href="#" title="Rechazar Operación" onClick={() => { doCancelOpe(operation) } }>
                                                        <i className='bx bx-x-circle' style={{color:'red'}}></i>
                                                    </a> 
                                                </>
                                            }
                                            {operation.observations !== null &&
                                                <a href="#" title="Comentarios" onClick={() => { doNote(operation) } }>
                                                    <i className='bx bx-message-square-dots' ></i>
                                                </a> 
                                            }
                                        </td>
                                        <td className="text-end">
                                            <div className="dropdown">
                                                <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                                                    <i className="bx bx-dots-vertical-rounded" />
                                                </button>
                                                <div className="dropdown-menu">
                                                    <a className="dropdown-item" href="#"  onClick={() => {  doView(operation) } }>
                                                        <i className="bx bx-eyes me-1" /> Ver
                                                    </a>
                                                    {operation.operation_state_id === 2 &&
                                                        <>
                                                            <a className="dropdown-item" href="#"  onClick={() => { doUnlock(operation) } }>
                                                                Desbloquear
                                                            </a>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    )
                                })
                            }

                        </tbody>
                    </table>
                </Sheet>
            </div>

            { bonoSelected !== '' ?
                <>  
                    <ModalViewDayBonos 
                        handleOpen={setModalViewOpen} 
                        isOpen={modalViewOpen} 
                        selected={bonoSelected}
                        fundId={bonoSelected.id}
                    />

                    <ModalViewDayObservations 
                        handleOpen={setModalViewObservationsOpen} 
                        isOpen={modalViewObservationsOpen} 
                        selected={bonoSelected}
                        opeId={bonoSelected.id}
                    />
                </>
            
            :
                null
            }


        </div>
     );

}

export default MesaDayOperationsBonos;