import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom"
import Swal from "sweetalert2";
import axios from 'axios'
import moment from 'moment';
import statementService from "../../services/statementService";
import accountSeervice from "../../services/accountService";
import SendDocumentModal from "./sendDocumentModal";
import DocumentSearch from "./modals/documentSearch";


function Notifications() {

    const initSearch = {
        statement: '',
        noti: '',
        name : '',
        status : '',
        date : '',
    }
    
    const navigate = useNavigate();
    const [sending, setSending] = useState(false)
    const initialized = useRef(false)
    const [listAccounts, setListAccounts] = useState([])
    const [listStatement, setListStatement] = useState([])
    const [listStatementLinks, setListStatementLinks] = useState([])
    const [listStatementMeta, setListStatementMeta] = useState([])
    const [modalOpen, setModalOpen] = useState(false);
    
    // Search Form
    const [searchModel, setSearchModel] = useState(initSearch)
    const [listSearchStatement, setListSearchStatement] = useState([])

    const userProfile = JSON.parse(localStorage.getItem("userProfile"));


    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true
            doLoad()
        }
    }, []);

    const doLoad = async () => {
        try {  
            setSending(true)
            const response = await statementService.getStatements()
            setListStatement(response.data)
            setListStatementLinks(response.links)
            setListStatementMeta(response.meta)
            // LISTS
            const responseSearch = await statementService.getStatementsLists()
            setListSearchStatement(responseSearch.data)
            const responseAccount = await accountSeervice.getAccountAutocomplete()
            setListAccounts(responseAccount.data)
            
            setSending(false)
        } catch (e) {
            if (axios.isAxiosError(e)) {
                const statusCode = e.response?.status
                const error = e.response?.data.errors;
                // let message = '';
                
                if(statusCode && statusCode !== 401){
                    Swal.fire({
                        title: 'ERROR',
                        text: error[0].detail,
                        icon: 'error',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#f13939'
                      })
                }else{
                    localStorage.removeItem("userProfile");
                    navigate('/')
                    window.location.reload();
                }
                
              }
              setSending(false)
        }
    }

    // const doSearch = async (event) => {
    const doSearch = async () => {
        // event.preventDefault();
        try {  
            setSending(true)
            const response = await statementService.getStatementSearch(searchModel)
            setListStatement(response.data)
            setListStatementLinks(response.links)
            setListStatementMeta(response.meta)
            setSending(false)
        } catch (e) {
            if (axios.isAxiosError(e)) {
                const statusCode = e.response?.status
                const error = e.response?.data.errors;
                // let message = '';
                
                if(statusCode && statusCode !== 401){
                    Swal.fire({
                        title: 'ERROR',
                        text: error[0].detail,
                        icon: 'error',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#f13939'
                    })
                }else{
                    localStorage.removeItem("userProfile");
                    navigate('/')
                    window.location.reload();
                }
            }
            setSending(false)
        }
    }

    const doPagination = async () => {
        try {  
            setSending(true)
            const response = await statementService.getStatementsSearchPagination(listStatementLinks.next)
            response.data.forEach( data => listStatement.push(data) )
            setListStatementLinks(response.links)
            setListStatementMeta(response.meta)
            setSending(false)
        } catch (e) {
            if (axios.isAxiosError(e)) {
                const statusCode = e.response?.status
                const error = e.response?.data.errors;
                
                if(statusCode && statusCode !== 401){
                    Swal.fire({
                        title: 'ERROR',
                        text: error[0].detail,
                        icon: 'error',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#f13939'
                    })
                }else{
                    localStorage.removeItem("userProfile");
                    navigate('/')
                    window.location.reload();
                }
            }
            setSending(false)
        }
    }

    const doExport = async () => {
        let filters = '';
        if(searchModel.name !== ""){ filters += '&filter[name]='+searchModel.name; }
        if(searchModel.noti !== ""){ filters += '&filter[swornStatement]='+searchModel.noti; }
        if(searchModel.status !== ""){ filters += '&filter[status]='+searchModel.status; }
        if(searchModel.date !== "" && searchModel.date !== null){ filters += '&filter[signedAt]='+searchModel.date; }
        window.open(process.env.REACT_APP_API_URL+'/statement-signatures/download/excel?token='+userProfile.token+filters, '_blank')
    }

    return (
        <div className="container-fluid flex-grow-1 container-p-y">

            <div className="row mb-3">
                <div className="col">
                    <h2>Notificaciones  { listStatementMeta.total > 0 && <span className="fontMedium">({listStatementMeta.total})</span> } </h2>
                </div>
                <div className="col">
                    <div className="btn-group float-end" role="group" aria-label="First group">
                        <button type="button" className="btn btn-outline-secondary" title="Nuevo envio" onClick={() => setModalOpen(true)}>
                        {/* <button type="button" className="btn btn-outline-secondary" title="Nuevo envio" onClick={setShowModal(true)}> */}
                            <i className="tf-icons bx bx-send" />
                        </button>
                        <button type="button" className="btn btn-outline-secondary" title="Exportar" onClick={doExport}>
                            <i className="tf-icons bx bx-export" />
                        </button>
                        <button type="button" className="btn btn-outline-secondary" title="Buscar" data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasEnd"
                          aria-controls="offcanvasEnd">
                            <i className="tf-icons bx bx-filter" />
                        </button>
                    </div>
                </div>
            </div>
        

            <div className="card">
                
                <div className="table-responsive ">
                    <table className="table table-striped table-hover">
                        <thead>
                            <tr className="text-nowrap">
                                {/* <th>Comitente</th> */}
                                <th>Comitente / Email</th>
                                {/* <th>Email</th> */}
                                <th>Notificación</th>
                                <th>Estado</th>
                                <th>Fecha Envidada</th>
                                <th>Fecha Firmada</th>
                                {/* <th>Envió</th> */}
                                <th></th>
                            </tr>
                        </thead>
                        <tbody className="table-border-bottom-0">
                            {listStatement.map(statement => {
                                return (
                                    <tr key={statement.id}>
                                        {/* <td><span className="badge bg-label-dark">{statement.attributes.account}</span></td> */}
                                        <td>
                                            <div className="d-flex justify-content-start align-items-center user-name">
                                                <div className="d-flex flex-column">
                                                    <a href="app-user-view-account.html" className="text-body text-truncate">
                                                        <span className="fw-medium text-uppercase">
                                                            {statement.attributes.name.length > 45 ?
                                                                `${statement.attributes.name.substring(0, 45)}...` : statement.attributes.name
                                                            }
                                                        </span>
                                                    </a>

                                                    <small className="text-muted">
                                                        <span className="badge bg-label-dark me-1">{statement.attributes.account}</span>
                                                        {statement.attributes.email}
                                                    </small>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            {statement.included.swornStatement.display_name.length > 85 ?
                                                `${statement.included.swornStatement.display_name.substring(0, 85)}...` : statement.included.swornStatement.display_name
                                            }
                                        </td>
                                        <td>
                                            {statement.included.statementSignatureStatus.id === 2 && <span className="badge bg-label-success me-1">{statement.included.statementSignatureStatus.name}</span>}
                                            {statement.included.statementSignatureStatus.id === 1 && <span className="badge bg-label-warning me-1">{statement.included.statementSignatureStatus.name}</span>}
                                            {statement.included.statementSignatureStatus.id === 3 && <span className="badge bg-label-danger me-1">{statement.included.statementSignatureStatus.name}</span>}
                                        </td>
                                        <td>{moment(statement.attributes.sent_at).format('DD/MM/YYYY HH:mm')}</td>
                                        <td>{statement.attributes.signed_at !== null && moment(statement.attributes.signed_at).format('DD/MM/YYYY HH:mm')}</td>
                                        {/* <td>{statement.included.sentBy.full_name}</td>/ */}
                                        <td className="text-start">
                                            <div className="dropdown">
                                                <a href={process.env.REACT_APP_API_URL+"/statement-signatures/pdf/"+statement.id+"?token="+userProfile.token} title="Ver Documento" target="_blank"><i className='bx bxs-file-pdf'></i></a>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                            
                        </tbody>
                    </table>
                </div>

            </div>
                { listStatementLinks.next !== null ?
                    <div className="row mt-4">
                        <div className="col d-grid gap-2 d-md-flex justify-content-md-center">
                        { sending ? 
                            <div className="d-flex justify-content-center">
                                <div className="spinner-border text-info" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        : 
                            <button type="button" className="btn btn-outline-info" onClick={doPagination}>Mas Información</button>
                        }
                        </div>
                    </div>
                :
                    <span></span>
                }
            
            {/* SEARCH */}
            <DocumentSearch  modelar={searchModel} handleModel={setSearchModel} statements={listSearchStatement} handleSearch={doSearch}/>
            {/* <div>
                <div
                    className="offcanvas offcanvas-end"
                    tabIndex="-1"
                    id="offcanvasEnd"
                    aria-labelledby="offcanvasEndLabel"
                >
                    <div className="offcanvas-header">
                        <h5 id="offcanvasEndLabel" className="offcanvas-title">
                            <i className="tf-icons bx bx-filter" /> Buscar
                        </h5>
                        <button
                            type="button"
                            className="btn-close text-reset"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="offcanvas-body ">
                        <form>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="basic-default-fullname">Comitente/Nombre</label>
                                <input type="text" className="form-control" id="basic-default-fullname" placeholder="Comitente/Nombre" 
                                    onChange={(e)=> setSearchName(e.target.value)} />
                            </div>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="notificationControlSelect">Notificaciones</label>
                                <select className="form-select" id="notificationControlSelect" defaultValue={''} onChange={(e)=> setSearchNoti(e.target.value)}>
                                    <option selected value={''}>---- Todas las Notoficaciones ----</option>
                                    {listSearchStatement
                                        .sort((a, b) => a.attributes.active > b.attributes.active ? -1 : 1)
                                        .map( (statement) => {
                                            if(statement.attributes.active){
                                               return <option value={statement.id} key={statement.id}>{statement.attributes.name} (Vigente)</option> 
                                            }else{
                                                return <option value={statement.id} key={statement.id}>{statement.attributes.name} (No Vigente)</option> 
                                            }
                                        }
                                        
                                    )}
                                </select> 
                            </div>
                            <div className="mb-3">
                                <label htmlFor="exampleFormControlSelect1" className="form-label">Estados</label>
                                <select className="form-select" defaultValue={''} onChange={(e)=> setSearchStatus(e.target.value)}>
                                    <option selected value="">---- Todos los Estados ----</option>
                                    <option value="1">Pendiente</option>
                                    <option value="2">Firmada</option>
                                    <option value="3">Cancelada</option>
                                </select> 
                            </div>
                            <div className="mb-3">
                                <label htmlFor="exampleFormControlSelect1" className="form-label">Fecha de Firma</label>
                                <input className="form-control" type="date" id="html5-date-input" onChange={(e)=> setSearchDate(e.target.value)}/>
                            </div>
                        </form>

                        <div className="mt-5">
                            { sending ? 
                                <div className="d-flex justify-content-center">
                                    <div className="spinner-border text-info" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            : 
                                <div>
                                    <button type="button" className="btn btn-info mb-2 d-grid w-100" onClick={doSearch}>Buscar</button>
                                    <button type="button" className="btn btn-outline-secondary d-grid w-100" data-bs-dismiss="offcanvas" >
                                        Cerrar
                                    </button>
                                </div>
                            }
                            
                        </div>
                        
                    </div>
                </div>
            </div> */}
             
                        
            {/* MODAL */}
            <SendDocumentModal handleOpen={setModalOpen} isOpen={modalOpen} refresh={doLoad} listStatement={listSearchStatement} listAccounts={listAccounts}/> 

        </div>             
    );

}

export default Notifications;