import api from "../apiapp";


const securitieService = {
    getSecuritiesAutocomplete: async function () {
      const response = await api.request({
        url: 'securities?sort=instrumentoAbrev&page[size]=100000&filter[active]=1',
        method: "GET",
      })
      
      return response.data
    }, 
    getSecurities: async function () {
      const response = await api.request({
        url: 'securities?page[size]=100&filter[active]=1',
        method: "GET",
      })
      
      return response.data
    },
    getSecuritieSearch: async function (model) {
      var filter = "";
		  if(model.instrumento != ''){filter = "&filter[instrumentoAbrev]="+model.instrumento}
      const response = await api.request({
        url: 'securities?page[size]=100&filter[active]=1'+filter,
        method: "GET",
      })
      
      return response.data
    },
    getPagination: async function (url) {
      const response = await api.request({
        url: url,
        method: "GET",
      })
      
      return response.data
    },
    deleteTicker: async function (id) {
      const response = await api.request({
        url: 'securities/'+id,
        method: "DELETE",
      })
      
      return response.data
    },
    setTicker: async function (model) {
      const response = await api.request({
        url: 'securities/',
        method: "POST",
        data: model,
      })
      
      return response.data
    },
 

}
  
export default securitieService;