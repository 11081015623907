import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom"
import Swal from "sweetalert2";
import axios from 'axios'
// import { toast } from 'react-toastify';
import moment from 'moment';
import { NumericFormat } from 'react-number-format';
import Loading from "../../components/loading";
import Grid from '@mui/joy/Grid';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Sheet from '@mui/joy/Sheet';
import ModalViewBonos from "./modals/modalViewBonos";
import { toast } from 'react-toastify';
//SERVICES  
import OperationService from "../../services/operationService";
import ListService from "../../services/listService";


function ReportOperationBonosDraft() {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const initialized = useRef(false)
    const [listOperations, setListOperations] = useState([])
    const [listOperationLinks, setListOperationLinks] = useState([])
    const [listOperationMeta, setListOperationMeta] = useState([])
    const [listUsers, setListUsers] = useState([])
    const [listStatus, setListStatus] = useState([])
    const [modalViewOpen, setModalViewOpen] = useState(false);
    const [bonoSelected, setBonoSelected] = useState('');
    const userProfile = JSON.parse(localStorage.getItem("userProfile"));

    // Search Form
    let defaultDate = new Date().toISOString().slice(0,10);
    const [searchModel, setSearchModel] = useState({
        account: '',
        status: '',
        dateFrom: defaultDate,
        dateTo: defaultDate,
        user: '',
    })

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true
            doSearch()
        }
    }, [false]);

    const doSearch = async (event) => {
        try {  
            setLoading(true)
            const response = await OperationService.getOperationDraftSearch(searchModel)
            setListOperations(response.data)
            setListOperationLinks(response.links)
            setListOperationMeta(response.meta)
            // Users
            const responseUsers = await ListService.getUsersList()
            setListUsers(responseUsers.data)        
        } catch (err) {
            if(err.response.status !== 401){
                Swal.fire({
                    title: 'Ooops?',
                    text: err.response.data.errors[0].detail,
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#f13939'
                })
            }else{
                localStorage.removeItem("userProfile");
                navigate('/')
                window.location.reload();
            }
        } finally{ setLoading(false) }
    }
    
    const doExport = async () => {
        var filters = '';
        if(searchModel.dateFrom != '' && searchModel.dateFrom != ""){ filters += '&filter[from]='+searchModel.dateFrom; }
        if(searchModel.dateTo != '' && searchModel.dateTo != ""){ filters += '&filter[to]='+searchModel.dateTo; }
        if(searchModel.account != '' && searchModel.account != null){ filters += '&filter[account]='+searchModel.account; }
        if(searchModel.user != '' && searchModel.user != null){ filters += '&filter[user]='+searchModel.user; }
        window.open(process.env.REACT_APP_API_URL+"/operations/download/excel?token="+userProfile.token+filters, '_blank')

    }

    const doView = async (obj) => {
        if(obj !== null){
            setBonoSelected(obj)
            setModalViewOpen(true)
        }
    }

    const doEdit = async (obj) => {
        if(obj !== null){
            setBonoSelected(obj)
            setModalViewOpen(true)
        }
    }

    const doDelete = async (obj) => {
        try {
            if(obj !== null){
                Swal.fire({
                    title: "Atención!",
                    text: "Ud. esta seguro que quiere eliminar la operación como BORRADOR?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Eliminar",
                    confirmButtonColor: "#83766d"
                }).then((result) => {
                    if (result.isConfirmed) {
                        
                        const response =  OperationService.deleteOperationsDraft(obj.id)
                        toast.success('Se ha eliminado la operación!', {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        })
                        doSearch() 
                    } 
                });
            }
        } catch (err) {
            if(err.response.status !== 401){
                Swal.fire({
                    title: 'Ooops?',
                    text: err.response.data.errors[0].detail,
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#f13939'
                })
            }else{
                localStorage.removeItem("userProfile");
                navigate('/')
                window.location.reload();
            }
        } 
    }

    const doPagination = async () => {
        try {  
            setLoading(true)
            const response = await OperationService.getOperationsSearchPagination(listOperationLinks.next)
            response.data.forEach( data => listOperations.push(data) )
            setListOperationLinks(response.links)
            setListOperationMeta(response.meta)
        } catch (err) {
            if(err.response.status !== 401){
                Swal.fire({
                    title: 'Ooops?',
                    text: err.response.data.errors[0].detail,
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#f13939'
                })
            }else{
                localStorage.removeItem("userProfile");
                navigate('/')
                window.location.reload();
            }
        } finally{ setLoading(false) }
    }

    if(loading){
        return(
            <Loading></Loading>
        )
    }


    return (
        // <div className="container-fluid flex-grow-1 container-p-y">
        <div className="row">
            <div className="row">
                <div className="col">
                    <h2>Reporte Operaciones de Bonos { listOperationMeta.total > 0 && <span className="fontMedium">({listOperationMeta.total})</span> }</h2>
                </div>
                <div className="col">
                    <div className="btn-group float-end" role="group" aria-label="First group">
                        {/* <button type="button" className="btn btn-outline-secondary" title="Exportar" onClick={doExport}>
                            <i className="tf-icons bx bx-export" />
                        </button> */}
                        <button type="button" className="btn btn-outline-secondary" title="Buscar" data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasEnd"
                          aria-controls="offcanvasEnd">
                            <i className="tf-icons bx bx-filter" />
                        </button>
                    </div>
                </div>
            </div>

            <div className="row">
                <Sheet sx={{ height: 580, overflow: 'auto', backgroundColor: 'transparent', padding: '2px' }} className="table-responsive">
                    <table className="table table-striped table-hover" >
                        <thead style={{backgroundColor: '#FFFFFF'}}>
                            <tr className="text-nowrap">
                                <th>#</th>
                                <th>Fecha</th>
                                <th>Comintente</th>
                                <th>Mercado</th>
                                <th>Precio</th>
                                <th>Tipo Operación</th>
                                <th>Plazo</th>
                                <th>Especie</th>
                                <th>Valor Nominal</th>
                                <th>Monto</th>
                                <th>Usuario</th>
                                <th>Estado</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody className="table-border-bottom-0">
                            {listOperations.map(operation => {
                                return (
                                    <tr key={operation.id}>  
                                        <td>{operation.id}</td>
                                        <td>
                                            <div className="d-flex justify-content-start align-items-center user-name">
                                                <div className="d-flex flex-column">
                                                    <a className="text-body text-truncate">
                                                        <span className="fw-medium">
                                                            {moment(operation.date).format('DD/MM/YYYY')}
                                                        </span>
                                                    </a>
                                                    <small className="text-muted">{moment(operation.date).format('HH:MM')}</small>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            {operation.account !== null ?
                                                <div className="d-flex justify-content-start align-items-center user-name">
                                                    <div className="d-flex flex-column">
                                                        <a className="text-body text-truncate">
                                                            <span className="fw-medium">
                                                                {operation.account.name.length > 30 ?
                                                                    `${operation.account.name.substring(0, 30)}...` : operation.account.name
                                                                }
                                                            </span>
                                                        </a>
                                                        <small className="text-muted"><span className="badge bg-label-dark">{operation.account.account}</span></small>
                                                    </div>
                                                </div>
                                            : null
                                            }  
                                        </td>
                                        <td>
                                        {operation.market !== null ?
                                            <div className="d-flex justify-content-start align-items-center user-name">
                                                <div className="d-flex flex-column">
                                                    <a className="text-body text-truncate">
                                                        <span className="fw-medium">{operation.market.name}</span>
                                                    </a>
                                                    <small className="text-muted">
                                                        {operation.segment_id === 1 && <span className="badge bg-label-primary me-1">{operation.segment.name}</span>}
                                                        {operation.segment_id === 2 && <span className="badge bg-label-info me-1">{operation.segment.name}</span>}
                                                        {operation.segment_id === 3 && <span className="badge bg-label-secondary me-1">{operation.segment.name}</span>}
                                                    </small>
                                                </div>
                                            </div>
                                            : null
                                        } 
                                        </td>
                                        <td>
                                            {operation.price_type !== null ?
                                                operation.price_type.name
                                                : null
                                            }
                                        </td>
                                        <td>
                                        {operation.operation_type !== null ?
                                            <>
                                                {operation.operation_type_id === 1 && <span className="badge bg-label-success me-1">{operation.operation_type.name}</span>}
                                                {operation.operation_type_id === 2 && <span className="badge bg-label-danger me-1">{operation.operation_type.name}</span>}
                                            </>
                                            : null
                                        }
                                        </td>
                                        <td>
                                            {operation.settlement_term !== null ?
                                                operation.settlement_term.name
                                            : null
                                            }
                                        </td>
                                        <td>
                                            {operation.ticker !== null ?
                                                <div className="d-flex justify-content-start align-items-center user-name">
                                                    <div className="d-flex flex-column">
                                                        <a className="text-body text-truncate">
                                                            <span className="fw-medium">{operation.ticker}</span>
                                                        </a>
                                                        <small className="text-muted"><span className="badge bg-label-dark">{operation.currency.name}</span></small>
                                                    </div>
                                                </div>
                                            : null
                                            }
                                        </td>
                                        <td className="text-end">
                                            <NumericFormat 
                                                displayType="text" 
                                                value={operation.qty} 
                                                allowLeadingZeros thousandSeparator="." 
                                                decimalSeparator="," 
                                            /> 
                                        </td>
                                        <td className="text-end">
                                            <NumericFormat 
                                                displayType="text" 
                                                value={operation.price} 
                                                decimalScale={4} 
                                                allowLeadingZeros thousandSeparator="." 
                                                decimalSeparator="," 
                                                prefix={'$'}  
                                            /> 
                                        </td>
                                        <td>{operation.user.full_name}</td>
                                        <td>
                                        {operation.operation_state !== null ?
                                            <>
                                            {operation.operation_state_id === 1 && <span className="badge bg-label-secondary me-1">{operation.operation_state.display_name}</span>}
                                            {operation.operation_state_id === 2 && <span className="badge bg-label-warning me-1">{operation.operation_state.display_name}</span>}
                                            {operation.operation_state_id === 3 && <span className="badge bg-label-success me-1">{operation.operation_state.display_name}</span>}
                                            {operation.operation_state_id === 4 && <span className="badge bg-label-danger me-1">{operation.operation_state.display_name}</span>}
                                            </>
                                        : null}
                                        </td>
                                        <td className="text-end">
                                            <div className="dropdown">
                                                <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                                                    <i className="bx bx-dots-vertical-rounded" />
                                                </button>
                                                <div className="dropdown-menu">
                                                    <a className="dropdown-item" href="#"  onClick={() => {  doView(operation) } }>
                                                        <i className="bx bx-eyes me-1" /> Ver
                                                    </a>
                                                    <a className="dropdown-item" href="#"  onClick={() => {  doEdit(operation) } }>
                                                        <i className="bx bx-eyes me-1" /> Editar
                                                    </a>
                                                    <a className="dropdown-item" href="#"  onClick={() => {  doDelete(operation) } }>
                                                        <i className="bx bx-eyes me-1" /> Eliminar
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    )
                                })
                            }

                        </tbody>
                    </table>
                </Sheet>
            </div>

            { listOperationLinks.next !== null ?
                    <div className="row mt-4">
                        <div className="col d-grid gap-2 d-md-flex justify-content-md-center">
                        { sending ? 
                            <div className="d-flex justify-content-center">
                                <div className="spinner-border text-info" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        : 
                            <button type="button" className="btn btn-outline-info" onClick={doPagination}>Mas Información</button>
                        }
                        </div>
                    </div>
                :
                    null
            }
            
            {/* SEARCH */}
            <div>
                <div
                    className="offcanvas offcanvas-end"
                    tabIndex="-1"
                    id="offcanvasEnd"
                    aria-labelledby="offcanvasEndLabel"
                >
                    <div className="offcanvas-header">
                        <h5 id="offcanvasEndLabel" className="offcanvas-title">
                            <i className="tf-icons bx bx-filter" /> Buscar
                        </h5>
                        <button
                            type="button"
                            className="btn-close text-reset"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                        ></button>
                    </div>

                    <div className="offcanvas-body ">
                        <form>
                            <Grid xs={12} style={{marginBottom: '10px'}}>
                                <label className="form-label" htmlFor="basic-default-fullname">Comitente</label>
                                <input type="text" className="form-control" id="basic-default-fullname" placeholder="Comitente" 
                                    value={searchModel.account}
                                    onChange={(e)=> setSearchModel({...searchModel, account: e.target.value}) }
                                />
                            </Grid>
                            <Grid xs={12} style={{marginBottom: '10px'}}>
                                <label htmlFor="searchDateFrom" className="form-label">Fecha de Desde</label>
                                <input className="form-control" type="date" id="searchDateFrom" 
                                    value={searchModel.dateFrom}
                                    onChange={(e)=> setSearchModel({...searchModel, dateFrom: e.target.value})}
                                />
                            </Grid>
                            <Grid xs={12} style={{marginBottom: '10px'}}>
                                <label htmlFor="searchDateTo" className="form-label">Fecha de Hasta</label>
                                <input className="form-control" type="date" id="searchDateTo" 
                                    value={searchModel.dateTo}
                                    onChange={(e)=> setSearchModel({...searchModel, dateTo: e.target.value})}
                                />
                            </Grid>
                            <Grid xs={12} style={{marginBottom: '10px'}}>
                                <FormControl>
                                    <FormLabel className="form-labels">Estados</FormLabel>
                                        <select className="form-select" value={searchModel.status}
                                            onChange={(e)=> setSearchModel({...searchModel, status: e.target.value})}
                                        >
                                            <option value="">---- Todos los Estados ----</option>
                                            
                                            {listStatus.map( (obj) => 
                                                <option value={obj.id} key={obj.id}>{obj.display_name}</option>
                                            )}
                                        </select> 
                                </FormControl>
                            </Grid>
                            <Grid xs={12} style={{marginBottom: '10px'}}>
                                <FormControl>
                                    <FormLabel className="form-labels">Usuarios</FormLabel>
                                        <select className="form-select" value={searchModel.user}
                                            onChange={(e)=> setSearchModel({...searchModel, user: e.target.value})}
                                        >
                                        <option selected value="">---- Todos los Usuarios ----</option>
                                        {listUsers.map( (usr) => 
                                            <option value={usr.id} key={usr.id}>{usr.full_name}</option>
                                        )}
                                    </select> 
                                </FormControl>
                            </Grid>
                           
                        </form>

                        <div className="mt-5">
                            { sending ? 
                                <div className="d-flex justify-content-center">
                                    <div className="spinner-border text-info" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            : 
                                <div>
                                    <button type="button" className="btn btn-info mb-2 d-grid w-100" onClick={doSearch}>Buscar</button>
                                    <button type="button" className="btn btn-outline-secondary d-grid w-100" data-bs-dismiss="offcanvas" >
                                        Cerrar
                                    </button>
                                </div>
                            }
                            
                        </div>
                        
                    </div>
                </div>
            </div>
            
            { bonoSelected !== '' ?
                <ModalViewBonos 
                    handleOpen={setModalViewOpen} 
                    isOpen={modalViewOpen} 
                    selected={bonoSelected}
                    fundId={bonoSelected.id}
                />
            :
                null
            }

        </div>
    );

}

export default ReportOperationBonosDraft;