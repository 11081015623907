import React, {useState, useEffect} from "react";
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import DialogActions from '@mui/joy/DialogActions';
import Divider from '@mui/joy/Divider';
import Grid from '@mui/joy/Grid';
import Input from '@mui/joy/Input';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import { toast } from 'react-toastify';
import Swal from "sweetalert2";
import axios from 'axios'
import userService from "../../services/userService";

function UserModalEdit( {handleOpen, isOpen, selected, handleSelected, listRoles}) {

    const initProp = {
        id: '',
        name: '',
        last_name: '',
        email: '',
        role: ''
    }
    const [sending, setSending] = useState(false)
    const [newModel, setNewModel] = useState(initProp)
    // const [newModel, setNewModel] = useState({})

    useEffect(() => {
        
        if(selected !== ''){
            let model = {
                id: selected.id,
                name: selected.name,
                last_name: selected.last_name,
                email: selected.email,
                role: selected.roles[0].name,
            }
            setNewModel(model)
        }
        
    }, [selected]);

    const doClose = async () => {
        handleOpen(false)
    }
    
    const doSelectChange = async (obj) => {
        setNewModel({
            ...newModel, 
            role: obj,
        })
    }
    
    const doSave = async () => {
        if(validateNewForm()){
            try {  
                setSending(true)
                let editModel = {
                    name: newModel.name,
                    last_name: newModel.last_name,
                    email: newModel.email,
                    phone: selected.phone,
                    role: newModel.role,
                    change_password: 0,
                    password_generate: 0,
                    password: ''
                  };
                // handleSelected(newModel)
                const response = await userService.putUser(selected.id, editModel)
                toast.success('Usuario actualizado!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                handleOpen(false)
                setNewModel(initProp)
                window.location.reload();
            } catch (e) {
                if (axios.isAxiosError(e)) {
                    const statusCode = e.response?.status
                    if(statusCode && statusCode !== 401){
                        Swal.fire({
                            title: 'ERROR',
                            text: "Error al intentar actualizar el usuario.",
                            icon: 'error',
                            confirmButtonText: 'OK',
                            confirmButtonColor: '#f13939'
                        })
                    }else{
                        localStorage.removeItem("userProfile");
                        window.location.reload();
                    }
                }
            } finally{ setSending(false) }
        }else{
            toast.error('Cargue los datos correctamente!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    function validateNewForm (){
        let rta = true;
        const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
        if(newModel.name === '' || newModel.name === undefined  || newModel.name === null){ return false; } 
        if(newModel.last_name === '' || newModel.last_name === undefined  || newModel.last_name === null){ return false; } 
        if(newModel.email === '' || newModel.email === undefined  || newModel.email === null){ return false; }         
        if(newModel.role === '' || newModel.role === undefined  || newModel.role === null){ return false; }         
        if(!newModel.email.match(isValidEmail)){ return false; }
        return rta;
    }

    return (
        <Modal
            aria-labelledby="close-modal-title"
            open={isOpen}
            onClose={() => handleOpen(false) }
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <ModalDialog>
                <ModalClose variant="outlined" />
                <DialogTitle>Editar Usuario</DialogTitle>
                <Divider />
                <DialogContent>
                    <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                        <Grid xs={6}>
                            <FormControl>
                                <FormLabel className="form-labels">Nombre <span style={{color:'red'}}>*</span></FormLabel>
                                <Input type="text" size="md" placeholder="Ingrese Nombre del usuario" value={newModel.name}
                                    onChange={(e)=> setNewModel({...newModel, name: e.target.value}) }
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={6}>
                            <FormControl>
                                <FormLabel className="form-labels">Apellido <span style={{color:'red'}}>*</span></FormLabel>
                                <Input type="text" size="md" placeholder="Ingrese Apellido del usuario" value={newModel.last_name}  
                                    onChange={(e)=> setNewModel({...newModel, last_name: e.target.value}) }
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={6}>
                            <FormControl>
                                <FormLabel className="form-labels">Email <span style={{color:'red'}}>*</span></FormLabel>
                                <Input type="email" size="md" placeholder="Ingrese email del usuario"  value={newModel.email}  
                                    onChange={(e)=> setNewModel({...newModel, email: e.target.value}) }
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={6}>
                            <FormControl>
                                <FormLabel className="form-labels">Perfil <span style={{color:'red'}}>*</span></FormLabel>
                                <Select 
                                    value={newModel.role}  
                                    placeholder="Seleccione ..." 
                                    onChange={(e, newValue) => doSelectChange(newValue) } 
                                >   
                                    {listRoles.map( (role) => 
                                        <Option key={role.name} value={role.name} label={role.name}>{role.name}</Option> )
                                    }  
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <button type="button" className="btn btn-info" onClick={() => doSave() } disabled={sending}>Guardar</button>
                    <button type="button" className="btn btn-outline-secondary"  onClick={doClose}> Cerrar </button>                    
                </DialogActions>
            </ModalDialog>
        </Modal>
    );
}

export default UserModalEdit;