import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom"
import Grid from '@mui/joy/Grid';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab, { tabClasses } from '@mui/joy/Tab';
import TabPanel from '@mui/joy/TabPanel';
import ReportOperationMiddle from "./report-operation-middle";


function ReportsMiddle() {

    // const navigate = useNavigate();


    return (
        <ReportOperationMiddle />
    );

}

export default ReportsMiddle;