import axios from "axios"
// import { useState } from "react";

const userProfile = JSON.parse(localStorage.getItem("userProfile"));
// const [token, setToken] = useState('')
let token = ''
if(userProfile){ token= userProfile.token}

const api = axios.create({
    // withCredentials: true,
    // baseURL: "https://alchemy.api.globemind.ar/api/v1/",
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 50000, // tiempo de espera en milisegundos
    headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+ token
    },
  })
  
  // defining a custom error handler for all APIs
  const errorHandler = (error) => {
    const statusCode = error.response?.status
  
    // logging only errors that are not 401
    /* if (statusCode && statusCode !== 401) {
      console.error(error)
        // return error.response?.data
    } */
  
    return Promise.reject(error)
  }
  
  // registering the custom error handler to the
  // "api" axios instance
  api.interceptors.response.use(undefined, (error) => {
    return errorHandler(error)
  })

  export default api;