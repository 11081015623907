import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom"
import Swal from "sweetalert2";
import axios from 'axios'
// import { toast } from 'react-toastify';
import moment from 'moment';
import { NumericFormat } from 'react-number-format';
import fundService from "../../services/fundService";
import ModalViewFund from "./modals/modalViewFund";
import Sheet from '@mui/joy/Sheet';


function ReportFCIBackoffice() {

    const navigate = useNavigate();
    const [sending, setSending] = useState(false)
    const initialized = useRef(false)
    const [listFunds, setListFunds] = useState([])
    const [listFundsLinks, setListFundsLinks] = useState([])
    const [listFundsMeta, setListFundsMeta] = useState([])
    const [modalViewOpen, setModalViewOpen] = useState(false);
    const [fundSelected, setFundSelected] = useState('');
    const userProfile = JSON.parse(localStorage.getItem("userProfile"));


    // Search Form
    let defaultDate = new Date().toISOString().slice(0,10);
    // let defaultDate = '';
    const [searchModel, setSearchModel] = useState({
        account: '',
        status: '',
        dateFrom: defaultDate,
        dateTo: defaultDate,
        acdi: '',
        operationType: ''
    })

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true
            doSearch()
        }
    }, [false]);

    const doSearch = async (event) => {
        try {  
            setSending(true)
            const response = await fundService.getFundOperationSearch(searchModel)
            setListFunds(response.data)
            setListFundsLinks(response.links)
            setListFundsMeta(response.meta)
            setSending(false)
        } catch (e) {
            if (axios.isAxiosError(e)) {
                const statusCode = e.response?.status
                const error = e.response?.data.errors;
                
                if(statusCode && statusCode !== 401){
                    Swal.fire({
                        title: 'ERROR',
                        text: error[0].detail,
                        icon: 'error',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#f13939'
                    })
                }else{
                    localStorage.removeItem("userProfile");
                    navigate('/')
                    window.location.reload();
                }
            }
            setSending(false)
        }
    }
    
    const doExport = async () => {
        var filters = '';
        if(searchModel.dateFrom != '' && searchModel.dateFrom != ""){ filters += '&filter[from]='+searchModel.dateFrom; }
        if(searchModel.dateTo != '' && searchModel.dateTo != ""){ filters += '&filter[to]='+searchModel.dateTo; }
        if(searchModel.account != '' && searchModel.account != null){ filters += '&filter[account]='+searchModel.account; }
        if(searchModel.status != '' && searchModel.status != null){ filters += '&filter[state]='+searchModel.status; }
        window.open(process.env.REACT_APP_API_URL+"/fund-operations/download/excel?token="+userProfile.token+filters, '_blank')
    }

    const doView = async (obj) => {
        if(obj !== null){
            setFundSelected(obj)
            setModalViewOpen(true)
        }
    }

    const doPagination = async () => {
        try {  
            setSending(true)
            const response = await fundService.getFundSearchPagination(listFundsLinks.next)
            response.data.forEach( data => listFunds.push(data) )
            setListFundsLinks(response.links)
            setListFundsMeta(response.meta)
            setSending(false)
        } catch (e) {
            if (axios.isAxiosError(e)) {
                const statusCode = e.response?.status
                const error = e.response?.data.errors;
                
                if(statusCode && statusCode !== 401){
                    Swal.fire({
                        title: 'ERROR',
                        text: error[0].detail,
                        icon: 'error',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#f13939'
                    })
                }else{
                    localStorage.removeItem("userProfile");
                    navigate('/')
                    window.location.reload();
                }
            }
            setSending(false)
        }
    }

    return (
        // <div className="container-fluid flex-grow-1 container-p-y">
        <div className="row">
            <div className="row mb-3">
                <div className="col">
                    <h2>Reporte Operaciones de Fondos { listFundsMeta.total > 0 && <span className="fontMedium">({listFundsMeta.total})</span> }</h2>
                    {/* <h2>Reporte Operaciones de Fondos </h2> */}
                </div>
                <div className="col">
                    <div className="btn-group float-end" role="group" aria-label="First group">
                        <button type="button" className="btn btn-outline-secondary" title="Exportar" onClick={doExport}>
                            <i className="tf-icons bx bx-export" />
                        </button>
                        <button type="button" className="btn btn-outline-secondary" title="Buscar" data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasEnd"
                          aria-controls="offcanvasEnd">
                            <i className="tf-icons bx bx-filter" />
                        </button>
                    </div>
                </div>
            </div>

            <div className="row">
                <Sheet sx={{ height: 580, overflow: 'auto', backgroundColor: 'transparent', padding: '2px' }} className="table-responsive">
                    <table className="table table-striped table-hover" >
                        <thead style={{backgroundColor: '#FFFFFF'}}>
                            <tr className="text-nowrap">
                                <th>#</th>
                                <th>Fecha</th>
                                <th>Comintente</th>
                                <th>Fondo</th>
                                <th>Tipo</th>
                                <th>Cantidad / Monto</th>
                                <th>Oficial</th>
                                <th>Estado</th>
                                <th>Estado Pago</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody className="table-border-bottom-0">
                            {listFunds.map(fund => {
                                return (
                                    <tr key={fund.id}>  
                                        <td>{fund.id}</td>
                                        <td>{moment(fund.date).format('DD/MM/YYYY')}</td>
                                        <td>
                                            <div className="d-flex justify-content-start align-items-center user-name">
                                                <div className="d-flex flex-column">
                                                    <a className="text-body text-truncate">
                                                        <span className="fw-medium">
                                                            {fund.account_model.name.length > 30 ?
                                                                `${fund.account_model.name.substring(0, 35)}...` : fund.account_model.name
                                                            }
                                                        </span>
                                                    </a>
                                                    <small className="text-muted"><span className="badge bg-label-dark">{fund.account_model.id}</span></small>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex justify-content-start align-items-center user-name">
                                                <div className="d-flex flex-column">
                                                    <a className="text-body text-truncate">
                                                        <span className="fw-medium">
                                                            {fund.fund.name.length > 35 ?
                                                                `${fund.fund.name.substring(0, 35)}...` : fund.fund.name
                                                            }
                                                        </span>
                                                    </a>
                                                    <small className="text-muted">
                                                        {/* {fund.attributes.operation_type_id === 4 && <span className="badge bg-label-primary me-1">{fund.included.operationType.name}</span>} */}
                                                        {/* {fund.attributes.operation_type_id === 3 && <span className="badge bg-label-warning me-1">{fund.included.operationType.name}</span>} */}
                                                        <span className="badge bg-label-primary me-1">{fund.operation_type.name}</span>
                                                        <span className="badge bg-label-info">{fund.currency.name}</span>
                                                        <span className="badge bg-label-dark ms-1">{fund.settlement_term.name}</span>
                                                    </small>
                                                </div>
                                            </div>
                                        </td>
                                        <td>{fund.investment_mode.name}</td>
                                        <td className="text-end">
                                            <NumericFormat 
                                                displayType="text" 
                                                value={fund.qty} 
                                                decimalScale={fund.fund.decimals} 
                                                allowLeadingZeros thousandSeparator="." 
                                                decimalSeparator="," 
                                            /> 
                                        </td>
                                        <td>{fund.account_model.portfolio_manager}</td>
                                        <td>
                                            {fund.operation_state_id === 1 && <span className="badge bg-label-secondary me-1">{fund.fund_operation_status.display_name}</span>}
                                            {fund.operation_state_id === 2 && <span className="badge bg-label-warning me-1">{fund.fund_operation_status.display_name}</span>}
                                            {fund.operation_state_id === 3 && <span className="badge bg-label-success me-1">{fund.fund_operation_status.display_name}</span>}
                                            {fund.operation_state_id === 4 && <span className="badge bg-label-danger me-1">{fund.fund_operation_status.display_name}</span>}
                                        </td>
                                        <td>
                                            {fund.operation_type_id === 4 &&  fund.is_acdi ?
                                                <span>
                                                    {fund.fund_payment_status_id === 1 && <span className="badge bg-label-success me-1">Pendientes</span>}
                                                    {fund.fund_payment_status_id === 2 && <span className="badge bg-label-warning me-1">Pagado</span>}
                                                    {fund.fund_payment_status_id === 3 && <span className="badge bg-label-danger me-1">Cancalado</span>}
                                                </span>
                                            : null }
                                        </td>
                                        <td className="text-end">
                                            <div className="dropdown">
                                                <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                                                    <i className="bx bx-dots-vertical-rounded" />
                                                </button>
                                                <div className="dropdown-menu">
                                                    <a className="dropdown-item" href="javascript:void(0);"  onClick={() => {  doView(fund) } }>
                                                        <i className="bx bx-eyes me-1" /> Ver
                                                    </a>
                                                    {/* <a className="dropdown-item" href="javascript:void(0);"  onClick={() => {  doDelete(fund) } }>
                                                        <i className="bx bx-trash me-1" /> Eliminar
                                                    </a> */}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    )
                                })
                            }

                        </tbody>
                    </table>
                </Sheet>
            </div>
            { listFundsLinks.next !== null ?
                    <div className="row mt-4">
                        <div className="col d-grid gap-2 d-md-flex justify-content-md-center">
                        { sending ? 
                            <div className="d-flex justify-content-center">
                                <div className="spinner-border text-info" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        : 
                            <button type="button" className="btn btn-outline-info" onClick={doPagination}>Mas Información</button>
                        }
                        </div>
                    </div>
                :
                    null
            }
            
            {/* SEARCH */}
            <div>
                <div
                    className="offcanvas offcanvas-end"
                    tabIndex="-1"
                    id="offcanvasEnd"
                    aria-labelledby="offcanvasEndLabel"
                >
                    <div className="offcanvas-header">
                        <h5 id="offcanvasEndLabel" className="offcanvas-title">
                            <i className="tf-icons bx bx-filter" /> Buscar
                        </h5>
                        <button
                            type="button"
                            className="btn-close text-reset"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="offcanvas-body ">
                        <form>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="basic-default-fullname">Comitente</label>
                                <input type="text" className="form-control" id="basic-default-fullname" placeholder="Comitente" 
                                    value={searchModel.account}
                                    onChange={(e)=> setSearchModel({...searchModel, account: e.target.value}) }
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="exampleFormControlSelect1" className="form-label">Estados</label>
                                <select className="form-select" defaultValue={''} value={searchModel.status}
                                    onChange={(e)=> setSearchModel({...searchModel, status: e.target.value})}
                                >
                                    <option selected value="">---- Todos los Estados ----</option>
                                    <option value="1">Pendiente</option>
                                    <option value="2">Tomada</option>
                                    <option value="3">Finalizada</option>
                                    <option value="4">Cancelado</option>
                                </select> 
                            </div>
                            <div className="mb-3">
                                <label htmlFor="searchDateFrom" className="form-label">Fecha de Desde</label>
                                <input className="form-control" type="date" id="searchDateFrom" 
                                    value={searchModel.dateFrom}
                                    onChange={(e)=> setSearchModel({...searchModel, dateFrom: e.target.value})}
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="searchDateTo" className="form-label">Fecha de Hasta</label>
                                <input className="form-control" type="date" id="searchDateTo" 
                                    value={searchModel.dateTo}
                                    onChange={(e)=> setSearchModel({...searchModel, dateTo: e.target.value})}
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="exampleFormControlOperationType" className="form-label">Tipo de Operación </label>
                                <select className="form-select" defaultValue={''} value={searchModel.operationType}
                                    onChange={(e)=> setSearchModel({...searchModel, operationType: e.target.value})}
                                >
                                    <option selected value="">---- Todos ----</option>
                                    <option value="3">Suscripción</option>
                                    <option value="4">Rescate</option>
                                </select> 
                            </div>
                            <div className="mb-3">
                                <label htmlFor="exampleFormControlAgdi" className="form-label">Pago Acdi </label>
                                <select className="form-select" defaultValue={''} value={searchModel.acdi}
                                    onChange={(e)=> setSearchModel({...searchModel, acdi: e.target.value})}
                                >
                                    <option selected value="">---- Todos ----</option>
                                    <option value="1">Sí</option>
                                    <option value="0">No</option>
                                </select> 
                            </div>
                        </form>

                        <div className="mt-5">
                            { sending ? 
                                <div className="d-flex justify-content-center">
                                    <div className="spinner-border text-info" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            : 
                                <div>
                                    <button type="button" className="btn btn-info mb-2 d-grid w-100" onClick={doSearch}>Buscar</button>
                                    <button type="button" className="btn btn-outline-secondary d-grid w-100" data-bs-dismiss="offcanvas" >
                                        Cerrar
                                    </button>
                                </div>
                            }
                            
                        </div>
                        
                    </div>
                </div>
            </div>
            
            { fundSelected !== '' ?
                <ModalViewFund 
                    handleOpen={setModalViewOpen} 
                    isOpen={modalViewOpen} 
                    selected={fundSelected}
                    fundId={fundSelected.id}
                />
            :
                null
            }

        </div>
     );

}

export default ReportFCIBackoffice;