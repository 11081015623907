import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom"
import Swal from "sweetalert2";
import axios from 'axios'
import { toast } from 'react-toastify';
import { NumericFormat } from 'react-number-format';
import fundService from "../../services/fundService";
import listService from "../../services/listService"
import ModalNewFund from "./modals/modalNewFund"
import ModalEditFund from "./modals/modalEditFund"

function Funds() {
    const navigate = useNavigate();
    const [sending, setSending] = useState(false)
    const initialized = useRef(false)
    const [modalOpen, setModalOpen] = useState(false);
    const [modalEditOpen, setModalEditOpen] = useState(false);
    const [fundSelected, setFundSelected] = useState('');
    const [listFunds, setListFunds] = useState([])
    const [listFundsLinks, setListFundsLinks] = useState([])
    const [listFundsMeta, setListFundsMeta] = useState([])
    const [listTerm, setListTerm] = useState([])
    // Search Form
    const [searchName, setSearchName] = useState('')

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true
            doSearch()
            doList()
        }
    }, [false]);

    const doSearch = async (event) => {
        try {  
            setSending(true)
            const response = await fundService.getFundSearch({name: searchName})
            setListFunds(response.data)
            setListFundsLinks(response.links)
            setListFundsMeta(response.meta)
            setSending(false)
        } catch (e) {
            if (axios.isAxiosError(e)) {
                const statusCode = e.response?.status
                const error = e.response?.data.errors;
                
                if(statusCode && statusCode !== 401){
                    Swal.fire({
                        title: 'ERROR',
                        text: error[0].detail,
                        icon: 'error',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#f13939'
                    })
                }else{
                    localStorage.removeItem("userProfile");
                    navigate('/')
                    window.location.reload();
                }
            }
            setSending(false)
        }
    }

    const doList = async (event) => {
        try {  
            const response = await listService.getTermList()
            setListTerm(response.data)
        } catch (e) {
            if (axios.isAxiosError(e)) {
                const statusCode = e.response?.status
                const error = e.response?.data.errors;
                
                if(statusCode && statusCode !== 401){
                    Swal.fire({
                        title: 'ERROR',
                        text: error[0].detail,
                        icon: 'error',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#f13939'
                    })
                }else{
                    localStorage.removeItem("userProfile");
                    navigate('/')
                    window.location.reload();
                }
            }
            setSending(false)
        }
    }


    const doEdit = async (obj) => {
        if(obj !== null){
            setFundSelected(obj)
            setModalEditOpen(true)
        }
    }

    const doDelete = async (obj) => {
        Swal.fire({
            title: 'Atención!',
            text: "Ud. esta seguro que quiere eliminar el fondo comun de inversión seleccionado ?",
            icon: "warning",
            showCancelButton: true,
            // cancelButtonColor: '#efefef',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Eliminar',
            confirmButtonColor: '#f13939',
            reverseButtons: true
          })
          .then(async (result) => {
            if (result.isConfirmed) {               
                try {  
                    setSending(true)
                    const response = await fundService.deleteFund(obj.id)
                    toast.success('Fondo Comun de Inversión eliminado!', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    })
                    doSearch()
                    setSending(false)
                } catch (e) {
                    if (axios.isAxiosError(e)) {
                        const statusCode = e.response?.status
                        const error = e.response?.data.errors;
                        
                        if(statusCode && statusCode !== 401){
                            Swal.fire({
                                title: 'ERROR',
                                text: error[0].detail,
                                icon: 'error',
                                confirmButtonText: 'OK',
                                confirmButtonColor: '#f13939'
                            })
                        }else{
                            localStorage.removeItem("userProfile");
                            navigate('/')
                            window.location.reload();
                        }
                    }
                    setSending(false)
                }
            }
          })
    }

    const doPagination = async () => {
        try {  
            setSending(true)
            const response = await fundService.getFundSearchPagination(listFundsLinks.next)
            response.data.forEach( data => listFunds.push(data) )
            setListFundsLinks(response.links)
            setListFundsMeta(response.meta)
            setSending(false)
        } catch (e) {
            if (axios.isAxiosError(e)) {
                const statusCode = e.response?.status
                const error = e.response?.data.errors;
                
                if(statusCode && statusCode !== 401){
                    Swal.fire({
                        title: 'ERROR',
                        text: error[0].detail,
                        icon: 'error',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#f13939'
                    })
                }else{
                    localStorage.removeItem("userProfile");
                    navigate('/')
                    window.location.reload();
                }
            }
            setSending(false)
        }
    }

    return (
        <div className="container-fluid flex-grow-1 container-p-y">

            <div className="row">
                <div className="col">
                        <h4 className="fw-bold py-3 mb-2"><span className="text-muted fw-light">
                            Configuración /</span> Fondos Comunes de Inversión
                            {listFundsMeta.total > 0 && <span className="fontMedium">({listFundsMeta.total})</span> }
                        </h4>
                    </div>
                <div className="col">
                    <div className="btn-group float-end" role="group" aria-label="First group">
                        <button type="button" className="btn btn-outline-secondary" title="Nuevo Fondo Comun de Inversión" onClick={() => setModalOpen(true)}>
                            <i className="tf-icons bx bx-plus" />
                        </button>
                        <button type="button" className="btn btn-outline-secondary" title="Buscar" data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasEnd"
                          aria-controls="offcanvasEnd">
                            <i className="tf-icons bx bx-filter" />
                        </button>
                    </div>
                </div>
            </div>

            <div className="card">

                <div className="table-responsive ">
                    <table className="table table-striped table-hover">
                        <thead>
                            <tr className="text-nowrap">
                                <th>Nombre</th>
                                <th>Plazo Suscripción</th>
                                <th>Plazo Rescate</th>
                                <th>Moneda</th>
                                <th>Valor Cuotaparte</th>
                                <th  className="text-center">Hora Limite</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody className="table-border-bottom-0">
                            {listFunds.map(fund => {
                                return (
                                    <tr key={fund.id}>  
                                        <td>
                                            <div className="d-flex justify-content-start align-items-center user-name">
                                                <div className="d-flex flex-column">
                                                    <a className="text-body text-truncate">
                                                        <span className="fw-medium">
                                                            {fund.name.length > 45 ?
                                                                `${fund.name.substring(0, 45)}...` : fund.name
                                                            }
                                                        </span>
                                                    </a>
                                                    <small className="text-muted"><span className="badge bg-label-dark">{fund.stock_box_code_interface}</span></small>
                                                </div>
                                            </div>
                                        </td>
                                        <td>{fund.subscription_term.name}</td>
                                        <td>{fund.rescue_term.name}</td>
                                        <td>
                                            {fund.currency.id === 1 && <span className="badge bg-label-secondary me-1">{fund.currency.name}</span>}
                                            {fund.currency.id === 2 && <span className="badge bg-label-success me-1">{fund.currency.name}</span>}
                                            {fund.currency.id === 3 && <span className="badge bg-label-primary me-1">{fund.currency.name}</span>}
                                        </td>
                                        <td>
                                            {fund.unit_price !== null ?
                                                <NumericFormat 
                                                    displayType="text" 
                                                    value={fund.unit_price} 
                                                    decimalScale={fund.decimals} 
                                                    allowLeadingZeros thousandSeparator="." 
                                                    decimalSeparator="," 
                                                    prefix={fund.currency.symbol +" "}
                                                /> 
                                                : fund.unit_price
                                            }
                                        </td>
                                        <td className="text-center">{fund.limit_date}</td>
                                        <td className="text-end">
                                            <div className="dropdown">
                                                <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                                                    <i className="bx bx-dots-vertical-rounded" />
                                                </button>
                                                <div className="dropdown-menu">
                                                    <a className="dropdown-item" href="javascript:void(0);"  onClick={() => {  doEdit(fund) } }>
                                                        <i className="bx bx-edit-alt me-1" /> Editar
                                                    </a>
                                                    <a className="dropdown-item" href="javascript:void(0);"  onClick={() => {  doDelete(fund) } }>
                                                        <i className="bx bx-trash me-1" /> Eliminar
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    )
                                })
                            }

                        </tbody>
                    </table>
                </div>

            </div>
            { listFundsLinks.next !== null ?
                    <div className="row mt-4">
                        <div className="col d-grid gap-2 d-md-flex justify-content-md-center">
                        { sending ? 
                            <div className="d-flex justify-content-center">
                                <div className="spinner-border text-info" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        : 
                            <button type="button" className="btn btn-outline-info" onClick={doPagination}>Mas Información</button>
                        }
                        </div>
                    </div>
                :
                    null
            }
            
            {/* SEARCH */}
            <div>
                <div
                    className="offcanvas offcanvas-end"
                    tabIndex="-1"
                    id="offcanvasEnd"
                    aria-labelledby="offcanvasEndLabel"
                >
                    <div className="offcanvas-header">
                        <h5 id="offcanvasEndLabel" className="offcanvas-title">
                            <i className="tf-icons bx bx-filter" /> Buscar
                        </h5>
                        <button
                            type="button"
                            className="btn-close text-reset"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="offcanvas-body ">
                        <form>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="basic-default-fullname">Nombre del Fondo</label>
                                <input type="text" className="form-control" id="basic-default-fullname" placeholder="Nombre del Fondo" 
                                    onChange={(e)=> setSearchName(e.target.value)} />
                            </div>
                        </form>

                        <div className="mt-5">
                            { sending ? 
                                <div className="d-flex justify-content-center">
                                    <div className="spinner-border text-info" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            : 
                                <div>
                                    <button type="button" className="btn btn-info mb-2 d-grid w-100" onClick={doSearch}>Buscar</button>
                                    <button type="button" className="btn btn-outline-secondary d-grid w-100" data-bs-dismiss="offcanvas" >
                                        Cerrar
                                    </button>
                                </div>
                            }
                            
                        </div>
                        
                    </div>
                </div>
            </div>
                        
            {/* MODAL */}
            <ModalNewFund handleOpen={setModalOpen} isOpen={modalOpen} listTerm={listTerm}/>
            { fundSelected !== '' ?
                <ModalEditFund 
                    handleOpen={setModalEditOpen} 
                    isOpen={modalEditOpen} 
                    listTerm={listTerm} 
                    selected={fundSelected}
                    fundId={fundSelected.id}
                />
            :
                null
            }

        </div>        
    );

}

export default Funds;