import React, {useState, useEffect} from "react";
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import DialogActions from '@mui/joy/DialogActions';
import Divider from '@mui/joy/Divider';
import { NumericFormat } from 'react-number-format';
import moment from 'moment';


function ModalDashboardOperations( {handleOpen, isOpen, listOperations}) {
    // console.log(listOperations)
    return (
        <Modal
            aria-labelledby="close-modal-title"
            open={isOpen}
            onClose={() => handleOpen(false) }
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <ModalDialog>
                <ModalClose variant="outlined" />
                <DialogTitle>  Operaciones</DialogTitle>
                <Divider />
                <DialogContent>
                    <table className="table table-sm table-hover table-striped" >
                        <thead style={{backgroundColor: '#FFFFFF'}}>
                            <tr className="text-nowrap">
                                <th>Tipo Operación</th>
                                <th>Fecha</th>
                                <th>Instrumento</th>
                                <th>Codigo</th>
                                <th className="text-end">Cantidad</th>
                                <th className="text-end">Precio</th>
                                <th className="text-end">Bruto</th>
                            </tr>
                        </thead>
                        <tbody className="table-border-bottom-0">
                            {listOperations.map(operation => {
                                return (
                                    <tr key={"M"+operation.id}>  
                                        <td><small>{operation.operation_type.name}</small></td>
                                        <td><small>{moment(operation.created_at).format('DD/MM/YYYY')}</small></td>
                                        <td><span className="fw-medium"><small>{operation.ticker}</small></span></td>
                                        <td><span className="fw-medium"><small>{operation.security_code}</small></span></td>                                        
                                        <td className="text-end">
                                            <small>
                                                <NumericFormat 
                                                    displayType="text" 
                                                    value={operation.qty} 
                                                    allowLeadingZeros thousandSeparator="." 
                                                    decimalSeparator="," 
                                                /> 
                                            </small>
                                        </td>
                                        <td className="text-end">
                                            <small>
                                                <NumericFormat 
                                                    displayType="text" 
                                                    value={operation.price} 
                                                    decimalScale={4} 
                                                    allowLeadingZeros thousandSeparator="." 
                                                    decimalSeparator="," 
                                                /> 
                                            </small>
                                        </td>
                                        <td className="text-end">
                                            <small>
                                                <NumericFormat 
                                                    displayType="text" 
                                                    value={operation.price * operation.qty} 
                                                    decimalScale={4} 
                                                    allowLeadingZeros thousandSeparator="." 
                                                    decimalSeparator="," 
                                                /> 
                                            </small>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
        
                </DialogContent>
                {/* <Divider />
                <DialogActions>
                    <button type="button" className="btn btn-outline-secondary"  onClick={() => handleOpen(false)}> Cerrar </button>                                       
                </DialogActions> */}
            </ModalDialog>
        </Modal>

    );
}

export default ModalDashboardOperations;