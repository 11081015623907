import React, {useState, useEffect} from "react";
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import DialogActions from '@mui/joy/DialogActions';
import Divider from '@mui/joy/Divider';
import Grid from '@mui/joy/Grid';
import Checkbox from '@mui/joy/Checkbox';
import { NumericFormat } from 'react-number-format';


function ModalViewFund( {handleOpen, isOpen, selected, fundId}) {
    
    return (
        <Modal
            aria-labelledby="close-modal-title"
            open={isOpen}
            onClose={() => handleOpen(false) }
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <ModalDialog>
                <ModalClose variant="outlined" />
                <DialogTitle>  Operación: #{fundId}</DialogTitle>
                <Divider />
                <DialogContent>
                    <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                        <Grid xs={4}>
                            <div>
                                <label className="form-label" htmlFor="basic-default-nombre-selected">Nro. Comitente</label>
                                <input type="text" className="form-control" id="basic-default-nombre-selected" placeholder="Ingrese Nombre"                                
                                    value={selected.account_model.account} 
                                    disabled="true"
                                />
                            </div>
                        </Grid>
                        <Grid xs={8}>
                            <div>
                                <label className="form-label" htmlFor="basic-default-codigo-selected">Comitente</label>
                                <input type="text" className="form-control" id="basic-default-codigo-selected"                              
                                    value={selected.account_model.name}  
                                    disabled="true"
                                />
                            </div>
                        </Grid>
                        <Grid xs={4}>
                            <div>
                                <label className="form-label" htmlFor="basic-default-codigo-selected">Tipo de Operación</label>
                                <input type="text" className="form-control" id="basic-default-codigo-selected"                              
                                    value={selected.operation_type.name}  
                                    disabled="true"
                                />
                            </div>
                        </Grid>
                        <Grid xs={8}>
                            <div>
                                <label className="form-label" htmlFor="basic-default-codigo-selected">Fondo</label>
                                <input type="text" className="form-control" id="basic-default-codigo-selected"                              
                                    value={selected.fund.name}  
                                    disabled="true"
                                />
                            </div>
                        </Grid>
                        <Grid xs={6}>
                            <div>
                                <label className="form-label" htmlFor="basic-default-codigo-selected">Moneda</label>
                                <input type="text" className="form-control" id="basic-default-codigo-selected"                              
                                    value={selected.currency.name}  
                                    disabled="true"
                                />
                            </div>
                        </Grid>
                        <Grid xs={6}>
                            <div>
                                <label className="form-label" htmlFor="basic-default-codigo-selected">Plazo</label>
                                <input type="text" className="form-control" id="basic-default-codigo-selected"                              
                                    value={selected.settlement_term.name}  
                                    disabled="true"
                                />
                            </div>
                        </Grid>
                        <Grid xs={6}>
                            <div>
                                <label className="form-label" htmlFor="basic-default-codigo-selected">Tipo</label>
                                <input type="text" className="form-control" id="basic-default-codigo-selected"                              
                                    value={selected.investment_mode.name}  
                                    disabled="true"
                                />
                            </div>
                        </Grid>
                        <Grid xs={6}>
                            <div>
                                <label className="form-label" htmlFor="basic-default-codigo-selected">Monto</label>
                                <NumericFormat 
                                    displayType="input" 
                                    className="form-control" 
                                    value={selected.amount} 
                                    decimalScale={selected.fund.decimals}
                                    allowLeadingZeros thousandSeparator="." 
                                    decimalSeparator="," 
                                    disabled="true"
                                /> 
                            </div>
                        </Grid>
                        <Grid xs={6}>
                            <div>
                                <label className="form-label" htmlFor="basic-default-codigo-selected">Cantidad</label>
                                <NumericFormat 
                                    displayType="input" 
                                    className="form-control" 
                                    value={selected.qty} 
                                    // decimalScale={selected.included.fund.decimals} 
                                    decimalScale={0} 
                                    allowLeadingZeros thousandSeparator="." 
                                    decimalSeparator="," 
                                    disabled="true"
                                /> 
                            </div>
                        </Grid>
                        <Grid xs={6}>
                            <div>
                                <label className="form-label" htmlFor="basic-default-codigo-selected">Valor Cuotaparte</label>
                                <NumericFormat 
                                    displayType="input" 
                                    className="form-control" 
                                    value={selected.fund.unit_price} 
                                    decimalScale={selected.fund.decimals}
                                    allowLeadingZeros thousandSeparator="." 
                                    decimalSeparator="," 
                                    disabled="true"
                                /> 
                            </div>
                        </Grid>
                        <Grid xs={6}>
                            <div>
                                <label className="form-label" htmlFor="basic-default-codigo-selected">Valor Total</label>
                                <NumericFormat 
                                    displayType="input" 
                                    className="form-control" 
                                    value={selected.fund.unit_price * selected.qty} 
                                    decimalScale={selected.fund.decimals}
                                    allowLeadingZeros thousandSeparator="." 
                                    decimalSeparator="," 
                                    disabled="true"
                                /> 
                            </div>
                        </Grid>
                        <Grid xs={6} className="pt-3">
                            <div className="pt-4">
                                <Checkbox label="Pago ACDI" variant="soft" checked={selected.is_acdi}   />
                            </div>
                        </Grid>
                        <Grid xs={12}>
                            <div>
                                <label>Observaciones</label>
                                <textarea class="form-control" rows="6" 
                                    value={selected.observations}
                                    disabled="true" 
                                />
                            </div>
                        </Grid>
                        
                    </Grid> 
                </DialogContent>
                <Divider />
                <DialogActions>
                    <button type="button" className="btn btn-outline-secondary"  onClick={() => handleOpen(false)}> Cerrar </button>                    
                </DialogActions>
            </ModalDialog>
        </Modal>

    );
}

export default ModalViewFund;