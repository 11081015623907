import api from "../apiapp";

const operationService = {

  setOperations: async function (model) {
    const response = await api.request({
      url: 'operations',
      method: "POST",
      data: model
    })
    
    return response.data
  },
  getOperationsById: async function (id) {
    const response = await api.request({
      url: 'operations/'+id+'?include=account,user,operationLogs,settlementTerm,operationType,currency,market,segment,priceType,operationState',
      method: "GET",
    })
    
    return response.data
  },
  getOperationSearch: async function (model) {
    var filters = '';
		if(model.dateFrom !== '' && model.dateFrom !==""){ filters += '&filter[from]='+model.dateFrom; }
		if(model.dateTo !== '' && model.dateTo !== ""){ filters += '&filter[to]='+model.dateTo; }
		if(model.account !== '' && model.account !== null){ filters += '&filter[account]='+model.account; }
		if(model.user !== '' && model.user !== null){ filters += '&filter[user]='+model.user; }
		if(model.status !== '' && model.status !== null){ filters += '&filter[state]='+model.status; }
		// if(model.status != '' && model.status != null){ filters += '&filter[state]='+model.status.id; }
    const response = await api.request({
      url: 'operations/?include=account,user,operationLogs,settlementTerm,operationType,currency,market,segment,priceType,operationState,blockedBy&sort=date&page[size]=50'+filters,
      method: "GET",
    })
    
    return response.data
  },
  getOperationsSearchPagination: async function (url) {
    const response = await api.request({
      url: url,
      method: "GET",
    })
    
    return response.data
  },
  getOperationExcel: async function (model) {
    var filters = '';
		if(model.dateFrom !== '' && model.dateFrom !== ""){ filters += '&filter[from]='+model.dateFrom; }
		if(model.dateTo !== '' && model.dateTo !== ""){ filters += '&filter[to]='+model.dateTo; }
		if(model.account !== '' && model.account != null){ filters += '&filter[account]='+model.account; }
		if(model.user !== '' && model.user !== null){ filters += '&filter[user]='+model.user.id; }
		if(model.status !== '' && model.status !== null){ filters += '&filter[state]='+model.status; }
		// if(model.status != '' && model.status != null){ filters += '&filter[state]='+model.status.id; }
    const response = await api.request({
      url: 'operations/download/excel?'+filters,
      method: "GET",
    })
    
    return response.data
  },
  getOperationsDay: async function () {
    const response = await api.request({
      url: 'operations/reports/today?sort=-date&include=account,user,settlementTerm,operationType,currency,market,segment,priceType,operationState',
      method: "GET",
    })
  
    return response.data
  },
  putOperationStatus: async function (id, model) {
    const response = await api.request({
      url: 'operations/'+id+'/change-state',
      method: "PUT",
      data: model
    })
  
    return response.data
  },
  // DRAFT
  getOperationDraftSearch: async function (model) {
    var filters = '';
		if(model.dateFrom !== '' && model.dateFrom !== ""){ filters += '&filter[from]='+model.dateFrom; }
		if(model.dateTo !== '' && model.dateTo !== ""){ filters += '&filter[to]='+model.dateTo; }
		if(model.account !== '' && model.account != null){ filters += '&filter[account]='+model.account; }
		if(model.user !== '' && model.user !== null){ filters += '&filter[user]='+model.user; }
		const response = await api.request({
      url: 'operation-drafts/?include=account,user,operationLogs,settlementTerm,operationType,currency,market,segment,priceType,operationState&sort=-created_at&page[size]=50'+filters,
      method: "GET",
    })
    
    return response.data
  },
  deleteOperationsDraft: async function (id) {
    const response = await api.request({
      url: 'operation-drafts/'+id,
      method: "DELETE",
    })
  
    return response.data
  },
  setDraftOperations: async function (model) {
    const response = await api.request({
      url: 'operation-drafts/',
      method: "POST",
      data: model
    })
  
    return response.data
  }, 
  putOperationDraft: async function (model, id) {
    const response = await api.request({
      url: 'operation-drafts/'+id,
      method: "PUT",
      data: model
    })
  
    return response.data
  },
  getOperationsDraftById: async function (id) {
    const response = await api.request({
      url: 'operation-drafts/'+id+'?include=account,user,operationLogs,settlementTerm,operationType,currency,market,segment,priceType,operationState',
      method: "GET",
    })
  
    return response.data
  },

}

export default operationService;