



function Loading() {
    return (
        <div className="row">   
            <div className="col-12 d-grid gap-2 d-md-flex justify-content-md-center">
                <div className="d-flex justify-content-center">
                    <div className="spinner-border text-info" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Loading;