import React, {useState, useEffect} from "react";
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import DialogActions from '@mui/joy/DialogActions';
import Divider from '@mui/joy/Divider';
import Grid from '@mui/joy/Grid';
import { toast } from 'react-toastify';
import Swal from "sweetalert2";
import axios from 'axios'
// import { NumericFormat } from 'react-number-format';
import SecuritieService from "../../../services/securitieService";


function ModalNewTicker( {handleOpen, isOpen, listTerm}) {

    const initModel = {
        instrumentoAbrev: '',
        instrumentoDesc: '',
        monedaDescripcion: '',
        codInstrumento: null,
        instrumentoCodigo: null,
        codTpInstrumento: null,
        codIdMercado: null,
        mercadoDescripcion: null,
        codMercado: null,
        codMoneda: "1",
        moneda: null,
        monedaSimbolo: null,
        isinCode: null,
        codTpEspecie: null,
        tpEspecie: null,
        codEspecie: null,
        unidadCoti: null,
        active: true
    }
    const [sending, setSending] = useState(false)
    const [newModel, setNewModel] = useState(initModel)

    const doSave = async () => {
        if(validateNewForm()){
            try {  
                setSending(true)
                const response = await SecuritieService.setTicker(newModel)
                toast.success('Especie creada!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                handleOpen(false)
                setNewModel(initModel)
                setSending(false)
            } catch (e) {
                if (axios.isAxiosError(e)) {
                    const statusCode = e.response?.status
                    const error = e.response?.data.errors;
                    if(statusCode && statusCode !== 401){
                        Swal.fire({
                            title: 'ERROR',
                            text: error[0].detail,
                            icon: 'error',
                            confirmButtonText: 'OK',
                            confirmButtonColor: '#f13939'
                        })
                    }else{
                        localStorage.removeItem("userProfile");
                        window.location.reload();
                    }
                }
                setSending(false)
            }

        }else{
            toast.error('Cargue los datos correctamente!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    const tgrCurrency = async (curr) => {
        if(curr !== null){
            if(curr == "1"){
                setNewModel({
                    ...newModel,
                    codMoneda: curr,
                    monedaDescripcion : "PESOS",
                    moneda : "ARS"
                })
            }else if(curr == "2"){
                setNewModel({
                    ...newModel,
                    codMoneda: curr,
                    monedaDescripcion : "DOLAR CABLE",
                    moneda : "USD"
                })
            }else if(curr == "3"){
                setNewModel({
                    ...newModel,
                    codMoneda: curr,
                    monedaDescripcion : "DOLAR MEP",
                    moneda : "USDMEP"
                })
            }
        }
    }

    function validateNewForm (){
        let rta = true;
        if(newModel.instrumentoAbrev === '' || newModel.instrumentoAbrev === undefined  || newModel.instrumentoAbrev === null){ return false; } 
        if(newModel.instrumentoDesc === '' || newModel.instrumentoDesc === undefined  || newModel.instrumentoDesc === null){ return false; } 
        if(newModel.codMoneda === '' || newModel.codMoneda === undefined  || newModel.codMoneda === null){ return false; } 
        return rta;
    }

    return (
        <Modal
            aria-labelledby="close-modal-title"
            open={isOpen}
            onClose={() => handleOpen(false) }
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <ModalDialog>
                <ModalClose variant="outlined" />
                <DialogTitle>  Nueva Especie</DialogTitle>
                <Divider />
                <DialogContent>
                    <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                        <Grid xs={6}>
                            <div>
                                <label className="form-label" htmlFor="basic-default-nombre-selected">Abreviatura <span style={{color:'red'}}>*</span></label>
                                <input type="text" className="form-control" id="basic-default-nombre-selected" placeholder="Ingrese Abreviatura"                                
                                    value={newModel.instrumentoAbrev}  onChange={(e)=> setNewModel({...newModel, instrumentoAbrev: e.target.value}) }
                                />
                            </div>
                        </Grid>
                        <Grid xs={6}>
                            <div>
                                <label className="form-label" htmlFor="basic-default-codigo-selected">Descripción <span style={{color:'red'}}>*</span></label>
                                <input type="text" className="form-control" id="basic-default-codigo-selected" placeholder="Ingrese Descripción "                                
                                    value={newModel.instrumentoDesc}  onChange={(e)=> setNewModel({...newModel, instrumentoDesc: e.target.value}) }
                                />
                            </div>
                        </Grid>
                        <Grid xs={6}>
                            <div>
                                <label className="form-label" htmlFor="basic-default-moneda-selected">Moneda <span style={{color:'red'}}>*</span></label>
                                <select className="form-select" id="basic-default-moneda-selected" 
                                    defaultValue={'1'} 
                                    onChange={(e)=> tgrCurrency(e.target.value) }
                                >
                                    <option value="1">Pesos</option>
                                    <option value="2">Dolar Cable</option>
                                    <option value="3">Dolar MEP</option>
                                </select> 
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <button type="button" className="btn btn-info" 
                        onClick={() => doSave() }
                        disabled={sending}
                    >
                        Guardar
                    </button>
                    <button type="button" className="btn btn-outline-secondary"  onClick={() => handleOpen(false)}> Cerrar </button>                    
                </DialogActions>
            </ModalDialog>
        </Modal>

    );
}

export default ModalNewTicker;