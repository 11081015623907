import React, {useState, useEffect} from "react";
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import Divider from '@mui/joy/Divider';
import Grid from '@mui/joy/Grid';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Textarea from '@mui/joy/Textarea';


function ModalViewDayObservations( {handleOpen, isOpen, selected, opeId}) {
    
    return (
        <Modal
            aria-labelledby="close-modal-title"
            open={isOpen}
            onClose={() => handleOpen(false) }
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <ModalDialog>
                <ModalClose variant="outlined" />
                <DialogTitle>  Operación: #{opeId}</DialogTitle>
                <Divider />
                <DialogContent>
                    <Grid container spacing={1} sx={{ flexGrow: 1 }}>
                        <Grid xs={12}>
                            <FormControl>
                                <FormLabel className="form-labels">Observciones</FormLabel>
                                <Textarea
                                    disabled={true}
                                    value={selected.observations}
                                    minRows={4}
                                    maxRows={4}
                                />
                            </FormControl>
                        </Grid>
                        
                    </Grid> 
                </DialogContent>
            </ModalDialog>
        </Modal>

    );
}

export default ModalViewDayObservations;