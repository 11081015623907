import React, {useState} from "react";

function UserSearch( {handleOpen, mainModel, handleModel, roles}) {

    const doSearch = async () => {
        handleOpen()
    }

    return ( 
        <div>
                <div
                    className="offcanvas offcanvas-end"
                    tabIndex="-1"
                    id="offcanvasEnd"
                    aria-labelledby="offcanvasEndLabel"
                >
                    <div className="offcanvas-header">
                        <h5 id="offcanvasEndLabel" className="offcanvas-title">
                            <i className="tf-icons bx bx-filter" /> Buscar
                        </h5>
                        <button
                            type="button"
                            className="btn-close text-reset"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="offcanvas-body ">
                        <form>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="basic-default-fullname">Nombre</label>
                                <input type="text" className="form-control" id="basic-default-fullname" placeholder="Ingrese Nombre del usuario" 
                                    value={mainModel.name}  onChange={(e)=> handleModel({...mainModel, name: e.target.value}) }
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="basic-default-email">Email</label>
                                <div className="input-group input-group-merge">
                                    <input type="email" id="basic-default-email" className="form-control" placeholder="Ingrese email del usuario" aria-label="john.doe" aria-describedby="basic-default-email2" 
                                        value={mainModel.email}  onChange={(e)=> handleModel({...mainModel, email: e.target.value}) }
                                    />
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="exampleFormControlSelect1" className="form-label">Perfil</label>
                                <select className="form-select" id="roleControlSelect" aria-label=""  
                                    onChange={(e)=> handleModel({...mainModel, role: e.target.value})}>
                                    <option selected value={''}>Todos</option>
                                    {roles.map( (role) => 
                                        <option key={role.id}  value={role.id}>{role.name}</option> )
                                    }
                                </select> 
                            </div>
                        </form>

                        <div className="mt-5">
                                <div>
                                    <button type="button" className="btn btn-info mb-2 d-grid w-100" data-bs-dismiss="offcanvas" onClick={doSearch}>Buscar</button>
                                </div>
                            
                        </div>
                        
                    </div>
                </div>
            </div>
    );
}

export default UserSearch;