import React, {useState} from "react";
import Swal from "sweetalert2";
import axios from 'axios'
import authService from "../../services/authService";
import {useNavigate} from "react-router-dom"
import { Link } from "react-router-dom";
import Grid from '@mui/joy/Grid';
import Input from '@mui/joy/Input';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';

function NewPassword() {
  
	
    let url = window.location.href;
    let parameters = url.split('?');
    var mail = parameters[2].split('=')[1];
    var vtoken = parameters[1];
    const initModel = {
      password: '',
      password_confirmation: '',
      email: mail,
      via: 'web',
      token: vtoken,
    }
    const navigate = useNavigate();
    const [sending, setSending] = useState(false)
    const [newpass, setNewPass] = useState('');
    const [newpass2, setNewPass2] = useState('');
    const [formModel, setFormModel] = useState(initModel);
    
  
    const doSummit = async (e) => {
      setSending(true)
      if(!validateForm()){
        Swal.fire({
          title: 'Ooops?',
          text: 'Debe completar los datos para poder avanzar!',
          icon: 'error',
          confirmButtonText: 'OK',
          confirmButtonColor: '#f13939'
        })
      }else{
        try {
          setFormModel({
            ...formModel,
            password: newpass,
            password_confirmation: newpass2,
          })
          const response = await authService.setNewPassword(formModel)
          Swal.fire({
            title: 'Perfecto!',
            text: 'Te enviamos un mail con la clave temporal. Si no lo recibiste, te olvides de revisar la carpeta de SPAM!',
            icon: 'success',
            confirmButtonText: 'OK',
            confirmButtonColor: '#97e36b'
          })
          // .then(function(){ navigate("/") })
          
        } catch (e) {
          if (axios.isAxiosError(e)) {
            // console.log(e.request.status)
            const statusCode = e.response?.status
            const error = e.response?.data.errors;
            let message = error[0].detail;
            Swal.fire({
              title: 'ERROR',
              text: message,
              icon: 'error',
              confirmButtonText: 'OK',
              confirmButtonColor: '#f13939'
            })
          }
        } finally{ setSending(false) }
      }
      setSending(false)
    }

    const validateForm = function(){
      let rta = true;
      if(newpass === '' || newpass === undefined  || newpass === null){ return false; } 
      if(newpass2  === '' || newpass2 === undefined  || newpass2 === null){ return false; } 
      if(newpass2  !== newpass){ return false; } 
       
      return rta;
  }

    return (
        <div>
          <div className="container-xxl">
            <div className="authentication-wrapper authentication-basic container-p-y">
              <div className="authentication-inner">
                {/* New Password */}
                <div className="card">
                  <div className="card-body">
                    {/* Logo */}
                    <div className="app-brand justify-content-center">
                      <a href="/" className="app-brand-link gap-2">
                        <img src="alchemy-logo.png" alt="alchemy valores logo" className="img-fluid" style={{width: '100%'}}/>
                      </a>
                    </div>
                    {/* /Logo */}
                    <h4 className="mb-2">Olvidedaste Contraseña? 🔒</h4>
                    <p className="mb-4">Ingresa tu nueva clave.</p>
                      <Grid container spacing={2} sx={{ flexGrow: 1 }} className={'mb-4'}>
                        <Grid xs={12}>
                          <FormControl>
                              <FormLabel className="form-labels">Nueva contraseña</FormLabel>
                              <Input type="password" size="md" placeholder="Ingrese Nueva contraseña" autoFocus onChange={(e)=> setNewPass(e.target.value)}/>
                          </FormControl>
                        </Grid>
                        <Grid xs={12}>
                          <FormControl>
                              <FormLabel className="form-labels">Repetir Nueva contraseña</FormLabel>
                              <Input type="password" size="md" placeholder="Repetir la nueva contraseña" onChange={(e)=> setNewPass2(e.target.value)}/>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <div className="mb-3">
                      { sending ? 
                        <div className="d-flex justify-content-center">
                          <div className="spinner-border text-info" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      : 
                        <button className="btn btn-info d-grid w-100" type="button" onClick={doSummit}>Crear nueva contraseña</button> 
                      } 
                      </div>
                      <div className="text-center">
                        <Link to='/' className="d-flex align-items-center justify-content-center">
                            <i className="bx bx-chevron-left scaleX-n1-rtl bx-sm" />
                            Volver
                        </Link>
                    </div>

                  </div>
                </div>
                {/* /Register */}
              </div>
            </div>
          </div>
          <footer style={{textAlign: 'right', marginRight: 20,fontSize: 'small', marginTop: '-25px'}}>
            Power by <a href="https://globemind.com/" target="_blank" style={{color: '#396bad'}}>GlobeMind</a>
          </footer>
        </div>        
  
      );
}

  
export default NewPassword;