import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom"
import Grid from '@mui/joy/Grid';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab, { tabClasses } from '@mui/joy/Tab';
import TabPanel from '@mui/joy/TabPanel';
import OperationsMoney from "./operations-money";
import OperationsInstrumento from "./operations-instrumento";
import OperationsBonos from "./operations-bonos";
import OperationsFCI from "./operations-fci";

function Operations() {

    const navigate = useNavigate();
    const [cargando, setCargando] = useState(false)


    return (
        <div className="container-fluid flex-grow-1 container-p-y">
            {/* <div className="row mb-3">
                <div className="col"><h2>Operaciones</h2> </div>
            </div> */}
            
            <div className="row">

                <Tabs aria-label="tabs" defaultValue={0} sx={{ bgcolor: 'transparent' }} >
                    <TabList
                        disableUnderline
                        sx={{
                        p: 0.5,
                        gap: 0.5,
                        borderRadius: 'xl',
                        // bgcolor: 'background.level1',
                        bgcolor: 'rgba(131, 118, 109, 0.16)',
                        [`& .${tabClasses.root}[aria-selected="true"]`]: {
                            boxShadow: 'sm',
                            bgcolor: 'background.surface',
                            // bgcolor: '#FFFFFF',
                        },
                        }}
                    >
                        <Tab disableIndicator>Bonos</Tab>
                        {/* <Tab disableIndicator>Inter. Moneda</Tab> */}
                        {/* <Tab disableIndicator>Inter. Instrumentos</Tab> */}
                        <Tab disableIndicator>FCI</Tab>
                    </TabList>
                            
                    <TabPanel value={0}>
                        <OperationsBonos />
                    </TabPanel>

                    {/* <TabPanel value={1}>
                        <OperationsMoney />
                    </TabPanel>

                    <TabPanel value={2}>
                        <OperationsInstrumento />
                    </TabPanel> */}

                    <TabPanel value={1}>
                        <OperationsFCI />
                    </TabPanel>

                </Tabs>
            </div>
            
    

        </div>   
    );

}

export default Operations;