import api from "../apiapp";


const statementService = {
    getStatements: async function () {
        const response = await api.request({
          url: "statement-signatures?include=swornStatement,sentBy,statementSignatureStatus&sort=-id&page[size]=30",
          method: "GET",
        })

        return response.data
    },
    getStatementsSearchPagination: async function (purl) {
      const response = await api.request({
        url: purl,
        method: "GET",
      })
      
      return response.data
    },
    getStatementSearch: async function (name, noti, status, date) {
      var filters = '';
      if(name !== ""){ filters += '&filter[name]='+name; }
      if(noti !== ""){ filters += '&filter[swornStatement]='+noti; }
      if(status !== ""){ filters += '&filter[status]='+status; }
      if(date !== "" && date !== null){ filters += '&filter[signedAt]='+date; }
        
      const response = await api.request({
        url: "/statement-signatures?include=swornStatement,sentBy,statementSignatureStatus&sort=-id&page[size]=30"+filters,
        method: "GET",
      })
    
      return response.data
    },
    getStatementsLists: async function () {
      const response = await api.request({
        url: '/sworn-statements?page[size]=100',
        method: "GET",
      })
      
      return response.data
    },
    sendStatements: async function (model) {
      const response = await api.request({
        url: '/statement-signatures',
        method: "POST",
        data: model
      })
      
      return response.data
    },

}
  
export default statementService;