import React, {useState} from "react";

function DocumentSearch( modelar, handleModel, statements, handleSearch) {

    const doSearch = async () => {
        handleSearch()
    }

    return ( 
        <div>
            <div
                className="offcanvas offcanvas-end"
                tabIndex="-1"
                id="offcanvasEnd"
                aria-labelledby="offcanvasEndLabel"
            >
                <div className="offcanvas-header">
                    <h5 id="offcanvasEndLabel" className="offcanvas-title">
                        <i className="tf-icons bx bx-filter" /> Buscar
                    </h5>
                    <button
                        type="button"
                        className="btn-close text-reset"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                    ></button>
                </div>
                <div className="offcanvas-body ">
                    <form>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="basic-default-fullname">Comitente/Nombre</label>
                            {/* <input type="text" className="form-control" id="basic-default-fullname" placeholder="Comitente/Nombre" onChange={(e)=> handleModel({...model, name: e.target.value}) } */}
                                {/* // onChange={(e)=> setSearchName(e.target.value)}  */}
                            {/* /> */}
                            <input type="text" className="form-control" id="basic-default-fullname" placeholder="Ingrese Nombre del usuario" 
                                    value={modelar.name}  onChange={(e)=> handleModel({...modelar, name: e.target.value}) }
                            />
                                
                        </div>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="notificationControlSelect">Notificaciones</label>
                            {/* <select className="form-select" id="notificationControlSelect" defaultValue={''} onChange={(e)=> setSearchNoti(e.target.value)}>
                                <option selected value={''}>---- Todas las Notoficaciones ----</option>
                                {listSearchStatement
                                    .sort((a, b) => a.attributes.active > b.attributes.active ? -1 : 1)
                                    .map( (statement) => {
                                        if(statement.attributes.active){
                                            return <option value={statement.id} key={statement.id}>{statement.attributes.name} (Vigente)</option> 
                                        }else{
                                            return <option value={statement.id} key={statement.id}>{statement.attributes.name} (No Vigente)</option> 
                                        }
                                    }
                                    
                                )}
                            </select>  */}
                        </div>
                        {/* <div className="mb-3">
                            <label htmlFor="exampleFormControlSelect1" className="form-label">Estados</label>
                            <select className="form-select" defaultValue={''} 
                                onChange={(e)=> handleModel({...model, status: e.target.value}) }
                            // onChange={(e)=> setSearchStatus(e.target.value)}
                            >
                                <option selected value="">---- Todos los Estados ----</option>
                                <option value="1">Pendiente</option>
                                <option value="2">Firmada</option>
                                <option value="3">Cancelada</option>
                            </select> 
                        </div>
                        <div className="mb-3">
                            <label htmlFor="exampleFormControlSelect1" className="form-label">Fecha de Firma</label>
                            <input className="form-control" type="date" id="html5-date-input" onChange={(e)=> setSearchDate(e.target.value)}/>
                        </div> */}
                    </form>

                    <div className="mt-5">
                        <div>
                            <button type="button" className="btn btn-info mb-2 d-grid w-100" data-bs-dismiss="offcanvas" onClick={doSearch}>Buscar</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default DocumentSearch;