import React, {useState, useEffect} from "react";
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import DialogActions from '@mui/joy/DialogActions';
import Divider from '@mui/joy/Divider';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Grid from '@mui/joy/Grid';
import { toast } from 'react-toastify';
import Swal from "sweetalert2";
import axios from 'axios'
import userService from "../../../services/userService";


function UserModalNew( {handleOpen, isOpen, listRoles}) {

    const initProp = {
        name: '',
        last_name: '',
        email: '',
        password: '',
        password_confirmation: '',
        role: '',
        change_password: 0,
        password_generate: 0,
    }
    const [sending, setSending] = useState(false)
    const [newModel, setNewModel] = useState(initProp)

    const doClose = async () => {
        setNewModel(initProp)
        handleOpen(false)
    }
    
    const doSave = async () => {
        if(validateNewForm()){
            try {  
                setSending(true)
                const response = await userService.setUsers(newModel)
                toast.success('Usuario creado!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                handleOpen(false)
                setNewModel(initProp)
                setSending(false)
            } catch (e) {
                if (axios.isAxiosError(e)) {
                    const statusCode = e.response?.status
                    if(statusCode && statusCode !== 401){
                        Swal.fire({
                            title: 'ERROR',
                            // text: error[0].detail,
                            text: "Error al intentar crear usuario.",
                            icon: 'error',
                            confirmButtonText: 'OK',
                            confirmButtonColor: '#f13939'
                        })
                    }else{
                        localStorage.removeItem("userProfile");
                        window.location.reload();
                    }
                }
                setSending(false)
            }

        }else{
            toast.error('Cargue los datos correctamente!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    const doSelectChange = async (obj) => {
        setNewModel({
            ...newModel, 
            role: obj,
        })
    }

    function validateNewForm (){
        let rta = true;
        const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
        if(newModel.name === '' || newModel.name === undefined  || newModel.name === null){ return false; } 
        if(newModel.last_name === '' || newModel.last_name === undefined  || newModel.last_name === null){ return false; } 
        if(newModel.email === '' || newModel.email === undefined  || newModel.email === null){ return false; } 
        if(newModel.role === '' || newModel.role === undefined  || newModel.role === null){ return false; } 
        if(newModel.password === '' || newModel.password === undefined  || newModel.password === null){ return false; } 
        if(newModel.password_confirmation === '' || newModel.password_confirmation === undefined  || newModel.password_confirmation === null){ return false; } 
        if(newModel.password_confirmation !== newModel.password){ return false; }
        if(!newModel.email.match(isValidEmail)){ return false; }
        return rta;
    }



    return (
        <Modal
            aria-labelledby="close-modal-title"
            open={isOpen}
            onClose={doClose}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <ModalDialog>
                <ModalClose variant="outlined" />
                <DialogTitle>Nuevo Usuario</DialogTitle>
                <Divider />
                <DialogContent>                
                    <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                        <Grid xs={6}>
                            <div>
                                <label className="form-label" htmlFor="nombre-selected">Nombre <span style={{color:'red'}}>*</span></label>
                                <input type="text" className="form-control" id="nombre-selected" placeholder="Ingrese Nombre del usuario"                                
                                    value={newModel.name}  onChange={(e)=> setNewModel({...newModel, name: e.target.value}) }
                                />
                            </div>
                        </Grid>
                        <Grid xs={6}>
                            <div>
                                <label className="form-label" htmlFor="lastname-selected">Apellido <span style={{color:'red'}}>*</span></label>
                                <input type="text" className="form-control" id="lastname-selected" placeholder="Ingrese Apellido del usuario "                                
                                    value={newModel.last_name}  onChange={(e)=> setNewModel({...newModel, last_name: e.target.value}) }
                                />
                            </div>
                        </Grid>
                        <Grid xs={6}>
                            <div>
                                <label className="form-label" htmlFor="email-selected">Email / Usuario <span style={{color:'red'}}>*</span></label>
                                <input type="email" className="form-control" id="email-selected" placeholder="Ingrese email del usuario "                                
                                    value={newModel.email}  onChange={(e)=> setNewModel({...newModel, email: e.target.value}) }
                                />
                            </div>
                        </Grid>
                        <Grid xs={6}>
                            <div>
                                <label className="form-label" htmlFor="role-selected">Role <span style={{color:'red'}}>*</span></label>
                                <Select id="emrpesa" className="form-control" placeholder="Seleccione ..." 
                                    value={newModel.role}  
                                    onChange={(e, newValue) => doSelectChange(newValue) } >   
                                    {listRoles.map( (role) => 
                                        <Option key={role.id} value={role.name}>{role.name}</Option> )
                                    }  
                                </Select>
                            </div>
                        </Grid>
                        <Grid xs={6}>
                            <div>
                                <label className="form-label" htmlFor="password-selected">Contraseña <span style={{color:'red'}}>*</span></label>
                                <input type="password" className="form-control" id="password-selected" placeholder="Ingrese Contraseña "                                
                                    value={newModel.password}  onChange={(e)=> setNewModel({...newModel, password: e.target.value}) }
                                />
                            </div>
                        </Grid>
                        <Grid xs={6}>
                            <div>
                                <label className="form-label" htmlFor="repassword-selected">Confirme Contraseña <span style={{color:'red'}}>*</span></label>
                                <input type="password" className="form-control" id="repassword-selected" placeholder="Confirme Contraseña "                                
                                    value={newModel.password_confirmation}  onChange={(e)=> setNewModel({...newModel, password_confirmation: e.target.value}) }
                                />
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <button type="button" className="btn btn-info" 
                        onClick={() => doSave() }
                        disabled={sending}
                    >
                        Guardar
                    </button>
                    <button type="button" className="btn btn-outline-secondary"  onClick={doClose}> Cerrar </button>                    
                </DialogActions>
            </ModalDialog>
        </Modal>

    );
}

export default UserModalNew;