import React, {useState, useEffect} from "react";
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import DialogActions from '@mui/joy/DialogActions';
import Divider from '@mui/joy/Divider';
import Grid from '@mui/joy/Grid';
import { toast } from 'react-toastify';
import Swal from "sweetalert2";
import axios from 'axios'
// import { NumericFormat } from 'react-number-format';
import AccountService from "../../../services/accountService";


function ModalNewAccount( {handleOpen, isOpen, listTerm}) {

    const initModel = {
        account: '',
        name: '',
        portfolio_manager: '',
        emails: ''
    }
    const [sending, setSending] = useState(false)
    const [newModel, setNewModel] = useState(initModel)

    const doSave = async () => {
        if(validateNewForm()){
            try {  
                setSending(true)
                const response = await AccountService.setAccount(newModel)
                toast.success('Comitente creada!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                handleOpen(false)
                setNewModel(initModel)
                setSending(false)
            } catch (e) {
                if (axios.isAxiosError(e)) {
                    const statusCode = e.response?.status
                    const error = e.response?.data.errors;
                    if(statusCode && statusCode !== 401){
                        Swal.fire({
                            title: 'ERROR',
                            text: error[0].detail,
                            icon: 'error',
                            confirmButtonText: 'OK',
                            confirmButtonColor: '#f13939'
                        })
                    }else{
                        localStorage.removeItem("userProfile");
                        window.location.reload();
                    }
                }
                setSending(false)
            }

        }else{
            toast.error('Cargue los datos correctamente!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }


    function validateNewForm (){
        let rta = true;
        if(newModel.account === '' || newModel.account === undefined  || newModel.account === null){ return false; } 
        if(newModel.name === '' || newModel.name === undefined  || newModel.name === null){ return false; } 
        // if(newModel.emails === '' || newModel.emails === undefined  || newModel.emails === null){ return false; } 
        return rta;
    }

    return (
        <Modal
            aria-labelledby="close-modal-title"
            open={isOpen}
            onClose={() => handleOpen(false) }
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <ModalDialog>
                <ModalClose variant="outlined" />
                <DialogTitle>  Nuevo Comitente</DialogTitle>
                <Divider />
                <DialogContent>
                    <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                        <Grid xs={6}>
                            <div>
                                <label className="form-label" htmlFor="basic-default-nombre-selected">Código <span style={{color:'red'}}>*</span></label>
                                <input type="text" className="form-control" id="basic-default-nombre-selected" placeholder="Ingrese Abreviatura"                                
                                    value={newModel.account}  onChange={(e)=> setNewModel({...newModel, account: e.target.value}) }
                                />
                            </div>
                        </Grid>
                        <Grid xs={6}>
                            <div>
                                <label className="form-label" htmlFor="basic-default-codigo-selected">Nombre <span style={{color:'red'}}>*</span></label>
                                <input type="text" className="form-control" id="basic-default-codigo-selected" placeholder="Ingrese Descripción "                                
                                    value={newModel.name}  onChange={(e)=> setNewModel({...newModel, name: e.target.value}) }
                                />
                            </div>
                        </Grid>
                        <Grid xs={6}>
                            <div>
                                <label className="form-label" htmlFor="basic-default-codigo-selected">Adm. Cartera <span style={{color:'red'}}>*</span></label>
                                <input type="text" className="form-control" id="basic-default-codigo-selected" placeholder="Ingrese Descripción "                                
                                    value={newModel.portfolio_manager}  onChange={(e)=> setNewModel({...newModel, portfolio_manager: e.target.value}) }
                                />
                            </div>
                        </Grid>
                        <Grid xs={6}>
                            <div>
                                <label className="form-label" htmlFor="basic-default-codigo-selected">Mail <span style={{color:'red'}}>*</span></label>
                                <input type="text" className="form-control" id="basic-default-codigo-selected" placeholder="Ingrese Descripción "                                
                                    value={newModel.emails}  onChange={(e)=> setNewModel({...newModel, emails: e.target.value}) }
                                />
                            </div>
                        </Grid>
                        
                    </Grid>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <button type="button" className="btn btn-info" 
                        onClick={() => doSave() }
                        disabled={sending}
                    >
                        Guardar
                    </button>
                    <button type="button" className="btn btn-outline-secondary"  onClick={() => handleOpen(false)}> Cerrar </button>                    
                </DialogActions>
            </ModalDialog>
        </Modal>

    );
}

export default ModalNewAccount;