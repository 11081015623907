import React, {useState, useContext, useEffect, useRef} from "react";
import { Link } from "react-router-dom";
import {MyContext} from "../context/MyContext";


function Menu() {

  const initialized = useRef(false)
  const [active, setActive] = useState('HOME');
  const [open, setOpen] = useState(false);
  const [subactive, setSubActive] = useState(null);
  const [selected, setSelected] = useState(null);
  const { toggleMenu, setToggleMenu } = useContext(MyContext);

  useEffect(() => {
    if (!initialized.current) {
        initialized.current = true
        // doSearch()
        let menuOption = localStorage.getItem('menuOption');
        let submenuOption = localStorage.getItem('submenuOption');
        if(menuOption !==''){ setMenu(menuOption) }
        if(submenuOption !==''){ setSubmenu(menuOption, submenuOption) }
        // console.log("MENU ",menuOption)
        // console.log("SUBMENU ",submenuOption)
    }
}, []);
  
  function setMenu (option)  {
    setOpen(!open); 
    setSelected(option);
    localStorage.setItem('menuOption', option );

  }

  function setSubmenu (parent, child)  {
    setSubActive(child)
    setActive(parent)
    setSelected(parent)
    setOpen(true)
    localStorage.setItem('menuOption', parent );
    localStorage.setItem('submenuOption', child );
  }

    return (
      <span>
      {toggleMenu &&
        <aside id="layout-menu" className="layout-menu menu-vertical menu bg-menu-theme">
          <div className="menu-inner-shadow" />
            <ul className="menu-inner py-1">

            {/*   <li  id="HOME" 
                onClick={() => setActive('HOME') }
                className={`menu-item ${active === 'HOME' && "active"}`}
              >
                <Link to="/" className="menu-link">
                  <i className="menu-icon tf-icons bx bx-home-circle" />
                  <div data-i18n="Analytics">Inicio</div>
                </Link>
              </li> */}

              {/* MESA */}
              <li id="MESA"
                onClick={() =>  { setMenu('MESA') } }
                className={`menu-item ${active === 'MESA' && " active open"} ${(selected === 'MESA') && "open"} `}
              >
                <Link href="javascript:void(0);" className="menu-link menu-toggle ">
                  <i className="menu-icon bx bxs-bank" />
                  <div data-i18n="Layouts">Mesa</div>
                </Link>
                <ul className="menu-sub">
                  <li id="MESA-DASHBOARD"
                    onClick={() => setSubmenu('MESA','MESA-DASHBOARD')}
                    className={`menu-item ${subactive === 'MESA-DASHBOARD' && "active"}`}
                  >
                    <Link to="/mesaDashboard" className="menu-link ">
                      <div data-i18n="Without menu ">Dashboard</div>
                    </Link>
                  </li>
                  <li id="MESA-OPERACIONES"
                    onClick={() => setSubmenu('MESA','MESA-OPERACIONES')}
                    className={`menu-item ${subactive === 'MESA-OPERACIONES' && "active"}`}
                  >
                    <Link to="/mesaOperations" className="menu-link ">
                      <div data-i18n="Without menu ">Operaciones</div>
                    </Link>
                  </li>
                  {/* <li id="MESA-OPERACIONES"
                    onClick={() => setSubmenu('MESA','MESA-DIA-OPERACIONES')}
                    className={`menu-item ${subactive === 'MESA-DIA-OPERACIONES' && "active"}`}
                  >
                    <Link to="/mesaDayOperations" className="menu-link ">
                      <div data-i18n="Without menu ">Operaciones del Día</div>
                    </Link>
                  </li> */}
                  <li id="MESA-OPERACIONES"
                    onClick={() => setSubmenu('MESA','MESA-ESCO-OPERACIONES')}
                    className={`menu-item ${subactive === 'MESA-ESCO-OPERACIONES' && "active"}`}
                  >
                    <Link to="/mesaEscoOperations" className="menu-link ">
                      <div data-i18n="Without menu ">Operaciones ESCO</div>
                    </Link>
                  </li>
                </ul>
              </li>

              {/* <li id="DDJJ"
                onClick={() =>  { setMenu('DDJJ') } }
                className={`menu-item ${active === 'DDJJ' && " active open"}  ${(selected === 'DDJJ') && "open"} `}
              >
                <Link href="/" className="menu-link menu-toggle ">
                  <i className="menu-icon tf-icons bx bx-file" /> 
                  <div data-i18n="Layouts">DD.JJ.</div>
                </Link>
                <ul className="menu-sub">
                  <li id="DDJJ-NOTIFICACIONES"
                    onClick={() => setSubmenu('DDJJ','DDJJ-NOTIFICACIONES')}
                    className={`menu-item ${subactive === 'DDJJ-NOTIFICACIONES' && "active"}`}
                  >
                    <Link to="/notifications" className="menu-link ">
                      <div data-i18n="Without menu ">Notificaciones</div>
                    </Link>
                  </li>
                  <li id="DDJJ-DOCUMENTOS"
                    onClick={() => setSubmenu('DDJJ','DDJJ-DOCUMENTOS')}
                    className={`menu-item ${subactive === 'DDJJ-DOCUMENTOS' && "active"}`}
                  >
                    <Link to="/documents" className="menu-link">
                      <div data-i18n="Without navbar">Documentos</div>
                    </Link>
                  </li>
                </ul>
              </li> */}

              <li id="OPERATIONS"
                onClick={() =>  { setMenu('OPERATIONS') } }
                className={`menu-item ${active === 'OPERATIONS' && " active open"} ${(selected === 'OPERATIONS') && "open"} `}
              >
                <Link href="/" className="menu-link menu-toggle ">
                  <i className="menu-icon bx bx-wallet" /> 
                  <div data-i18n="Layouts">Operaciones</div>
                </Link>
                <ul className="menu-sub">
                  {/* <li id="OPERATIONS-BONOS"
                    onClick={() => setSubmenu('OPERATIONS','OPERATIONS-BONOS')}
                    className={`menu-item ${subactive === 'OPERATIONS-BONOS' && "active"}`}
                  >
                    <Link to="/operationsBonos" className="menu-link ">
                      <div data-i18n="Without menu ">Bonos</div>
                    </Link>
                    
                  </li> */}
                  <li id="OPERATIONS-BONOS"
                    onClick={() => setSubmenu('OPERATIONS','OPERATIONS-DOLLAR')}
                    className={`menu-item ${subactive === 'OPERATIONS-DOLLAR' && "active"}`}
                  >
                    <Link to="/operations" className="menu-link ">
                      <div data-i18n="Without menu ">Operaciones</div>
                    </Link>
                  </li>
                </ul>
              </li>

              <li id="REPORTS"
                onClick={() =>  { setMenu('REPORTS') } }
                className={`menu-item ${active === 'REPORTS' && " active open"} ${(selected === 'REPORTS') && "open"} `}
              >
                <Link href="/" className="menu-link menu-toggle ">
                  <i className="menu-icon bx bxs-report" />
                  <div data-i18n="Layouts">Reportes</div>
                </Link>
                <ul className="menu-sub">
                  <li id="REPORTS-BONOS"
                    onClick={() => setSubmenu('REPORTS','REPORTS-BONOS')}
                    className={`menu-item ${subactive === 'REPORTS-BONOS' && "active"}`}
                  >
                    <Link to="/reportOperation" className="menu-link ">
                      <div data-i18n="Without menu ">Bonos</div>
                    </Link>
                  </li>
                  <li id="REPORTS-MIDDLE"
                    onClick={() => setSubmenu('REPORTS','REPORTS-MIDDLE')}
                    className={`menu-item ${subactive === 'REPORTS-MIDDLE' && "active"}`}
                  >
                    <Link to="/reportOperationMiddle" className="menu-link ">
                      <div data-i18n="Without menu ">Operaciones Mesa</div>
                    </Link>
                  </li>
                </ul>
              </li>

              {/* SETTINGS */}
              <li id="SETTINGS"
                onClick={() =>  { setMenu('SETTINGS') } }
                className={`menu-item ${active === 'SETTINGS' && " active open"} ${(selected === 'SETTINGS') && "open"} `}
              >
                <Link href="javascript:void(0);" className="menu-link menu-toggle ">
                  <i className="menu-icon tf-icons bx bx-cog" /> 
                  <div data-i18n="Layouts">Configuración</div>
                </Link>
                <ul className="menu-sub">
                  <li id="SETTINGS-ACCOUNTS"
                    onClick={() => setSubmenu('SETTINGS','SETTINGS-ACCOUNTS')}
                    className={`menu-item ${subactive === 'SETTINGS-ACCOUNTS' && "active"}`}
                  >
                    <Link to="/accounts" className="menu-link ">
                      <div data-i18n="Without menu ">Comitentes</div>
                    </Link>
                  </li>
                  <li id="SETTINGS-TICKERS"
                    onClick={() => setSubmenu('SETTINGS','SETTINGS-TICKERS')}
                    className={`menu-item ${subactive === 'SETTINGS-TICKERS' && "active"}`}
                  >
                    <Link to="/tickers" className="menu-link ">
                      <div data-i18n="Without menu ">Especies</div>
                    </Link>
                  </li>
                  <li id="SETTINGS-FCI"
                    onClick={() => setSubmenu('SETTINGS','SETTINGS-FCI')}
                    className={`menu-item ${subactive === 'SETTINGS-FCI' && "active"}`}
                  >
                    <Link to="/funds" className="menu-link ">
                      <div data-i18n="Without menu ">Fondos Comunes Inversión</div>
                    </Link>
                  </li>
                </ul>
              </li>

               {/* USERS */}
               <li id="USERS" 
                onClick={() => setSubmenu('USERS','USERS-USERS')}
                className={`menu-item ${active === 'USERS' && "active"}`}
              >
                <Link to="/users" className="menu-link">
                  <i className="menu-icon tf-icons bx bx-lock-open-alt" />
                  <div data-i18n="Authentications">Usuario</div>
                </Link>
              </li>
            
            </ul>
            
        </aside>
      }
      </span>
  
    );
  }
  
  export default Menu;
  