import api from "../apiapp";


const listService = {
    getTermList: async function () {
        const response = await api.request({
            url: 'settlement-terms/',
            method: "GET",
        })
        
        return response.data
    },
    getTypeInvestmentsList: async function () {
        const response = await api.request({
            url: 'type-of-investments/',
            method: "GET",
        })
        
        return response.data
    },
    getCurrenciesList: async function () {
        const response = await api.request({
            url: 'currencies/',
            method: "GET",
        })
        
        return response.data
    },
    getMarketList: async function () {
        const response = await api.request({
            url: 'markets/',
            method: "GET",
        })
        
        return response.data
    },
    getSegmentList: async function () {
        const response = await api.request({
            url: 'segments/',
            method: "GET",
        })
        
        return response.data
    },
    getTermList: async function () {
        const response = await api.request({
            url: 'settlement-terms/',
            method: "GET",
        })
        
        return response.data
    },
    getCurrenciesList: async function () {
        const response = await api.request({
            url: 'currencies/',
            method: "GET",
        })
        
        return response.data
    },
    getOperationTypesList: async function (code) {
        const response = await api.request({
            url: 'operation-types/get-by-security-type/'+code,
            method: "GET",
        })
        
        return response.data
    },
    getPriceTypesList: async function () {
        const response = await api.request({
            url: 'price-types/',
            method: "GET",
        })
        
        return response.data
    },
    getUsersList: async function () {
        const response = await api.request({
            url: 'users/',
            method: "GET",
        })
        
        return response.data
    },
    getStatusList: async function () {
        const response = await api.request({
            url: 'operation-states/',
            method: "GET",
        })
        
        return response.data
    },
 
}
  
export default listService;
