import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom"
import Swal from "sweetalert2";
import axios from 'axios'
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Grid from '@mui/joy/Grid';
import Input from '@mui/joy/Input';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Autocomplete from '@mui/joy/Autocomplete';
import Textarea from '@mui/joy/Textarea';
import Divider from '@mui/joy/Divider';
import Table from '@mui/joy/Table';
import Typography from '@mui/joy/Typography';
// import moment from 'moment';
import { NumericFormat } from 'react-number-format';
import Loading from "../../components/loading";
import { toast } from 'react-toastify';
//SERVICES  
import AccountService from "../../services/accountService";
import SecuritieService from "../../services/securitieService";
import ListService from "../../services/listService";
import OperationService from "../../services/operationService";

function OperationsBonos() {

    const initPreview = {
        comitente: '',
        operationType: '',
        term: '',
        market: 1,
        segment: "",
        ticker: '',
        quantity: '',
        quantityDisable: false,
        pricetype:'',
        currency: '',
        monto: '',
        montoDisable: false,
        observations: '',
        limit_price: '',
        accountManager: '',
    }
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const initialized = useRef(false)
    const [listAccounts, setListAccounts] = useState([])
    const [listEspecies, setListEspecies] = useState([])
    const [listMarkets, setListMarkets] = useState([])
    const [listSegmentsOriginal, setListSegmentsOriginal] = useState([])
    const [listSegments, setListSegments] = useState([])
    const [listPriceTypes, setListPriceTypes] = useState([])
    const [listOperationTypes, setListOperationTypes] = useState([])
    const [listTerms, setListTerms] = useState([])
    const [previewModel, setPreviewModel] = useState(initPreview)
  
    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true
            doLoad()
        }
    }, [false]);

    const doLoad = async (event) => {
        try {  
            // Comitentes
            const responseAccount = await AccountService.getAccountAutocomplete()
            setListAccounts(responseAccount.data)
            // Especies
            const responseSecurities = await SecuritieService.getSecuritiesAutocomplete()
            setListEspecies(responseSecurities.data)
            // Markets
            const responseMarkets = await ListService.getMarketList()
            setListMarkets(responseMarkets.data)
            // Price Types
            const responsePriceType = await ListService.getPriceTypesList()
            setListPriceTypes(responsePriceType.data)
            // Segments
            const responseSegments = await ListService.getSegmentList()
            const temSegments = []
            responseSegments.data.forEach( function(item, key) {
                if(item.id != 3){ temSegments.push({id: item.id , name: item.name}) }
            });
            setListSegmentsOriginal(temSegments)
            setListSegments(temSegments)
            // Terms
            const responseTerms = await ListService.getTermList()
            const tempTerms = []
            responseTerms.data.forEach( function(item, key) {
                if(item.name === "T0" || item.name === "T1"){
                    tempTerms.push({id: item.id, name: item.name});
                }
            });
            setListTerms(tempTerms)
            // Operation Types
            const responseOpeType = await ListService.getOperationTypesList(1)
            setListOperationTypes(responseOpeType.data)
        } catch (e) {
            if (axios.isAxiosError(e)) {
                const statusCode = e.response?.status
                const error = e.response?.data.errors;
                if(statusCode && statusCode !== 401){
                    Swal.fire({
                        title: 'ERROR',
                        text: error[0].detail,
                        icon: 'error',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#f13939'
                    })
                }else{
                    localStorage.removeItem("userProfile");
                    navigate('/')
                    window.location.reload();
                }
            }
        } finally{ setLoading(false) }
    }

    const selectComitente = async (obj) => {
        if(obj !== null){
            setPreviewModel({
                ...previewModel,
                comitente: obj,
                accountManager: obj.portfolio_manager,
            })
       }else{
            setPreviewModel({
                ...previewModel,
                comitente: "",
                accountManager: "",
            })
       }   
    }
     
    const selectEspecie = async (obj) => {
        if(obj !== null){
            setPreviewModel({
                ...previewModel,
                ticker: obj,
                currency: {id: obj.codMoneda, name:obj.monedaDescripcion}
            })
       }else{
            setPreviewModel({
                ...previewModel,
                ticker: "",
                currency: "",
            })
       }
    } 

    const trgChangeMarket = async (obj) => {
        if(obj.id === 2){
            setListSegments([{id:1 , name: 'PPT'}]);
            setPreviewModel({
                ...previewModel,
                market: {id:obj.id, name:obj.name},
                segment: {id:1 , name: 'PPT'},
            })
        }else if(obj.id === 5 ){
            setListSegments([{id:3 , name: 'Sin Segemento'}]);
            setPreviewModel({
                ...previewModel,
                market: {id:obj.id, name:obj.name},
                segment: {id:3 , name: 'Sin Segemento'},
            })
        }else{
            setPreviewModel({
                ...previewModel,
                market: {id:obj.id, name:obj.name},
                segment: "",
            })
            setListSegments(listSegmentsOriginal);
        }
    }

    const trgChangeSegment = async (obj) => {
        if(obj !== null){
            setPreviewModel({
                ...previewModel,
                segment: {id:obj.id, name:obj.name},
            })
        }else{
            setPreviewModel({
                ...previewModel,
                segment: "",
            })
        }
    };

    const trgChangeTerm = async (obj) => {
        if(obj !== null){
            setPreviewModel({
                ...previewModel,
                term: obj,
            })
        }else{
            setPreviewModel({
                ...previewModel,
                term: "",
            })
        }
    };
   
    const trgChangePriceType = async (obj) => {
        if(obj !== null){
            setPreviewModel({
                ...previewModel,
                pricetype: {id: obj.id, name: obj.name},
                limit_price: "",
            })
        }else{
            setPreviewModel({
                ...previewModel,
                pricetype: "",
                limit_price: "",
            })
        }
    };

    const trgChangeQuantity = async (obj) => {
        if(obj !== null){
            if(obj > 0){
                setPreviewModel({
                    ...previewModel, 
                    quantity: obj,
                    monto: '',
                    montoDisable: true
                })
            }else{
                setPreviewModel({
                    ...previewModel, 
                    quantity: obj,
                    monto: '',
                    montoDisable: false
                })
            }
        }
    };

    const trgChangeMonto = async (obj) => {
        if(obj !== null){
            if(obj > 0){
                setPreviewModel({
                    ...previewModel, 
                    monto: obj,
                    quantity: '',
                    quantityDisable: true
                })
            }else{
                setPreviewModel({
                    ...previewModel, 
                    monto: obj,
                    quantity: '',
                    quantityDisable: false
                })
            }
        }
    };

    const validateForm = function(){
        let rta = true;
        if(previewModel.comitente === '' || previewModel.comitente === undefined  || previewModel.comitente === null){ return false; } 
        if(previewModel.ticker  === '' || previewModel.ticker === undefined  || previewModel.ticker === null){ return false; } 
        if(previewModel.operationType  === '' || previewModel.operationType === undefined  || previewModel.operationType === null){ return false; } 
        if(previewModel.market === '' || previewModel.market === undefined  || previewModel.market === null){ return false; } 
        if(previewModel.segment === '' || previewModel.segment === undefined  || previewModel.segment === null){ return false; } 
        if(previewModel.term === '' || previewModel.term === undefined  || previewModel.term === null){ return false; } 
        if(previewModel.pricetype === '' || previewModel.pricetype === undefined  || previewModel.pricetype === null){ return false; } 
        if(previewModel.quantity === '' || previewModel.quantity === undefined  || previewModel.quantity === null){ 
            if(previewModel.monto === '' || previewModel.monto === undefined  || previewModel.monto === null){ return false; } 
        } 
        if(previewModel.monto === '' || previewModel.monto === undefined  || previewModel.monto === null){ 
            if(previewModel.quantity === '' || previewModel.quantity === undefined  || previewModel.quantity === null){ return false; } 
        } 
        if(previewModel.pricetype.id === 1){
            if(previewModel.limit_price === '' || previewModel.limit_price === undefined  || previewModel.limit_price === null){ return false; } 
        }
        
        return rta;
    }

    const doOperation = function(){
        try {  
            setLoading(true)
            if(validateForm()){
                Swal.fire({
                    title: "Atención!",
                    text: "Ud. esta seguro que quiere crear la operación ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Crear",
                    confirmButtonColor: "#83766d"
                }).then((result) => {
                    if (result.isConfirmed) {
                        
                        let model = {
                            settlement_term_id: previewModel.term.id,
                            account: previewModel.comitente.account,
                            operation_type_id: previewModel.operationType.id,
                            currency_id: previewModel.currency.id,
                            market_id: previewModel.market.id,
                            segment_id: previewModel.segment.id,
                            price_type_id: previewModel.pricetype.id,
                            limit_price: previewModel.limit_price,
                            ticker: previewModel.ticker.instrumentoAbrev,
                            price: "",
                            qty: previewModel.quantity ,
                            total: previewModel.monto,
                            observations: previewModel.observations
                        }

                        OperationService.setOperations(model)
                            .then((response) => {
                                toast.success('Operación creada!', {
                                    position: "top-right",
                                    autoClose: 3000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                })
                                // setPreviewModel(initPreview)
                                window.location.reload();
                            })
                            .catch((error) => {
                                if(error.response.status !== 401){
                                    Swal.fire({
                                        title: 'Ooops?',
                                        text: error.response.data.errors[0].detail,
                                        icon: 'error',
                                        confirmButtonText: 'OK',
                                        confirmButtonColor: '#f13939'
                                    })
                                }else{
                                    localStorage.removeItem("userProfile");
                                    navigate('/')
                                    window.location.reload();
                                }
                            })
                    } 
                });
            }else{
                Swal.fire({
                    title: 'Ooops?',
                    text: 'Debe completar los datos correctamente!',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#f13939'
                })
            }
            
        } catch (e) {
            if (axios.isAxiosError(e)) {
                const statusCode = e.response?.status
                const error = e.response?.data.errors;
                if(statusCode && statusCode !== 401){
                    Swal.fire({
                        title: 'ERROR',
                        text: error[0].detail,
                        icon: 'error',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#f13939'
                    })
                }else{
                    localStorage.removeItem("userProfile");
                    navigate('/')
                    window.location.reload();
                }
            }
        } finally{ setLoading(false) }
    }

    const doDraft = function(){
        try {  
            setLoading(true)
            Swal.fire({
                title: "Atención!",
                text: "Ud. esta seguro que quiere guardar la operación como BORRADOR?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Crear",
                confirmButtonColor: "#83766d"
            }).then((result) => {
                if (result.isConfirmed) {
                    
                    let model = {
                        settlement_term_id: previewModel.term.id,
                        account: previewModel.comitente.account,
                        operation_type_id: previewModel.operationType.id,
                        currency_id: previewModel.currency.id,
                        market_id: previewModel.market.id,
                        segment_id: previewModel.segment.id,
                        price_type_id: previewModel.pricetype.id,
                        limit_price: previewModel.limit_price,
                        ticker: previewModel.ticker.instrumentoAbrev,
                        price: "",
                        qty: previewModel.quantity ,
                        total: previewModel.monto,
                        observations: previewModel.observations
                    }

                    OperationService.setDraftOperations(model)
                        .then((response) => {
                            toast.success('Operación creada en Borrador!', {
                                position: "top-right",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            })
                            // setPreviewModel(initPreview)
                            window.location.reload();
                        })
                        .catch((error) => {
                            if(error.response.status !== 401){
                                Swal.fire({
                                    title: 'Ooops?',
                                    text: error.response.data.errors[0].detail,
                                    icon: 'error',
                                    confirmButtonText: 'OK',
                                    confirmButtonColor: '#f13939'
                                })
                            }else{
                                localStorage.removeItem("userProfile");
                                navigate('/')
                                window.location.reload();
                            }
                        })
                } 
            });
        } catch (err) {
            if(err.response.status !== 401){
                Swal.fire({
                    title: 'Ooops?',
                    text: err.response.data.errors[0].detail,
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#f13939'
                })
            }else{
                localStorage.removeItem("userProfile");
                navigate('/')
                window.location.reload();
            }
        } finally{ setLoading(false) }
    }

/*     const doClean = function(){

        setPreviewModel(initPreview)
        console.log(previewModel)
    } */
    
    
    if(loading){
        return(
            <Loading></Loading>
        )
    }

    return (
        <div className="row">
            <div className="col-sm-8">
                <div className="card">
                    <div className="card-body" style={{paddingBottom:0}}>
                        <h6 className="card-subtitle text-muted">Operaciones con Bonos</h6>
                    </div>
                    
                    <Grid className="card-body" container spacing={2} sx={{ flexGrow: 1 }}>        
                        <Grid xs={6}>
                            <FormControl>
                                <FormLabel className="form-labels">Comitente <span style={{color:'red'}}>*</span></FormLabel>
                                <Autocomplete 
                                    autoFocus
                                    options={listAccounts} 
                                    getOptionLabel={option => option.name +' - '+ option.account }
                                    slotProps={{
                                        listbox: {
                                            sx: (theme) => ({
                                            zIndex: theme.vars.zIndex.modal
                                            })
                                        }
                                    }}
                                    placeholder="Seleccione un Comitente" 
                                    onChange={(event, newValue) => { selectComitente(newValue); }}
                                />  
                            </FormControl>
                        </Grid>
                        <Grid xs={6}>
                            <FormControl>
                                <FormLabel className="form-labels">Oficial de Cuenta <span style={{color:'red'}}>*</span></FormLabel>
                                <Input type="text" size="md" placeholder="" disabled={true} value={previewModel.accountManager}/>
                            </FormControl>
                        </Grid>
                        <Grid xs={4}>
                            <FormControl>
                                <FormLabel className="form-labels">Mercado <span style={{color:'red'}}>*</span></FormLabel>
                                <Select 
                                        // placeholder="BYMA" 
                                    // defaultValue={previewModel.market.name}
                                    // value={null}
                                    onChange={(event, newValue) => { trgChangeMarket(newValue); }}
                                >
                                    {listMarkets.filter(st => st.active).map( (market) => 
                                        <Option value={market} key={market.id}>{market.name}</Option>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid xs={4}>
                            <FormControl>
                                <FormLabel className="form-labels">Segmento <span style={{color:'red'}}>*</span></FormLabel>
                                <Select 
                                        // placeholder="Seleccione un Segmento" 
                                        defaultValue={previewModel.segment}
                                        onChange={(event, newValue) => { trgChangeSegment(newValue); }}
                                        
                                >
                                    {listSegments.map( (segment) => 
                                        <Option value={segment} key={segment.id}>{segment.name}</Option>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid xs={4}>
                            <FormControl>
                                <FormLabel className="form-labels">Tipo Precio <span style={{color:'red'}}>*</span></FormLabel>
                                <Select 
                                    // placeholder="Seleccione un Tipo de Precio" 
                                    defaultValue={previewModel.pricetype}
                                    onChange={(event, newValue) => { trgChangePriceType(newValue); }}
                                >
                                    {listPriceTypes.filter(st => st.active).map( (priceType) => 
                                        <Option value={priceType} key={priceType.id}>{priceType.name}</Option>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        {/* PRECIO LIMITADO */}
                        { previewModel.pricetype.id === 1 ?
                            <Grid xs={4}>
                                <FormControl>
                                    <FormLabel className="form-labels">Precio Limite <span style={{color:'red'}}>*</span></FormLabel>
                                    <Input type="number" size="md" placeholder="" 
                                        slotProps={{
                                            input: {
                                            min: 0,
                                            },
                                        }} 
                                        onChange={(event) => setPreviewModel({...previewModel, limit_price: event.target.value})}
                                    />
                                </FormControl>
                            </Grid>
                        : null
                        }
                        
                        <Grid xs={4}>
                            <FormControl>
                                <FormLabel className="form-labels">Tipo de Operación <span style={{color:'red'}}>*</span></FormLabel>
                                <Select 
                                        // placeholder="Seleccione un Tipo de Operación" 
                                    //  defaultValue={previewModel.operationType}
                                    onChange={(event, newValue) => { setPreviewModel({...previewModel, operationType: newValue}) }}
                                >
                                    {listOperationTypes.map( (obj) => 
                                        <Option value={obj} key={obj.id}>{obj.name}</Option>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid xs={4}>
                            <FormControl>
                                <FormLabel className="form-labels">Plazo de liquidación <span style={{color:'red'}}>*</span></FormLabel>
                                <Select 
                                        placeholder="" 
                                        onChange={(event, newValue) => { trgChangeTerm(newValue); }}
                                >
                                    {listTerms.map( (term) => 
                                        <Option value={term} key={term.id} label={term.name}>{term.name}</Option>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid xs={6}>
                            <FormControl>
                                <FormLabel className="form-labels">Especie <span style={{color:'red'}}>*</span></FormLabel>
                                <Autocomplete
                                    options={listEspecies} 
                                    getOptionLabel={option => option.instrumentoAbrev +'('+ option.monedaDescripcion+')' }
                                    slotProps={{
                                        listbox: {
                                            sx: (theme) => ({
                                            zIndex: theme.vars.zIndex.modal
                                            })
                                        }
                                    }}
                                    placeholder="Seleccione una Especie" 
                                    onChange={(event, newValue) => { selectEspecie(newValue); }}
                                />  
                            </FormControl>
                        </Grid>
                        <Grid xs={6}>
                            <FormControl>
                                <FormLabel className="form-labels">Moneda <span style={{color:'red'}}>*</span></FormLabel>
                                <Input size="md" placeholder="" disabled={true} value={previewModel.currency.name}/>
                            </FormControl>
                        </Grid>
                        <Grid xs={6}>
                            <FormControl>
                                <FormLabel className="form-labels">Valor Nominal <span style={{color:'red'}}>*</span></FormLabel>
                                <Input type="number" size="md" placeholder="" value={previewModel.quantity}
                                    slotProps={{
                                        input: {
                                        min: 0,
                                        },
                                    }} 
                                    onChange={(event) => trgChangeQuantity(event.target.value)}
                                    disabled={previewModel.quantityDisable}
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={6}>
                            <FormControl>
                                <FormLabel className="form-labels">Monto <span style={{color:'red'}}>*</span></FormLabel>
                                <Input type="number" size="md" placeholder="" value={previewModel.monto}
                                    slotProps={{
                                        input: {
                                        min: 0,
                                        },
                                    }} 
                                    onChange={(event) => trgChangeMonto(event.target.value)}
                                    disabled={previewModel.montoDisable}
                                />
                            </FormControl>
                        </Grid>

                        <Grid xs={12}>
                            <FormControl>
                                <FormLabel className="form-labels">Observciones</FormLabel>
                                <Textarea
                                    onChange={(event) => { setPreviewModel({...previewModel, observations: event.target.value}) }}
                                    value={previewModel.observations}
                                    placeholder="Escriba aqui..."
                                    minRows={4}
                                    maxRows={4}
                                />
                            </FormControl>
                        </Grid>

                        <Typography level="body-xs" style={{color:'red'}}>* obligatorios</Typography>
                        
                    </Grid>
                    
                    
                </div>
            </div>
            <div className="col-sm-4">
                <div className="card">
                    <div className="card-body" style={{paddingBottom:0}}>
                        <h6 style={{color:'red'}} className="card-subtitle text-muted">Vista Previa</h6>
                    </div>
                    <Grid className="card-body" container spacing={2} sx={{ flexGrow: 1 }}>
                        <Grid xs={12}>
                            <FormLabel className="form-labels">Comitente</FormLabel>  
                            <Typography level="h2" sx={{ fontSize: 'xl', mb: 0.5 }}>{previewModel.comitente.account} - {previewModel.comitente.name}</Typography>
                            {/* <p><strong>{previewModel.codComitente} - {previewModel.comitenteDesc}</strong></p> */}
                            <Divider />
                        </Grid>

                        <Table>
                            <tbody>
                                <tr key="accountmanager">
                                    <td><span className="form-labels">Oficial de Cuenta</span>  </td>
                                    <td><Typography level="title-sm">{previewModel.accountManager}</Typography></td>
                                </tr>
                                <tr key="mercados">
                                    <td><span className="form-labels">Mercado</span>  </td>
                                    <td><Typography level="title-sm">{previewModel.market.name}</Typography></td>
                                </tr>
                                <tr key="segmento">
                                    <td><span className="form-labels">Segmento</span>  </td>
                                    <td><Typography level="title-sm">{previewModel.segment.name}</Typography></td>
                                </tr>
                                <tr key="tprecio">
                                    <td><span className="form-labels">Tipo de Precio</span>  </td>
                                    <td><Typography level="title-sm">{previewModel.pricetype.name}</Typography></td>
                                </tr>
                                { previewModel.pricetype.id === 1 ?
                                    <tr key="tpreciolimite">
                                        <td><span className="form-labels">Precio Limite</span>  </td>
                                        <td>
                                            <Typography level="title-sm">
                                                <NumericFormat 
                                                    displayType="text" 
                                                    value={previewModel.limit_price} 
                                                    allowLeadingZeros thousandSeparator="." 
                                                    decimalSeparator=","
                                                    prefix={'$'}  
                                                /> 
                                            </Typography>
                                        </td>
                                    </tr>
                                : null}
                                <tr key="toperacion">
                                    <td><span className="form-labels">Tipo de Operación</span>  </td>
                                    <td><Typography level="title-sm">{previewModel.operationType.name}</Typography></td>
                                </tr>
                                <tr key="plazo">
                                    <td><span className="form-labels">Plazo de liquidación</span>  </td>
                                    <td><Typography level="title-sm">{previewModel.term.name}</Typography></td>
                                </tr>
                                <tr key="ticker">
                                    <td><span className="form-labels">Especie</span> </td>
                                    <td>
                                        <Typography level="title-sm">{previewModel.ticker.instrumentoAbrev}</Typography>
                                        <Typography level="body-xs">{previewModel.ticker.instrumentoDesc}</Typography>
                                    </td>
                                </tr>
                                <tr key="currency">
                                    <td><span className="form-labels">Moneda</span>  </td>
                                    <td><Typography level="title-sm">{previewModel.currency.name}</Typography></td>
                                </tr>
                                <tr key="valor">
                                    <td><span className="form-labels">Valor Nominal</span>  </td>
                                    <td>
                                        <Typography level="title-sm">
                                            <NumericFormat 
                                                displayType="text" 
                                                value={previewModel.quantity} 
                                                allowLeadingZeros thousandSeparator="." 
                                                decimalSeparator="," 
                                            /> 
                                        </Typography>
                                    </td>
                                </tr>
                                <tr key="monto">
                                    <td><span className="form-labels">Monto</span>  </td>
                                    <td>
                                        <Typography level="title-sm">
                                            <NumericFormat 
                                                displayType="text" 
                                                value={previewModel.monto} 
                                                allowLeadingZeros thousandSeparator="." 
                                                decimalSeparator=","
                                                prefix={'$'}  
                                            /> 
                                        </Typography>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                        <Grid xs={6} >
                            <button type="button" className="btn btn-outline-dark" onClick={doDraft}>GUARDAR BORRADOR</button>
                        </Grid>
                        <Grid xs={6}>
                            <button type="button" className="btn btn-dark" onClick={doOperation}>CREAR OPERACION</button>
                        </Grid>
                        {/* <Grid xs={6}>
                            <button type="button" className="btn btn-dark" onClick={doClean}>CLEAN</button>
                        </Grid> */}
                        
                        
                    </Grid>
                </div>
            </div>
            
        </div>
     );

}

export default OperationsBonos;