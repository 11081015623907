import React, {useState, useEffect} from "react";
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import DialogActions from '@mui/joy/DialogActions';
import Divider from '@mui/joy/Divider';
import Grid from '@mui/joy/Grid';
import Input from '@mui/joy/Input';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Textarea from '@mui/joy/Textarea';
import Checkbox from '@mui/joy/Checkbox';
import { NumericFormat } from 'react-number-format';


function ModalViewBonos( {handleOpen, isOpen, selected, fundId}) {
    
    return (
        <Modal
            aria-labelledby="close-modal-title"
            open={isOpen}
            onClose={() => handleOpen(false) }
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <ModalDialog>
                <ModalClose variant="outlined" />
                <DialogTitle>  Operación: #{fundId}</DialogTitle>
                <Divider />
                <DialogContent>
                    <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                        <Grid xs={4}>
                            <FormControl>
                                <FormLabel className="form-labels">Nro. Comitente</FormLabel>
                                {selected.account !== null ?
                                    <Input size="md" placeholder="" disabled={true} value={selected.account.account} />
                                : 
                                    <Input size="md" placeholder="" disabled={true} />
                                }
                            </FormControl>
                        </Grid>
                        <Grid xs={8}>
                            <FormControl>
                                <FormLabel className="form-labels">Comitente</FormLabel>
                                {selected.account !== null ?
                                    <Input size="md" placeholder="" disabled={true} value={selected.account.name} />
                                : 
                                    <Input size="md" placeholder="" disabled={true} />
                                }
                            </FormControl>
                        </Grid>
                        <Grid xs={4}>
                            <FormControl>
                                <FormLabel className="form-labels">Mercado</FormLabel>
                                {selected.market !== null ?
                                    <Input size="md" placeholder="" disabled={true} value={selected.market.name} />
                                : 
                                    <Input size="md" placeholder="" disabled={true} />
                                }
                            </FormControl>
                        </Grid>
                        <Grid xs={4}>
                            <FormControl>
                                <FormLabel className="form-labels">Segmento</FormLabel>
                                {selected.segment !== null ?
                                    <Input size="md" placeholder="" disabled={true} value={selected.segment.name} />
                                : 
                                    <Input size="md" placeholder="" disabled={true} />
                                }
                            </FormControl>
                        </Grid>
                        <Grid xs={4}>
                            <FormControl>
                                <FormLabel className="form-labels">Tipo Precio</FormLabel>
                                {selected.price_type !== null ?
                                    <Input size="md" placeholder="" disabled={true} value={selected.price_type.name} />
                                : 
                                    <Input size="md" placeholder="" disabled={true} />
                                }
                            </FormControl>
                        </Grid>
                        <Grid xs={4}>
                            <FormControl>
                                <FormLabel className="form-labels">Tipo de Operación</FormLabel>
                                {selected.operation_type !== null ?
                                    <Input size="md" placeholder="" disabled={true} value={selected.operation_type.name} />
                                : 
                                    <Input size="md" placeholder="" disabled={true} />
                                }
                            </FormControl>
                        </Grid>
                        <Grid xs={4}>
                            <FormControl>
                                <FormLabel className="form-labels">Plazo de liquidación</FormLabel>
                                {selected.settlement_term !== null ?
                                    <Input size="md" placeholder="" disabled={true} value={selected.settlement_term.name} />
                                : 
                                    <Input size="md" placeholder="" disabled={true} />
                                }
                            </FormControl>
                        </Grid>
                        <Grid xs={4}>
                            <FormControl>
                                <FormLabel className="form-labels">Especie</FormLabel>
                                {selected.ticker !== null ?
                                    <Input size="md" placeholder="" disabled={true} value={selected.ticker} />
                                : 
                                    <Input size="md" placeholder="" disabled={true} />
                                }
                            </FormControl>
                        </Grid>
                        <Grid xs={4}>
                            <FormControl>
                                <FormLabel className="form-labels">Moneda</FormLabel>
                                {selected.currency !== null ?
                                    <Input size="md" placeholder="" disabled={true} value={selected.currency.name} />
                                : 
                                    <Input size="md" placeholder="" disabled={true} />
                                }
                            </FormControl>
                        </Grid>
                        <Grid xs={4}>
                            <FormControl>
                                <FormLabel className="form-labels">Valor Nominal</FormLabel>
                                <NumericFormat 
                                    displayType="input" 
                                    className="form-control" 
                                    value={selected.qty} 
                                    // decimalScale={selected.included.fund.decimals} 
                                    decimalScale={0} 
                                    allowLeadingZeros thousandSeparator="." 
                                    decimalSeparator="," 
                                    disabled="true"
                                /> 
                            </FormControl>
                        </Grid>
                        <Grid xs={4}>
                            <FormControl>
                                <FormLabel className="form-labels">Monto</FormLabel>
                                <NumericFormat 
                                    displayType="input" 
                                    className="form-control" 
                                    value={selected.price} 
                                    decimalScale={4}
                                    allowLeadingZeros thousandSeparator="." 
                                    decimalSeparator="," 
                                    disabled="true"
                                /> 
                            </FormControl>
                        </Grid>
                        <Grid xs={4}>
                            <FormControl>
                                <FormLabel className="form-labels">Tomada por</FormLabel>
                                {selected.currency !== null ?
                                    <Input size="md" placeholder="" disabled={true} value={selected.user.full_name} />
                                : 
                                    <Input size="md" placeholder="" disabled={true} />
                                }
                            </FormControl>
                            {/* <div>
                                <label className="form-label">Tomada por</label>
                                <input type="text" className="form-control" value={selected.user.full_name} disabled="true"/>
                            </div> */}
                        </Grid>
                        <Grid xs={12}>
                            <FormControl>
                                <FormLabel className="form-labels">Observciones</FormLabel>
                                <Textarea
                                    disabled={true}
                                    value={selected.observations}
                                    minRows={4}
                                    maxRows={4}
                                />
                            </FormControl>
                        </Grid>
                        
                    </Grid> 
                </DialogContent>
                <Divider />
                <DialogActions>
                    <button type="button" className="btn btn-outline-secondary"  onClick={() => handleOpen(false)}> Cerrar </button>                    
                </DialogActions>
            </ModalDialog>
        </Modal>

    );
}

export default ModalViewBonos;