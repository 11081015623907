import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom"
import Swal from "sweetalert2";
import axios from 'axios'
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Grid from '@mui/joy/Grid';
import Input from '@mui/joy/Input';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Autocomplete from '@mui/joy/Autocomplete';
import Textarea from '@mui/joy/Textarea';
import Divider from '@mui/joy/Divider';
import Table from '@mui/joy/Table';
import Typography from '@mui/joy/Typography';
// import moment from 'moment';
import { NumericFormat } from 'react-number-format';
import Loading from "../../components/loading";
import { toast } from 'react-toastify';
//SERVICES  
import AccountService from "../../services/accountService";
import SecuritieService from "../../services/securitieService";
import ListService from "../../services/listService";
// import OperationService from "../../services/operationService";
import MiddleOperationService from "../../services/middleOperationService";

function MesaOperationsLoan() {

    const initPreview = {
        comitente: '',
        operationType: '',
        ticker: '',
        quantity: '',
        currency: '',
        price: '',
        accountManager: '',
    }
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const initialized = useRef(false)
    const [listAccounts, setListAccounts] = useState([])
    const [listEspecies, setListEspecies] = useState([])
    const [listOperationTypes, setListOperationTypes] = useState([])
    const [previewModel, setPreviewModel] = useState(initPreview)
  
    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true
            doLoad()
        }
    }, [false]);

    const doLoad = async (event) => {
        try {  
            // Comitentes
            const responseAccount = await AccountService.getAccountAutocomplete()
            setListAccounts(responseAccount.data)
            // Especies
            const responseSecurities = await SecuritieService.getSecuritiesAutocomplete()
            setListEspecies(responseSecurities.data)
            // Operation Types
            const responseOpeType = await ListService.getOperationTypesList(1)
            setListOperationTypes(responseOpeType.data)
        } catch (e) {
            if (axios.isAxiosError(e)) {
                const statusCode = e.response?.status
                const error = e.response?.data.errors;
                if(statusCode && statusCode !== 401){
                    Swal.fire({
                        title: 'ERROR',
                        text: error[0].detail,
                        icon: 'error',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#f13939'
                    })
                }else{
                    localStorage.removeItem("userProfile");
                    navigate('/')
                    window.location.reload();
                }
            }
        } finally{ setLoading(false) }
    }

    const selectComitente = async (obj) => {
        if(obj !== null){
            setPreviewModel({
                ...previewModel,
                comitente: obj,
                accountManager: obj.portfolio_manager,
            })
       }else{
            setPreviewModel({
                ...previewModel,
                comitente: "",
                accountManager: "",
            })
       }   
    }
     
    const selectEspecie = async (obj) => {
        if(obj !== null){
            setPreviewModel({
                ...previewModel,
                ticker: obj,
                currency: {id: obj.codMoneda, name:obj.monedaDescripcion}
            })
       }else{
            setPreviewModel({
                ...previewModel,
                ticker: "",
                currency: "",
            })
       }
    } 

    const trgChangeQuantity = async (obj) => {
        if(obj !== null){
            if(obj > 0){
                setPreviewModel({
                    ...previewModel, 
                    quantity: obj,
                })
            }else{
                setPreviewModel({
                    ...previewModel, 
                    quantity: obj,
                })
            }
        }
    };

    const trgChangeMonto = async (obj) => {
        if(obj !== null){
            if(obj > 0){
                setPreviewModel({
                    ...previewModel, 
                    price: obj,
                })
            }else{
                setPreviewModel({
                    ...previewModel, 
                    price: obj,
                })
            }
        }
    };

    const validateForm = function(){
        let rta = true;
        if(previewModel.comitente === '' || previewModel.comitente === undefined  || previewModel.comitente === null){ return false; } 
        if(previewModel.ticker  === '' || previewModel.ticker === undefined  || previewModel.ticker === null){ return false; } 
        if(previewModel.operationType  === '' || previewModel.operationType === undefined  || previewModel.operationType === null){ return false; } 
        if(previewModel.quantity === '' || previewModel.quantity === undefined  || previewModel.quantity === null){ return false; } 
        if(previewModel.price === '' || previewModel.price === undefined  || previewModel.price === null){ return false; } 
    
        return rta;
    }

    const doOperation = function(){
        try {  
            setLoading(true)
            if(validateForm()){
                Swal.fire({
                    title: "Atención!",
                    text: "Ud. esta seguro que quiere crear la operación ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Crear",
                    confirmButtonColor: "#83766d"
                }).then((result) => {
                    if (result.isConfirmed) {
                        
                        let model = {
                            middle_operation_type_id: 1,
                            account: previewModel.comitente.account,
                            operation_type_id: previewModel.operationType.id,
                            currency_id: previewModel.currency.id,
                            ticker: previewModel.ticker.instrumentoAbrev,
                            price: previewModel.price,
                            qty: previewModel.quantity ,
                        }

                        MiddleOperationService.setMiddleOperations(model)
                            .then((response) => {
                                toast.success('Operación creada!', {
                                    position: "top-right",
                                    autoClose: 3000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                })
                                setPreviewModel(initPreview)
                                // window.location.reload();
                            })
                            .catch((error) => {
                                if(error.response.status !== 401){
                                    Swal.fire({
                                        title: 'Ooops?',
                                        text: error.response.data.errors[0].detail,
                                        icon: 'error',
                                        confirmButtonText: 'OK',
                                        confirmButtonColor: '#f13939'
                                    })
                                }else{
                                    localStorage.removeItem("userProfile");
                                    navigate('/')
                                    window.location.reload();
                                }
                            })
                    } 
                });
            }else{
                Swal.fire({
                    title: 'Ooops?',
                    text: 'Debe completar los datos correctamente!',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#f13939'
                })
            }
            
        } catch (e) {
            if (axios.isAxiosError(e)) {
                const statusCode = e.response?.status
                const error = e.response?.data.errors;
                if(statusCode && statusCode !== 401){
                    Swal.fire({
                        title: 'ERROR',
                        text: error[0].detail,
                        icon: 'error',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#f13939'
                    })
                }else{
                    localStorage.removeItem("userProfile");
                    navigate('/')
                    window.location.reload();
                }
            }
        } finally{ setLoading(false) }
    }

    
    if(loading){
        return(
            <Loading></Loading>
        )
    }

    return (
        <div className="row">
            <div className="col-sm-8">
                <div className="card">
                    <div className="card-body" style={{paddingBottom:0}}>
                        <h6 className="card-subtitle text-muted">Operaciones con descargas futuras.</h6>
                    </div>
                    
                    <Grid className="card-body" container spacing={2} sx={{ flexGrow: 1 }}>        
                        <Grid xs={6}>
                            <FormControl>
                                <FormLabel className="form-labels">Comitente <span style={{color:'red'}}>*</span></FormLabel>
                                <Autocomplete 
                                    autoFocus
                                    options={listAccounts} 
                                    getOptionLabel={option => option.name +' - '+ option.account }
                                    slotProps={{
                                        listbox: {
                                            sx: (theme) => ({
                                            zIndex: theme.vars.zIndex.modal
                                            })
                                        }
                                    }}
                                    placeholder="Seleccione un Comitente" 
                                    onChange={(event, newValue) => { selectComitente(newValue); }}
                                />  
                            </FormControl>
                        </Grid>
                      {/*   <Grid xs={6}>
                            <FormControl>
                                <FormLabel className="form-labels">Oficial de Cuenta <span style={{color:'red'}}>*</span></FormLabel>
                                <Input type="text" size="md" placeholder="" disabled={true} value={previewModel.accountManager}/>
                            </FormControl>
                        </Grid> */}
                        <Grid xs={6}>
                            <FormControl>
                                <FormLabel className="form-labels">Tipo de Operación <span style={{color:'red'}}>*</span></FormLabel>
                                <Select 
                                    onChange={(event, newValue) => { setPreviewModel({...previewModel, operationType: newValue}) }}
                                >
                                    {listOperationTypes.map( (obj) => 
                                        <Option value={obj} key={obj.id}>{obj.name}</Option>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid xs={6}>
                            <FormControl>
                                <FormLabel className="form-labels">Especie <span style={{color:'red'}}>*</span></FormLabel>
                                <Autocomplete
                                    options={listEspecies} 
                                    getOptionLabel={option => option.instrumentoAbrev +'('+ option.monedaDescripcion+')' }
                                    slotProps={{
                                        listbox: {
                                            sx: (theme) => ({
                                            zIndex: theme.vars.zIndex.modal
                                            })
                                        }
                                    }}
                                    placeholder="Seleccione una Especie" 
                                    onChange={(event, newValue) => { selectEspecie(newValue); }}
                                />  
                            </FormControl>
                        </Grid>
                        <Grid xs={6}>
                            <FormControl>
                                <FormLabel className="form-labels">Moneda <span style={{color:'red'}}>*</span></FormLabel>
                                <Input size="md" placeholder="" disabled={true} value={previewModel.currency.name}/>
                            </FormControl>
                        </Grid>
                        <Grid xs={6}>
                            <FormControl>
                                <FormLabel className="form-labels">Cantidad <span style={{color:'red'}}>*</span></FormLabel>
                                <Input type="number" size="md" placeholder="" value={previewModel.quantity}
                                    slotProps={{
                                        input: {
                                        min: 0,
                                        },
                                    }} 
                                    onChange={(event) => trgChangeQuantity(event.target.value)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={6}>
                            <FormControl>
                                <FormLabel className="form-labels">Precio <span style={{color:'red'}}>*</span></FormLabel>
                                <Input type="number" size="md" placeholder="" value={previewModel.price}
                                    slotProps={{
                                        input: {
                                        min: 0,
                                        },
                                    }} 
                                    onChange={(event) => trgChangeMonto(event.target.value)}
                                />
                            </FormControl>
                        </Grid>

                        <Grid xs={12}>
                            <Typography level="body-xs" style={{color:'red'}}>* obligatorios</Typography>
                        </Grid>

                    </Grid>
                    
                </div>
            </div>
            <div className="col-sm-4">
                <div className="card">
                    <div className="card-body" style={{paddingBottom:0}}>
                        <h6 style={{color:'red'}} className="card-subtitle text-muted">Vista Previa</h6>
                    </div>
                    <Grid className="card-body" container spacing={2} sx={{ flexGrow: 1 }}>
                        <Grid xs={12}>
                            <FormLabel className="form-labels">Comitente</FormLabel>  
                            <Typography level="h2" sx={{ fontSize: 'xl', mb: 0.5 }}>{previewModel.comitente.account} - {previewModel.comitente.name}</Typography>
                            {/* <p><strong>{previewModel.codComitente} - {previewModel.comitenteDesc}</strong></p> */}
                            <Divider />
                        </Grid>

                        <Table>
                            <tbody>
                                <tr key="accountmanager">
                                    <td><span className="form-labels">Oficial de Cuenta</span>  </td>
                                    <td><Typography level="title-sm">{previewModel.accountManager}</Typography></td>
                                </tr>
                                <tr key="toperacion">
                                    <td><span className="form-labels">Tipo de Operación</span>  </td>
                                    <td><Typography level="title-sm">{previewModel.operationType.name}</Typography></td>
                                </tr>
                                <tr key="ticker">
                                    <td><span className="form-labels">Especie</span> </td>
                                    <td>
                                        <Typography level="title-sm">{previewModel.ticker.instrumentoAbrev}</Typography>
                                        <Typography level="body-xs">{previewModel.ticker.instrumentoDesc}</Typography>
                                    </td>
                                </tr>
                                <tr key="currency">
                                    <td><span className="form-labels">Moneda</span>  </td>
                                    <td><Typography level="title-sm">{previewModel.currency.name}</Typography></td>
                                </tr>
                                <tr key="valor">
                                    <td><span className="form-labels">Cantidad</span>  </td>
                                    <td>
                                        <Typography level="title-sm">
                                            <NumericFormat 
                                                displayType="text" 
                                                value={previewModel.quantity} 
                                                allowLeadingZeros thousandSeparator="." 
                                                decimalSeparator="," 
                                            /> 
                                        </Typography>
                                    </td>
                                </tr>
                                <tr key="monto">
                                    <td><span className="form-labels">Precio</span>  </td>
                                    <td>
                                        <Typography level="title-sm">
                                            <NumericFormat 
                                                displayType="text" 
                                                value={previewModel.price} 
                                                allowLeadingZeros thousandSeparator="." 
                                                decimalSeparator=","
                                                prefix={'$'}  
                                                decimalScale={4}
                                            /> 
                                        </Typography>
                                    </td>
                                </tr>
                                <tr key="total">
                                    <td><span className="form-labels">Total</span>  </td>
                                    <td>
                                        <Typography level="title-sm">
                                            <NumericFormat 
                                                displayType="text" 
                                                value={previewModel.price * previewModel.quantity} 
                                                allowLeadingZeros thousandSeparator="." 
                                                decimalSeparator=","
                                                prefix={'$'}  
                                                decimalScale={4}
                                            /> 
                                        </Typography>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                            
                        <Grid xs={4}></Grid>
                        <Grid xs={6} style={{marginTop: '30px'}}>
                            <button type="button" className="btn btn-dark" onClick={doOperation}>CREAR OPERACION</button>
                        </Grid>
                        
                        
                    </Grid>
                </div>
            </div>
            
        </div>
     );

}

export default MesaOperationsLoan;