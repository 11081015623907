import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom"
import Swal from "sweetalert2";
import axios from 'axios'
import { toast } from 'react-toastify';
import Loading from "../../components/loading";
import SecuritieService from "../../services/securitieService";
import ModalNewTicker from "./modals/modalNewTicker"

function Tikcers() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const initialized = useRef(false)
    const [modalOpen, setModalOpen] = useState(false);
    const [tickerSelected, setTickerSelected] = useState('');
    const [listTickers, setListTickers] = useState([])
    const [listTickersLinks, setListTickersLinks] = useState([])
    const [listTickersMeta, setListTickersMeta] = useState([])
    // Search Form
    const [searchName, setSearchName] = useState('')

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true
            doSearch()
        }
    }, [false]);

    const doSearch = async (event) => {
        try {  
            setLoading(true)
            const response = await SecuritieService.getSecuritieSearch({instrumento: searchName})
            setListTickers(response.data)
            setListTickersLinks(response.links)
            setListTickersMeta(response.meta)
        } catch (e) {
            if (axios.isAxiosError(e)) {
                const statusCode = e.response?.status
                const error = e.response?.data.errors;
                
                if(statusCode && statusCode !== 401){
                    Swal.fire({
                        title: 'ERROR',
                        text: error[0].detail,
                        icon: 'error',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#f13939'
                    })
                }else{
                    localStorage.removeItem("userProfile");
                    navigate('/')
                    window.location.reload();
                }
            }
        } finally{ setLoading(false) }
    }

    const doDelete = async (obj) => {
        Swal.fire({
            title: 'Atención!',
            text: "Ud. esta seguro que quiere eliminar la especie seleccionada ?",
            icon: "warning",
            showCancelButton: true,
            // cancelButtonColor: '#efefef',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Eliminar',
            confirmButtonColor: '#f13939',
            reverseButtons: true
          })
          .then(async (result) => {
            if (result.isConfirmed) {               
                try {  
                    setLoading(true)
                    const response = await SecuritieService.deleteTicker(obj.id)
                    toast.success('Especie eliminado!', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    })
                    doSearch()
                } catch (e) {
                    if (axios.isAxiosError(e)) {
                        const statusCode = e.response?.status
                        const error = e.response?.data.errors;
                        
                        if(statusCode && statusCode !== 401){
                            Swal.fire({
                                title: 'ERROR',
                                text: error[0].detail,
                                icon: 'error',
                                confirmButtonText: 'OK',
                                confirmButtonColor: '#f13939'
                            })
                        }else{
                            localStorage.removeItem("userProfile");
                            navigate('/')
                            window.location.reload();
                        }
                    }
                } finally{ setLoading(false) }
            }
          })
    }

    const doPagination = async () => {
        try {  
            setLoading(true)
            const response = await SecuritieService.getPagination(listTickersLinks.next)
            response.data.forEach( data => listTickers.push(data) )
            setListTickersLinks(response.links)
            setListTickersMeta(response.meta)
        } catch (e) {
            if (axios.isAxiosError(e)) {
                const statusCode = e.response?.status
                const error = e.response?.data.errors;
                
                if(statusCode && statusCode !== 401){
                    Swal.fire({
                        title: 'ERROR',
                        text: error[0].detail,
                        icon: 'error',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#f13939'
                    })
                }else{
                    localStorage.removeItem("userProfile");
                    navigate('/')
                    window.location.reload();
                }
            }
        } finally{ setLoading(false) } 
    }

    if(loading){
        return( <Loading></Loading> )
    }

    return (
        <div className="container-fluid flex-grow-1 container-p-y">

            <div className="row">
                <div className="col">
                    <h4 className="fw-bold py-3 mb-2"><span className="text-muted fw-light">
                        Configuración /</span> Especies
                        {listTickersMeta.total > 0 && <span className="fontMedium">({listTickersMeta.total})</span> }
                    </h4>
                </div>
                <div className="col">
                    <div className="btn-group float-end" role="group" aria-label="First group">
                        <button type="button" className="btn btn-outline-secondary" title="Nuevo" onClick={() => setModalOpen(true)}>
                            <i className="tf-icons bx bx-plus" />
                        </button>
                        <button type="button" className="btn btn-outline-secondary" title="Buscar" data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasEnd"
                          aria-controls="offcanvasEnd">
                            <i className="tf-icons bx bx-filter" />
                        </button>
                    </div>
                </div>
            </div>

            <div className="card">

                <div className="table-responsive ">
                    <table className="table table-striped table-hover">
                        <thead>
                            <tr className="text-nowrap">
                                <th>Abreviatura</th>
                                <th>Descripción</th>
                                <th>Moneda</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody className="table-border-bottom-0">
                            {listTickers.map(ticker => {
                                return (
                                    <tr key={ticker.id}>  
                                        <td>{ticker.instrumentoAbrev}</td>
                                        <td>{ticker.instrumentoDesc}</td>
                                        <td>
                                            {ticker.moneda === "ARS" && <span className="badge bg-label-secondary me-1">{ticker.monedaDescripcion}</span>}
                                            {ticker.moneda === "USD" && <span className="badge bg-label-success me-1">{ticker.monedaDescripcion}</span>}
                                            {ticker.moneda === "USDMEP" && <span className="badge bg-label-primary me-1">{ticker.monedaDescripcion}</span>}
                                        </td>
                                        <td className="text-end">
                                            <button type="button" className="btn btn-icon btn-sm  btn-outline-danger"  onClick={() => doDelete(ticker) } ><i className='bx bx-x'></i></button>
                                        </td>
                                        {/* <td className="text-end">
                                            <div className="dropdown">
                                                <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                                                    <i className="bx bx-dots-vertical-rounded" />
                                                </button>
                                                <div className="dropdown-menu">
                                                    <a className="dropdown-item" href="javascript:void(0);"  onClick={() => {  doEdit(ticker) } }>
                                                        <i className="bx bx-edit-alt me-1" /> Editar
                                                    </a>
                                                    <a className="dropdown-item" href="javascript:void(0);"  onClick={() => {  doDelete(ticker) } }>
                                                        <i className="bx bx-trash me-1" /> Eliminar
                                                    </a>
                                                </div>
                                            </div>
                                        </td> */}
                                    </tr>
                                    )
                                })
                            }

                        </tbody>
                    </table>
                </div>

            </div>
            { listTickersLinks.next !== null ?
                    <div className="row mt-4">
                        <div className="col d-grid gap-2 d-md-flex justify-content-md-center">
                        { loading ? 
                            <div className="d-flex justify-content-center">
                                <div className="spinner-border text-info" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        : 
                            <button type="button" className="btn btn-outline-info" onClick={doPagination}>Mas Información</button>
                        }
                        </div>
                    </div>
                :
                    null
            }
            
            {/* SEARCH */}
            <div>
                <div
                    className="offcanvas offcanvas-end"
                    tabIndex="-1"
                    id="offcanvasEnd"
                    aria-labelledby="offcanvasEndLabel"
                >
                    <div className="offcanvas-header">
                        <h5 id="offcanvasEndLabel" className="offcanvas-title">
                            <i className="tf-icons bx bx-filter" /> Buscar
                        </h5>
                        <button
                            type="button"
                            className="btn-close text-reset"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="offcanvas-body ">
                        <form>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="basic-default-fullname">Abreviatura</label>
                                <input type="text" className="form-control" id="basic-default-fullname" placeholder="Abreviatura" 
                                    value={searchName} onChange={(e)=> setSearchName(e.target.value)} />
                            </div>
                        </form>

                        <div className="mt-5">
                            { loading ? 
                                <div className="d-flex justify-content-center">
                                    <div className="spinner-border text-info" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            : 
                                <div>
                                    <button type="button" className="btn btn-info mb-2 d-grid w-100" onClick={doSearch}>Buscar</button>
                                    {/* <button type="button" className="btn btn-outline-secondary d-grid w-100" data-bs-dismiss="offcanvas" >
                                        Cerrar
                                    </button> */}
                                </div>
                            }
                            
                        </div>
                        
                    </div>
                </div>
            </div>
                        
            {/* MODAL */}
            <ModalNewTicker handleOpen={setModalOpen} isOpen={modalOpen}/>

        </div>        
    );

}

export default Tikcers;