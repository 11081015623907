import api from "../api";


const authService = {
    login: async function (email, password, pushToken) {
        const data = { email, password, push_token: pushToken }
        const response = await api.request({
          url: "/auth/login",
          method: "POST",
          data: data
        })
    
        // returning the product returned by the API
        return response.data
    },
    recovery: async function (email) {
        const data = { email, via: 'app' }
        const response = await api.request({
          url: "/auth/recovery",
          method: "POST",
          data: data
        })
    
        // returning the product returned by the API
        return response.data
    },
    setNewPassword: async function (model) {
      const response = await api.request({
        url: "/users/change-password",
        method: "POST",
        data: model
      })

      return response.data
  }

}
  
export default authService;