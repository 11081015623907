import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom"
import Swal from "sweetalert2";
import { NumericFormat } from 'react-number-format';
import Loading from "../../components/loading";
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardActions from '@mui/joy/CardActions';
import CircularProgress from '@mui/joy/CircularProgress';
import Typography from '@mui/joy/Typography';
import SvgIcon from '@mui/joy/SvgIcon';
import Checkbox from '@mui/joy/Checkbox';
import { toast } from 'react-toastify';
import moment from 'moment';
import ModalDashboardOperations from "./modals/modalDashboardOperations";

//SERVICES  
import MiddleOperationService from "../../services/middleOperationService";


function MesaDashboard() {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const initialized = useRef(false)
    const [listEscoOperations, setListEscoOperations] = useState([])
    const [listOperations, setListOperations] = useState([])
    const [listModalOperations, setListModalOperations] = useState([])
    const [totalPesos, setTotalPesos] = useState(0)
    const [totalMep, setTotalMep] = useState(0)
    const [totalCable, setTotalCable] = useState(0)
    const [arrChecked, setArrChecked] = useState([])
    const [nominal, setNominal] = useState(0)
    const [modalOperationOpen, setModalOperationOpen] = useState(false)
    
    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true
            doLoad()
        }
    }, [false]);

    const doLoad = async (event) => {
        try {  
            setLoading(true)
            // ESCO OPERATIONS 
            const responseEsco = await MiddleOperationService.getMiddleOperationsConsolidated()
            setListEscoOperations(responseEsco.data)
            setTotalPesos( sumOperations(responseEsco.data.pesos, "P") )
            setTotalCable( sumOperations(responseEsco.data.cable, "D") )
            setTotalMep( sumOperations(responseEsco.data.mep, "M") )
            sumOperationsNominal(responseEsco.data)
            // MANUAL OPERATIONS
            const response = await MiddleOperationService.getMiddleAvailableOperations()
            setListOperations(response.data)
        } catch (err) {
            if(err.response.status !== 401){
                Swal.fire({
                    title: 'Ooops?',
                    text: err.response.data.errors[0].detail,
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#f13939'
                })
            }else{
                localStorage.removeItem("userProfile");
                navigate('/')
                window.location.reload();
            }
        } finally{ setLoading(false) }
    }
    
    const trgChecked = async (e, obj) => {
        if(obj.currency_id === 1){ // PESOS
            if(e){
                setTotalPesos(totalPesos + (obj.price * obj.qty)*-1)
                arrChecked.push(obj.id)
            }else{
                setTotalPesos(totalPesos - (obj.price * obj.qty)*-1)
                setArrChecked(arrChecked.filter(a => a !== obj.id) )
            }
        }else if(obj.currency_id === 2){ //CABLE
            if(e){
                setTotalCable(totalCable + (obj.price * obj.qty)*-1)
                arrChecked.push(obj.id)
            }else{
                setTotalCable(totalCable - (obj.price * obj.qty)*-1)
                setArrChecked(arrChecked.filter(a => a !== obj.id) )
            }
        }else if(obj.currency_id === 3){ //MEP
            if(e){
                setTotalMep(totalMep + (obj.price * obj.qty)*-1)
                arrChecked.push(obj.id)
            }else{
                setTotalMep(totalMep - (obj.price * obj.qty)*-1)
                setArrChecked(arrChecked.filter(a => a !== obj.id) )
            }
        }
        if(e){
            setNominal(nominal + parseFloat(obj.qty) )
        }else{
            setNominal(nominal - parseFloat(obj.qty))
        }
    }

    const doSave = async () => {
        if(arrChecked.length > 0){
            Swal.fire({
                title: "Atención!",
                text: "Ud. esta seguro que quiere guardar los datos seleccionados ?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Procesar",
                confirmButtonColor: "#83766d",
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    try {
                        setLoading(true)
                        // console.log("ARR: ",arrChecked)
                        const response =  MiddleOperationService.putChangeIsAvailable({
                            ids: arrChecked, 
                            is_available: false, 
                        })
                        .then((response) => {
                            toast.success('Operación agregada!', {
                                position: "top-right",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            })
                            window.location.reload();
                        })
                        .catch((error) => {
                            if(error.response.status !== 401){
                                Swal.fire({
                                    title: 'Ooops?',
                                    text: error.response.data.errors[0].detail,
                                    icon: 'error',
                                    confirmButtonText: 'OK',
                                    confirmButtonColor: '#f13939'
                                })
                            }else{
                                localStorage.removeItem("userProfile");
                                navigate('/')
                                window.location.reload();
                            }
                        })
                    } catch (err) {
                        if(err.response.status !== 401){
                            Swal.fire({
                                title: 'Ooops?',
                                text: err.response.data.errors[0].detail,
                                icon: 'error',
                                confirmButtonText: 'OK',
                                confirmButtonColor: '#f13939'
                            })
                        }else{
                            localStorage.removeItem("userProfile");
                            navigate('/')
                            window.location.reload();
                        }
                    } finally{ 
                        setArrChecked([])
                        setLoading(false) 
                    }
                } 
            });
        }else{
            Swal.fire({
                title: 'Ooops?',
                text: "Debe seleccionar al menos una operación?",
                icon: 'error',
                confirmButtonText: 'OK',
                confirmButtonColor: '#f13939'
            })
        }
    }

    const doViewOperations = async (currency) => {
        if(currency === 'P'){
            setListModalOperations(listEscoOperations.pesos)
        }else if(currency === 'DM'){
            setListModalOperations(listEscoOperations.mep)
        }else if(currency === 'DC'){
            setListModalOperations(listEscoOperations.cable)
        }
        setModalOperationOpen(true)
    }

    const sumOperations = function(arr, currency){
        let total = 0
        arr.forEach( function(item, key) {
            // total = total + parseFloat(item.price) * item.qty
            total = total + parseFloat(item.gross_price)
        });
        return total;
    }
    
    const sumOperationsNominal = function(data){
        let total = 0
        //Pesos
        data.pesos.forEach( function(item, key) {   
            total = total + parseFloat(item.qty)
        });
        //MEP
        data.mep.forEach( function(item, key) {
            total = total + parseFloat(item.qty)
        });
        //CABLE
        data.cable.forEach( function(item, key) {
            total = total + parseFloat(item.qty)
        });
        setNominal( total)
    }
    

    /* if(loading){
        return( <Loading></Loading> )
    } */

    return (
        <div className="container-fluid flex-grow-1 container-p-y">
            
            <div className="row">
                <h4 className="fw-bold py-3 mb-2"><span className="text-muted fw-light">Mesa /</span> Dashboard</h4>
                
                <div className="row">
                    <div className="col-sm-3">
                        <Card variant="solid" color="primary" invertedColors>
                            <CardContent orientation="horizontal">
                                <CircularProgress size="lg" determinate value={20}>
                                    <SvgIcon>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={1.5}
                                            stroke="currentColor"
                                        >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M2.25 18L9 11.25l4.306 4.307a11.95 11.95 0 015.814-5.519l2.74-1.22m0 0l-5.94-2.28m5.94 2.28l-2.28 5.941"
                                        />
                                        </svg>
                                    </SvgIcon>
                                </CircularProgress>
                                <CardContent>
                                    <Typography level="body-md">Neto en Pesos</Typography>
                                    <Typography level="h2"> 
                                        <NumericFormat 
                                            displayType="text" 
                                            value={totalPesos}
                                            decimalScale={4} 
                                            allowLeadingZeros thousandSeparator="." 
                                            decimalSeparator="," 
                                            prefix={'$'}  
                                        /> 
                                    </Typography>
                                </CardContent>
                            </CardContent>
                            <CardActions>
                                <Button variant="soft" size="sm" onClick={() => {doViewOperations('P')}}>Ver Operaciones</Button>
                                {/* <Button variant="solid" size="sm">See breakdown</Button> */}
                            </CardActions>
                        </Card>
                    </div>
                    <div className="col-sm-3">
                        <Card variant="solid" color="success" invertedColors>
                            <CardContent orientation="horizontal">
                                <CircularProgress size="lg" determinate value={20}>
                                <SvgIcon>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                    >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M2.25 18L9 11.25l4.306 4.307a11.95 11.95 0 015.814-5.519l2.74-1.22m0 0l-5.94-2.28m5.94 2.28l-2.28 5.941"
                                    />
                                    </svg>
                                </SvgIcon>
                                </CircularProgress>
                                <CardContent>
                                <Typography level="body-md">Neto en Dolar MEP</Typography>
                                <Typography level="h2">
                                    <NumericFormat 
                                        displayType="text" 
                                        value={totalMep}
                                        decimalScale={4} 
                                        allowLeadingZeros thousandSeparator="." 
                                        decimalSeparator="," 
                                        prefix={'$'}  
                                    /> 
                                </Typography>
                                </CardContent>
                            </CardContent>
                            <CardActions>
                                <Button variant="soft" size="sm" onClick={() => {doViewOperations('DM')}}>Ver Operaciones</Button>
                                {/* <Button variant="solid" size="sm">See breakdown</Button> */}
                            </CardActions>
                        </Card>
                    </div>
                    <div className="col-sm-3">
                        <Card variant="solid" color="warning" invertedColors>
                            <CardContent orientation="horizontal">
                                <CircularProgress size="lg" determinate value={40}>
                                <SvgIcon>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                    >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M2.25 18L9 11.25l4.306 4.307a11.95 11.95 0 015.814-5.519l2.74-1.22m0 0l-5.94-2.28m5.94 2.28l-2.28 5.941"
                                    />
                                    </svg>
                                </SvgIcon>
                                </CircularProgress>
                                <CardContent>
                                <Typography level="body-md">Neto en Dolar Cable</Typography>
                                <Typography level="h2">
                                    <NumericFormat 
                                        displayType="text" 
                                        value={totalCable}
                                        decimalScale={4} 
                                        allowLeadingZeros thousandSeparator="." 
                                        decimalSeparator="," 
                                        prefix={'$'}  
                                    /> 
                                </Typography>
                                </CardContent>
                            </CardContent>
                            <CardActions>
                                <Button variant="soft" size="sm" onClick={() => {doViewOperations('DC')}}>Ver Operaciones</Button>
                                {/* <Button variant="solid" size="sm">See breakdown</Button> */}
                            </CardActions>
                        </Card>
                    </div>
                    <div className="col-sm-3">
                        <Card variant="solid" color="danger" invertedColors>
                            <CardContent orientation="horizontal">
                                <CircularProgress size="lg" determinate value={40}>
                                <SvgIcon>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                    >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M2.25 18L9 11.25l4.306 4.307a11.95 11.95 0 015.814-5.519l2.74-1.22m0 0l-5.94-2.28m5.94 2.28l-2.28 5.941"
                                    />
                                    </svg>
                                </SvgIcon>
                                </CircularProgress>
                                <CardContent>
                                    <Typography level="body-md">Nominal</Typography>
                                    <Typography level="h2">
                                        <NumericFormat 
                                            displayType="text" 
                                            value={nominal}
                                            decimalScale={3} 
                                            allowLeadingZeros thousandSeparator="." 
                                            decimalSeparator="," 
                                        /> 
                                    </Typography>
                                </CardContent>
                            </CardContent>
                            <CardActions>
                                <Button variant="soft" size="sm">Ver Operaciones</Button>    
                            </CardActions>
                        </Card>
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-sm-12">
                        {loading ?  <Loading></Loading>
                            :

                        <Card variant="solid" color="natural" className="card">
                            <CardContent>
                                <div className="row">
                                    <div className="col">
                                        <Typography level="title-md" textColor="inherit"> Operaciones de Mesa</Typography>
                                    </div>
                                    <div className="col text-end">
                                        <button type="button" className="btn btn-icon btn-sm btn-outline-secondary" onClick={doSave}><i className='bx bxs-analyse'></i></button>
                                    </div>
                                </div>
                            </CardContent>
                            <CardContent>
                                <table className="table table-borderless table-sm table-hover" >
                                    <thead style={{backgroundColor: '#FFFFFF'}}>
                                        <tr className="text-nowrap">
                                            <th></th>
                                            <th>Comitente</th>
                                            <th>Fecha</th>
                                            <th>Operación</th>
                                            <th>Tipo</th>
                                            <th>Ticker</th>                                
                                            <th>Moneda</th>                                
                                            <th className="text-end">Cantidad</th>
                                            <th className="text-end">Precio</th>
                                            <th className="text-end">Neto</th>
                                            {/* <th></th> */}
                                        </tr>
                                    </thead>
                                    <tbody className="table-border-bottom-0">
                                        {listOperations.map(operation => {
                                            return (
                                                <tr key={operation.id}>  
                                                    <td>
                                                        <Checkbox
                                                            onChange={(event) => trgChecked(event.target.checked, operation)}
                                                        />
                                                    </td>
                                                    <td>
                                                        <div className="d-flex justify-content-start align-items-center user-name">
                                                            <div className="d-flex flex-column">
                                                                <a className="text-body text-truncate">
                                                                    <small>
                                                                        {operation.account.name.length > 30 ?
                                                                            `${operation.account.name.substring(0, 30)}...` : operation.account.name
                                                                        }
                                                                    </small>
                                                                </a>
                                                                <small className="text-muted"><span className="badge bg-label-dark">{operation.account.account}</span></small>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <small>{moment(operation.created_at).format('DD/MM/YYYY')}</small>
                                                    </td>
                                                    <td>
                                                        <small>
                                                            {operation.middle_operation_type_id === 1 && <span className="badge bg-primary">{operation.middle_operation_type.name}</span>}
                                                            {operation.middle_operation_type_id === 2 && <span className="badge bg-info">{operation.middle_operation_type.name}</span>}
                                                        </small>
                                                    </td>
                                                    <td>
                                                        {operation.operation_type_id === 1 && <span className="badge bg-success"><small>{operation.operation_type.name}</small></span>}
                                                        {operation.operation_type_id === 2 && <span className="badge bg-danger"><small>{operation.operation_type.name}</small></span>}
                                                        
                                                    </td>
                                                    <td><span className="fw-medium"><small>{operation.ticker}</small></span></td>
                                                    <td>
                                                        <small className="text-muted">
                                                            {operation.currency_id === 1 && <span className="badge bg-label-primary me-1">{operation.currency.name}</span>}
                                                            {operation.currency_id === 2 && <span className="badge bg-label-info me-1">{operation.currency.name}</span>}
                                                            {operation.currency_id === 3 && <span className="badge bg-label-success me-1">{operation.currency.name}</span>}
                                                        </small>
                                                    </td>
                                                    
                                                    <td className="text-end">
                                                        <small>
                                                            <NumericFormat 
                                                                displayType="text" 
                                                                value={operation.qty} 
                                                                allowLeadingZeros thousandSeparator="." 
                                                                decimalSeparator="," 
                                                            /> 
                                                        </small>
                                                    </td>
                                                    <td className="text-end">
                                                        <small>
                                                            <NumericFormat 
                                                                displayType="text" 
                                                                value={operation.price} 
                                                                decimalScale={4} 
                                                                allowLeadingZeros thousandSeparator="." 
                                                                decimalSeparator="," 
                                                            /> 
                                                        </small>
                                                    </td>
                                                    <td className="text-end">
                                                        <small>
                                                            <NumericFormat 
                                                                displayType="text" 
                                                                value={operation.gross_price} 
                                                                // value={operation.price * operation.qty} 
                                                                decimalScale={4} 
                                                                allowLeadingZeros thousandSeparator="." 
                                                                decimalSeparator="," 
                                                            /> 
                                                        </small>
                                                    </td>
                                                    {/* <td className="text-end">
                                                        <button type="button" className="btn btn-icon btn-sm  btn-outline-danger "><i className='bx bx-x'></i></button>
                                                    </td> */}
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </CardContent>
                        </Card>
                        }
                    </div>

                    
                </div> 

            </div>

            <ModalDashboardOperations 
                handleOpen={setModalOperationOpen} 
                isOpen={modalOperationOpen} 
                listOperations={listModalOperations}
              
            />

        </div>   
    );

}

export default MesaDashboard;