import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom"
import Swal from "sweetalert2";
// import axios from 'axios'
import moment from 'moment';
import { NumericFormat } from 'react-number-format';
import Loading from "../../components/loading";
import Grid from '@mui/joy/Grid';
// import FormControl from '@mui/joy/FormControl';
// import FormLabel from '@mui/joy/FormLabel';
// import Sheet from '@mui/joy/Sheet';

//SERVICES  
import MiddleOperationService from "../../services/middleOperationService";



function MesaEscoOperation() {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [uploading, setUploading] = useState(false)
    const [sending, setSending] = useState(false)
    const initialized = useRef(false)
    const [listOperations, setListOperations] = useState([])
    const [listOperationLinks, setListOperationLinks] = useState([])
    const [listOperationMeta, setListOperationMeta] = useState([])
    const [file, setFile] = useState();
    
    // Search Form
    let defaultDate = new Date().toISOString().slice(0,10);
    const [searchModel, setSearchModel] = useState({
        dateFrom: defaultDate,
        // dateTo: defaultDate,
        // user: '',
    })

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true
            doSearch()
        }
    }, [false]);

    const doSearch = async (event) => {
        try {  
            setLoading(true)
            const response = await MiddleOperationService.getMiddleOperationsSearch(searchModel)
            setListOperations(response.data)
            setListOperationLinks(response.links)
            setListOperationMeta(response.meta)
        } catch (err) {
            if(err.response.status !== 401){
                Swal.fire({
                    title: 'Ooops?',
                    text: err.response.data.errors[0].detail,
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#f13939'
                })
            }else{
                localStorage.removeItem("userProfile");
                navigate('/')
                window.location.reload();
            }
        } finally{ setLoading(false) }
    }
    
    const trgUpload = async (event) => {
        setFile(event.target.files[0])
    }

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            console.log("FileReader: ",fileReader)
            fileReader.onload = () => { resolve(fileReader.result); }
            fileReader.onerror = (error) => { reject(error);}
        })

    }
    
    const doImport = async () => {
        try {  
            setUploading(true)
            const base64 = await convertBase64(file)
            let tempBase = base64.split(',')
            let model = {  
                file_name: file.name,
                file: 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet/xlsx;base64,'+tempBase[1]
            }
            const response = await MiddleOperationService.importFile(model)
        } catch (err) {
            if(err.response.status !== 401){
                Swal.fire({
                    title: 'Ooops?',
                    text: err.response.data.errors[0].detail,
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#f13939'
                })
            }else{
                localStorage.removeItem("userProfile");
                navigate('/')
                window.location.reload();
            }
        } finally{ setUploading(false) }
    }

    const doPagination = async () => {
        try {  
            setLoading(true)
            const response = await MiddleOperationService.getPagination(listOperationLinks.next)
            response.data.forEach( data => listOperations.push(data) )
            setListOperationLinks(response.links)
            setListOperationMeta(response.meta)
        } catch (err) {
            if(err.response.status !== 401){
                Swal.fire({
                    title: 'Ooops?',
                    text: err.response.data.errors[0].detail,
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#f13939'
                })
            }else{
                localStorage.removeItem("userProfile");
                navigate('/')
                window.location.reload();
            }
        } finally{ setLoading(false) }
    }

/*     if(loading){
        return(
            <Loading></Loading>
        )
    } */


    return (
        <div className="container-fluid flex-grow-1 container-p-y">
            <div className="row">
            
                <div className="row">
                    <div className="col">
                        <h4 className="fw-bold py-3 mb-2"><span className="text-muted fw-light">
                            Mesa /</span> Operaciones de ESCO 
                            {listOperationMeta.total > 0 && <span className="fontMedium">({listOperationMeta.total})</span> }
                        </h4>
                    </div>
                    <div className="col">
                        <div className="btn-group float-end" role="group" aria-label="First group">
                            <button type="button" className="btn btn-outline-secondary" title="Importar" 
                                data-bs-toggle="offcanvas"
                                data-bs-target="#importFile"
                                aria-controls="importFile">
                                <i className='bx bx-cloud-upload'></i>
                            </button>
                            <button type="button" className="btn btn-outline-secondary" title="Buscar" data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasEnd"
                            aria-controls="offcanvasEnd">
                                <i className="tf-icons bx bx-filter" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                {/* <Sheet sx={{ height: 580, overflow: 'auto', backgroundColor: 'transparent', padding: '2px' }} className="table-responsive"> */}
                    <table className="table table-striped table-hover" >
                        <thead style={{backgroundColor: '#FFFFFF'}}>
                            <tr className="text-nowrap">
                                {/* <th>Fecha</th> */}
                                <th>Especie</th>
                                <th>Moneda</th>                                
                                <th className="text-end">Cantidad</th>
                                <th className="text-end">Precio</th>
                                <th className="text-end">Bruto</th>
                                <th className="text-end">Neto Dolares</th>
                                <th className="text-end">Neto Pesos</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody className="table-border-bottom-0">
                            {listOperations.map(operation => {
                                return (
                                    <tr key={operation.id}>  
                                        <td>
                                            <div className="d-flex justify-content-start align-items-center user-name">
                                                <div className="d-flex flex-column">
                                                    <a className="text-body text-truncate">
                                                        <span className="fw-medium">{operation.ticker}</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex justify-content-start align-items-center user-name">
                                                <div className="d-flex flex-column">
                                                    <a className="text-body text-truncate">
                                                        <span className="fw-medium">{operation.operation_type.name}</span>
                                                    </a>
                                                    <small className="text-muted">
                                                        {operation.currency_id === 1 && <span className="badge bg-label-primary me-1">{operation.currency.name}</span>}
                                                        {operation.currency_id === 2 && <span className="badge bg-label-info me-1">{operation.currency.name}</span>}
                                                        {operation.currency_id === 3 && <span className="badge bg-label-success me-1">{operation.currency.name}</span>}
                                                    </small>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="text-end">
                                            <NumericFormat 
                                                displayType="text" 
                                                value={operation.qty} 
                                                allowLeadingZeros thousandSeparator="." 
                                                decimalSeparator="," 
                                            /> 
                                        </td>
                                        <td className="text-end">
                                            <NumericFormat 
                                                displayType="text" 
                                                value={operation.price} 
                                                decimalScale={4} 
                                                allowLeadingZeros thousandSeparator="." 
                                                decimalSeparator="," 
                                                // prefix={'$'}  
                                            /> 
                                        </td>
                                        <td className="text-end">
                                            <NumericFormat 
                                                displayType="text" 
                                                value={operation.gross_price} 
                                                decimalScale={4} 
                                                allowLeadingZeros thousandSeparator="." 
                                                decimalSeparator="," 
                                                // prefix={'$'}  
                                            /> 
                                        </td>
                                        <td className="text-end">
                                            <NumericFormat 
                                                displayType="text" 
                                                value={operation.total_dollar} 
                                                decimalScale={4} 
                                                allowLeadingZeros thousandSeparator="." 
                                                decimalSeparator="," 
                                                // prefix={'$'}  
                                            /> 
                                        </td>
                                        <td className="text-end">
                                            <NumericFormat 
                                                displayType="text" 
                                                value={operation.total_pesos} 
                                                decimalScale={4} 
                                                allowLeadingZeros thousandSeparator="." 
                                                decimalSeparator="," 
                                                // prefix={'$'}  
                                            /> 
                                        </td>
                                        <td className="text-end">
                                            <div className="dropdown">
                                                <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                                                    <i className="bx bx-dots-vertical-rounded" />
                                                </button>
                                                <div className="dropdown-menu">
                                                    {/* <a className="dropdown-item" href="#"  onClick={() => {  doView(operation) } }>
                                                        <i className="bx bx-eyes me-1" /> Ver
                                                    </a> */}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    )
                                })
                            }

                        </tbody>
                    </table>
                {/* </Sheet> */}
            </div>

            { listOperationLinks.next !== null ?
                    <div className="row mt-4">
                        <div className="col d-grid gap-2 d-md-flex justify-content-md-center">
                        { sending ? 
                            <div className="d-flex justify-content-center">
                                <div className="spinner-border text-info" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        : 
                            <button type="button" className="btn btn-outline-info" onClick={doPagination}>Mas Información</button>
                        }
                        </div>
                    </div>
                :
                    null
            }
            
            {/* SEARCH */}
            <div>
                <div
                    className="offcanvas offcanvas-end"
                    tabIndex="-1"
                    id="offcanvasEnd"
                    aria-labelledby="offcanvasEndLabel"
                >
                    <div className="offcanvas-header">
                        <h5 id="offcanvasEndLabel" className="offcanvas-title">
                            <i className="tf-icons bx bx-filter" /> Buscar
                        </h5>
                        <button
                            type="button"
                            className="btn-close text-reset"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                        ></button>
                    </div>

                    <div className="offcanvas-body ">
                        <form>
                            <Grid xs={12} style={{marginBottom: '10px'}}>
                                <label htmlFor="searchDateFrom" className="form-label">Fecha de Importación</label>
                                <input className="form-control" type="date" id="searchDateFrom" 
                                    value={searchModel.dateFrom}
                                    onChange={(e)=> setSearchModel({...searchModel, dateFrom: e.target.value})}
                                />
                            </Grid>
                            {/* <Grid xs={12} style={{marginBottom: '10px'}}>
                                <label htmlFor="searchDateTo" className="form-label">Fecha de Hasta</label>
                                <input className="form-control" type="date" id="searchDateTo" 
                                    value={searchModel.dateTo}
                                    onChange={(e)=> setSearchModel({...searchModel, dateTo: e.target.value})}
                                />
                            </Grid> */}
                        </form>

                        <div className="mt-5">
                            { sending ? 
                                <div className="d-flex justify-content-center">
                                    <div className="spinner-border text-info" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            : 
                                <div>
                                    <button type="button" className="btn btn-info mb-2 d-grid w-100" onClick={doSearch}>Buscar</button>
                                    {/* <button type="button" className="btn btn-outline-secondary d-grid w-100" data-bs-dismiss="offcanvas" >
                                        Cerrar
                                    </button> */}
                                </div>
                            }
                            
                        </div>
                        
                    </div>
                </div>
            </div>

            {/* IMPORT FILE */}
            <div>
                <div
                    className="offcanvas offcanvas-end"
                    tabIndex="-1"
                    id="importFile"
                    aria-labelledby="offcanvasEndLabel"
                >
                    <div className="offcanvas-header">
                        <h5 id="offcanvasEndLabel" className="offcanvas-title">
                            <i className='bx bx-cloud-upload'></i> Importar Archivo
                        </h5>
                        <button
                            type="button"
                            className="btn-close text-reset"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                        ></button>
                    </div>

                    <div className="offcanvas-body ">
                        <form>
                            <Grid xs={12} style={{marginBottom: '10px'}}>
                                <label htmlFor="searchDateTo" className="form-label">Archivo</label>
                                <input className="form-control" type="file" onChange={(e)=> trgUpload(e)}/>
                            </Grid>
                        </form>

                        <div className="mt-5">
                            { uploading ? 
                                <div className="d-flex justify-content-center">
                                    <div className="spinner-border text-info" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            : 
                                <div>
                                    <button type="button" className="btn btn-info mb-2 d-grid w-100" onClick={doImport}>Importar</button>
                                </div>
                            }
                            
                        </div>
                        
                    </div>
                </div>
            </div>
            

        </div>
    );

}

export default MesaEscoOperation;