import React, {useCallback, useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom"
import Swal from "sweetalert2";
import axios from 'axios'
import Divider from '@mui/joy/Divider';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Grid from '@mui/joy/Grid';
import Input from '@mui/joy/Input';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Autocomplete from '@mui/joy/Autocomplete';
import Textarea from '@mui/joy/Textarea';
import Typography from '@mui/joy/Typography';
import Table from '@mui/joy/Table';
import FormHelperText from '@mui/joy/FormHelperText';
import { NumericFormat } from 'react-number-format';
import Loading from "../../components/loading";
import { toast } from 'react-toastify';
import Checkbox from '@mui/joy/Checkbox';
//SERVICES  
import AccountSeervice from "../../services/accountService";
import FundService from "../../services/fundService";
// import ListService from "../../services/listService";


function OperationsFCI() {

    const initPreview = {
        comitente: '',
        type: '',
        fund: '',
        operationType: '',
        quantity: '',
        observations: '',
        is_acdi: false,
    }
    const initOperatioType =[{id: "3", name: "Suscripción"},{id: "4", name: "Rescate"}] 
    const initType =[{id: "1", name: "Monto"},{id: "2", name: "Cuotaparte"},{id: "3", name: "Total"}] 
    
    const navigate = useNavigate();
    const [cargando, setCargando] = useState(true)
    const [listAccounts, setListAccounts] = useState([])
    const [listOpeType, setListOpeType] = useState(initOperatioType)
    const [listFunds, setListFunds] = useState([])
    const [listTypes, setListTypes] = useState([])
    const [previewModel, setPreviewModel] = useState(initPreview)

  
    useEffect(() => {
        if(cargando){
            doLoad()
        }
    }, []);


    const doLoad = useCallback(
        async (event) => {
            try {  
                setPreviewModel(initPreview)
                // Comitentes
                const responseAccount = await AccountSeervice.getAccountAutocomplete()
                setListAccounts(responseAccount.data)  
                //Funds
                const responseFunds = await FundService.getFundsAutocomplete()
                setListFunds(responseFunds.data)  
            } catch (e) {
                if (axios.isAxiosError(e)) {
                    const statusCode = e.response?.status
                    const error = e.response?.data.errors;
                    
                    if(statusCode && statusCode !== 401){
                        Swal.fire({
                            title: 'ERROR',
                            text: error[0].detail,
                            icon: 'error',
                            confirmButtonText: 'OK',
                            confirmButtonColor: '#f13939'
                        })
                    }else{
                        localStorage.removeItem("userProfile");
                        navigate('/')
                        window.location.reload();
                    }
                }
            } finally{
                setCargando(false)
            }
            
        },[]
    )

    const selectComitente = async (obj) => {
        if(obj !== null){
            setPreviewModel({
                ...previewModel,
                comitente: obj,
            })
       }else{
            setPreviewModel({
                ...previewModel,
                comitente: "",
            })
       }   
    }
   
    const selectFund = async (obj) => {
        if(obj !== null){
            setPreviewModel({
                ...previewModel,
                fund: obj,
            })
            //HORA LIMITE
            let d = new Date();
            let now = d.getHours()+':'+d.getMinutes();
            if( obj.limit_date !== null){
                if(now >= obj.limit_date){
                    Swal.fire({
                        title: 'Ooops?',
                        text: "Ud. tenia un plazo hasta las "+obj.limit_date+" para generar la operacion!",
                        icon: 'warning',
                        confirmButtonText: 'OK',
                    })
                }
            }
        }else{
            setPreviewModel({
                ...previewModel,
                fund: "",
            })
        }   
    }

    const trgOperationType = async (obj) => {
        if(obj !== null){
            setPreviewModel({
                ...previewModel,
                operationType: obj,
            })
            if(obj.id === "3"){
                setListTypes([{id: 1, name:"Monto"}])

            }else{
                setListTypes(initType)
            }
       }else{
            setPreviewModel({
                ...previewModel,
                operationType: "",
            })
       }   
    }

    const trgType = async (obj) => {
        if(obj !== null){
            setPreviewModel({
                ...previewModel,
                type: obj,
            })
       }else{
            setPreviewModel({
                ...previewModel,
                type: "",
            })
       }   
    }

    const trgChangeMonto = async (obj) => {
        if(obj !== null){
            if(obj > 0){
                setPreviewModel({
                    ...previewModel, 
                    quantity: obj,
                })
            }else{
                setPreviewModel({
                    ...previewModel, 
                    quantity: '',
                })
            }
        }
    };

    const trgACDI = async (obj) => {
        if(obj !== null){
            setPreviewModel({
                ...previewModel, 
                is_acdi: obj,
            })
        }else{
            setPreviewModel({
                ...previewModel, 
                is_acdi: '',
            })
        }
    };
    

    const validateForm = function(){
        let rta = true;
        if(previewModel.comitente === '' || previewModel.comitente === undefined  || previewModel.comitente === null){ return false; } 
        if(previewModel.type  === '' || previewModel.type === undefined  || previewModel.type === null){ return false; } 
        if(previewModel.operationType === '' || previewModel.operationType === undefined  || previewModel.operationType === null){ return false; } 
        if(previewModel.fund === '' || previewModel.fund === undefined  || previewModel.fund === null){ return false; } 
        if(previewModel.quantity === '' || previewModel.quantity === undefined  || previewModel.quantity === null){ return false; } 
        if(previewModel.is_acdi === '' || previewModel.is_acdi === undefined  || previewModel.is_acdi === null){ return false; } 
        return rta;
    }

    const doOperation = function(){
        try {  
            setCargando(true)
            if(validateForm()){
                // console.log("MODEL: ",previewModel)
                toast.success('Operación creada!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                })
                setPreviewModel(initPreview)
                // window.location.reload();
            }else{
                Swal.fire({
                    title: 'Ooops?',
                    text: 'Debe completar los datos correctamente!',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#f13939'
                })
            }
            
        } catch (e) {
            if (axios.isAxiosError(e)) {
                const statusCode = e.response?.status
                const error = e.response?.data.errors;
                
                if(statusCode && statusCode !== 401){
                    Swal.fire({
                        title: 'ERROR',
                        text: error[0].detail,
                        icon: 'error',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#f13939'
                    })
                }else{
                    localStorage.removeItem("userProfile");
                    navigate('/')
                    window.location.reload();
                }
            }
        } finally{ setCargando(false) }
    }


    if(cargando){
        return(
            <Loading></Loading>
        )
    }

    return(
        <div className="row">
            <div className="col-sm-8" style={{paddingLeft: 0}}>
                <div className="card">
                    <div className="card-body" style={{paddingBottom:0}}>
                        <h6 className="card-subtitle text-muted">Operaciones de Fondos Comunes de Inversión</h6>
                    </div>
                    
                    <Grid className="card-body" container spacing={2} sx={{ flexGrow: 1 }}>        
                        <Grid xs={6}>
                            <FormControl>
                                <FormLabel className="form-labels">Comitente <span style={{color:'red'}}>*</span></FormLabel>
                                <Autocomplete 
                                    autoFocus
                                    options={listAccounts} 
                                    getOptionLabel={option => option.name +' - '+ option.account }
                                    slotProps={{
                                        listbox: {
                                            sx: (theme) => ({
                                            zIndex: theme.vars.zIndex.modal
                                            })
                                        }
                                    }}
                                    placeholder="Seleccione un Comitente" 
                                    onChange={(event, newValue) => { selectComitente(newValue); }}
                                />  
                            </FormControl>
                        </Grid>
                        <Grid xs={6}>
                            <FormControl>
                                <FormLabel className="form-labels">Tipo de Operación <span style={{color:'red'}}>*</span></FormLabel>
                                <Select 
                                    onChange={(event, newValue) => { trgOperationType(newValue); }}
                                >
                                    {listOpeType.map( (obj) => 
                                        <Option value={obj} key={obj.id} label={obj.name}>{obj.name}</Option>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid xs={6}>
                            <FormControl>
                                <FormLabel className="form-labels">Fondo <span style={{color:'red'}}>*</span></FormLabel>
                                <Autocomplete 
                                    options={listFunds} 
                                    getOptionLabel={option => option.name}
                                    slotProps={{
                                        listbox: {
                                            sx: (theme) => ({
                                            zIndex: theme.vars.zIndex.modal
                                            })
                                        }
                                    }}
                                    placeholder="Seleccione un FCI" 
                                    onChange={(event, newValue) => { selectFund(newValue); }}
                                />  
                                {previewModel.fund !== '' ?
                                    <FormHelperText>Hora Limite: {previewModel.fund.limit_date}</FormHelperText>
                                : null
                                }
                                
                            </FormControl>
                        </Grid>
                        <Grid xs={6}>
                            <FormControl>
                                <FormLabel className="form-labels">Tipo <span style={{color:'red'}}>*</span></FormLabel>
                                <Select 
                                    onChange={(event, newValue) => { trgType(newValue); }}
                                >
                                    {listTypes.map( (obj) => 
                                        <Option value={obj} key={obj.id} label={obj.name}>{obj.name}</Option>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid xs={6}>
                            <FormControl>
                                <FormLabel className="form-labels">Cantidad / Monto <span style={{color:'red'}}>*</span></FormLabel>
                                <Input type="number" size="md" placeholder=""
                                    slotProps={{
                                        input: {
                                        min: 0,
                                        },
                                    }} 
                                    onChange={(event) => trgChangeMonto(event.target.value)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={6}>
                            <FormControl>
                                <FormLabel className="form-labels">Pago ACDI <span style={{color:'red'}}>*</span></FormLabel>
                                <Checkbox label="" size="lg" color="neutral" onChange={(event) => trgACDI(event.target.checked)}/>
                            </FormControl>
                        </Grid>
                    
                        <Grid xs={12}>
                            <FormControl>
                                <FormLabel className="form-labels">Observciones</FormLabel>
                                <Textarea
                                    onChange={(event) => { setPreviewModel({...previewModel, observations: event.target.value}) }}
                                    placeholder="Escriba aqui..."
                                    minRows={4}
                                    maxRows={4}
                                />
                            </FormControl>
                        </Grid>

                        <Typography level="body-xs" style={{color:'red'}}>* obligatorios</Typography>
                    </Grid>
                </div>
            </div>
            <div className="col-sm-4">
                <div className="card">
                    <div className="card-body" style={{paddingBottom:0}}>
                        <h6 style={{color:'red'}} className="card-subtitle text-muted">Vista Previa</h6>
                    </div>

                    <Grid className="card-body" container spacing={2} sx={{ flexGrow: 1 }}>
                        <Grid xs={12}>
                            <FormLabel className="form-labels">Comitente</FormLabel>  
                            <Typography level="h2" sx={{ fontSize: 'xl', mb: 0.5 }}>
                                {previewModel.comitente !== '' ? 
                                    previewModel.comitente.account +" - "+ previewModel.comitente.name 
                                : null}
                            </Typography>
                            <Divider />
                        </Grid>

                        <Table>
                            <tbody>
                                <tr key="accountmanager">
                                    <td><span className="form-labels">Oficial de Cuenta</span>  </td>
                                    <td><Typography level="title-sm">{previewModel.comitente !== '' ? previewModel.comitente.portfolio_manager : null}</Typography></td>
                                </tr>
                                <tr key="operatioType">
                                    <td><span className="form-labels">Tipo de Operación</span>  </td>
                                    <td><Typography level="title-sm">{previewModel.operationType !== '' ? previewModel.operationType.name : null}</Typography></td>
                                </tr>
                                <tr key="fondo">
                                    <td><span className="form-labels">Fondo</span>  </td>
                                    <td><Typography level="title-sm">{previewModel.fund !== '' ? previewModel.fund.name : null}</Typography></td>
                                </tr>
                                <tr key="moneda">
                                    <td><span className="form-labels">Moneda</span>  </td>
                                    <td><Typography level="title-sm">{previewModel.fund !== '' ? previewModel.fund.currency.name : null}</Typography></td>
                                </tr>
                                <tr key="plazo">
                                    <td><span className="form-labels">Plazo de Liquidación</span>  </td>
                                    <td>
                                        <Typography level="title-sm">
                                            {previewModel.fund !== '' ? 
                                                previewModel.operationType.id == '3' ? 
                                                    previewModel.fund.subscription_term.name 
                                                :
                                                    previewModel.fund.rescue_term.name 
                                            : null 
                                            }
                                        </Typography>
                                    </td>
                                </tr>
                                <tr key="tipo">
                                    <td><span className="form-labels">Tipo</span>  </td>
                                    <td><Typography level="title-sm">{previewModel.type !== '' ? previewModel.type.name : null}</Typography></td>
                                </tr>
                                <tr key="qty">
                                    <td><span className="form-labels">Cantidad</span>  </td>
                                    <td>
                                        <Typography level="title-sm">
                                        <NumericFormat 
                                                displayType="text" 
                                                value={previewModel.quantity} 
                                                allowLeadingZeros thousandSeparator="." 
                                                decimalSeparator=","
                                            /> 
                                        </Typography>
                                    </td>
                                </tr>
                                {previewModel.operationType.id === '4' ?
                                    <>
                                        <tr key="Cuotaparte">
                                            <td><span className="form-labels">Valor Cuotaparte:</span>  </td>
                                            <td>
                                                <Typography level="title-sm">
                                                <NumericFormat 
                                                        displayType="text" 
                                                        value={previewModel.fund.unit_price} 
                                                        allowLeadingZeros thousandSeparator="." 
                                                        decimalSeparator="," prefix={'$'}  
                                                        decimalScale={previewModel.fund.decimals} 
                                                    /> 
                                                </Typography>
                                            </td>
                                        </tr>
                                        <tr key="total">
                                            <td><span className="form-labels">Total:</span>  </td>
                                            <td>
                                                <Typography level="title-sm">
                                                <NumericFormat 
                                                        displayType="text" 
                                                        value={previewModel.fund.unit_price * previewModel.quantity} 
                                                        allowLeadingZeros thousandSeparator="." 
                                                        decimalSeparator="," prefix={'$'}  
                                                        decimalScale={previewModel.fund.decimals} 
                                                    /> 
                                                </Typography>
                                            </td>
                                        </tr>
                                    </>
                                :   null
                                }
                                
                                <tr key="acdi">
                                    <td><span className="form-labels">Pago ACDI</span></td>
                                    <td>
                                        {previewModel.is_acdi ? "Si" : "No"}
                                    </td>
                                </tr>
                                                         
                            </tbody>
                        </Table>
                        
                        <Grid xs={12}>
                            <button type="button" className="btn btn-dark btn-block" onClick={doOperation}>CREAR OPERACION</button>
                        </Grid>
                        
                        
                    </Grid>
                </div>
            </div>
        </div>

    );


}

export default OperationsFCI;