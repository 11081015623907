import React, {useEffect, useRef, useState} from "react";
import Swal from "sweetalert2";
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import userService from "../../services/userService";
import userService from "../../services/userService";
import roleService from "../../services/roleService";
import {useNavigate} from "react-router-dom"
import UserModalNew from "./modals/userModalNew";
import UserModalEdit from "./userModalEdit";
import UserSearch from "./userSearch"


function Users() {
    const initSearch = {
        role: '',
        email: '',
        name : ''
    }
    const navigate = useNavigate();
    const initialized = useRef(false)
    const [listUsers, setListUsers] = useState([])
    const [listUsersLinks, setListUsersLinks] = useState([])
    const [listUsersMeta, setListUsersMeta] = useState([])
    const [listRoles, setListRoles] = useState([])
    const [userSelected, setUserSelected] = useState('')
    const [sending, setSending] = useState(false)
    const [more, setMore] = useState(false)
    const [searchModel, setSearchModel] = useState(initSearch)
    const [modalOpen, setModalOpen] = useState(false);
    const [modalEditOpen, setModalEditOpen] = useState(false);

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true
            doSearch()
        }
    }, []);

   /*  const doLoad = async () => {
        try {  
            setSending(true)
            const response = await userService.getUsers()
            setListUsers(response.data)
            setListUsersLinks(response.links)
            setListUsersMeta(response.meta)
            const responseRole = await roleService.getRoles()
            setListRoles(responseRole.data)
            setSearchOpen(false)
            setSending(false)
        } catch (e) {
            if (axios.isAxiosError(e)) {
                const statusCode = e.response?.status
                const error = e.response?.data.errors;
                // let message = '';
                
                if(statusCode && statusCode !== 401){
                    Swal.fire({
                        title: 'ERROR',
                        text: error[0].detail,
                        icon: 'error',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#f13939'
                      })
                }else{
                    localStorage.removeItem("userProfile");
                    navigate('/')
                    window.location.reload();
                }
                
              }
              setSending(false)
        }
    } */

    const doSearch = async () => {
        try {  
            setSending(true)
            const response = await userService.getUserSearch(searchModel)
            setListUsers(response.data)
            setListUsersLinks(response.links)
            setListUsersMeta(response.meta)
            const responseRole = await roleService.getRoles()
            setListRoles(responseRole.data)
            setSending(false)
        } catch (e) {
            if (axios.isAxiosError(e)) {
                const statusCode = e.response?.status
                const error = e.response?.data.errors;
                let message = '';
                
                if(statusCode && statusCode !== 401){
                    Swal.fire({
                        title: 'ERROR',
                        text: error[0].detail,
                        icon: 'error',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#f13939'
                    })
                }else{
                    localStorage.removeItem("userProfile");
                    navigate('/')
                    window.location.reload();
                }
            }
            setSending(false)
        }
    }

    const doEdit = async (obj) => {
        if(obj !== null){
            setUserSelected(obj)
            setModalEditOpen(true)
        }
    }

    const doDelete = async (obj) => {
        Swal.fire({
            title: 'Atención!',
            text: "Ud. esta seguro que quiere eliminar el usuario seleccionado ?",
            icon: "warning",
            showCancelButton: true,
            // cancelButtonColor: '#efefef',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Eliminar',
            confirmButtonColor: '#f13939',
            reverseButtons: true
          })
          .then(async (result) => {
            if (result.isConfirmed) {               
                try {  
                    setSending(true)
                    const response = await userService.deleteUser(obj.id)
                    toast.success('Usuario eliminado!', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    })
                    doSearch()
                    setSending(false)
                } catch (e) {
                    if (axios.isAxiosError(e)) {
                        const statusCode = e.response?.status
                        const error = e.response?.data.errors;
                        
                        if(statusCode && statusCode !== 401){
                            Swal.fire({
                                title: 'ERROR',
                                text: error[0].detail,
                                icon: 'error',
                                confirmButtonText: 'OK',
                                confirmButtonColor: '#f13939'
                            })
                        }else{
                            localStorage.removeItem("userProfile");
                            navigate('/')
                            window.location.reload();
                        }
                    }
                    setSending(false)
                }
            }
          })
    }

    const doPassword = async (obj) => {
        Swal.fire({
            title: 'Atención!',
            text: "Está seguro que quiere resetear la contraseña del usuario ?",
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Continuar',
            confirmButtonColor: '#71dd37',
            reverseButtons: true
          })
          .then(async (result) => {
            if (result.isConfirmed) {
             try {  
                setSending(true)
                const model = {
                    name: obj.name,
                    last_name: obj.last_name,
                    email: obj.email,
                    role: obj.roles[0].name,
                    change_password: 1,
                    password_generate: 1,
                    password: ''
                }
                
                const response = await userService.putUser(obj.id, model)
                toast.success('Contraseña actualizada!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                
                setSending(false)
            } catch (e) {
                if (axios.isAxiosError(e)) {
                    const statusCode = e.response?.status
                    const error = e.response?.data.errors;
                    
                    if(statusCode && statusCode !== 401){
                        Swal.fire({
                            title: 'ERROR',
                            text: error[0].detail,
                            icon: 'error',
                            confirmButtonText: 'OK',
                            confirmButtonColor: '#f13939'
                        })
                    }else{
                        localStorage.removeItem("userProfile");
                        navigate('/')
                        window.location.reload();
                    }
                }
                setSending(false)
            }
            }
          })
    }

    const doPagination = async () => {
        try {  
            setMore(true)
            const response = await userService.getUserSearchPagination(listUsersLinks.next)
            response.data.forEach( data => listUsers.push(data) )
            setListUsersLinks(response.links)
            setListUsersMeta(response.meta)
            setMore(false)
        } catch (e) {
            if (axios.isAxiosError(e)) {
                const statusCode = e.response?.status
                const error = e.response?.data.errors;
                
                if(statusCode && statusCode !== 401){
                    Swal.fire({
                        title: 'ERROR',
                        text: error[0].detail,
                        icon: 'error',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#f13939'
                    })
                }else{
                    localStorage.removeItem("userProfile");
                    navigate('/')
                    window.location.reload();
                }
            }
            setMore(false)
        }
    }
   
    return (
       
        <div className="container-fluid flex-grow-1 container-p-y">

            <div className="row">
                {/* <div className="col">
                    <h2>Usuarios  { listUsersMeta.total > 0 && <span className="fontMedium">({listUsersMeta.total})</span> } </h2>
                </div> */}
                <div className="col">
                    <h4 className="fw-bold py-3 mb-2">
                        {/* <span className="text-muted fw-light"> Usuarios </span> */}
                         Usuarios 
                        {listUsersMeta.total > 0 && <span className="fontMedium">({listUsersMeta.total})</span> }
                    </h4>
                </div>
                <div className="col">
                    <div className="btn-group float-end" role="group" aria-label="First group">
                        <button type="button" className="btn btn-outline-secondary" onClick={() => setModalOpen(true)}>
                            <i className="tf-icons bx bx-plus" />
                        </button>
                        <button type="button" className="btn btn-outline-secondary" data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasEnd"
                          aria-controls="offcanvasEnd">
                            <i className="tf-icons bx bx-filter" />
                        </button>
                    </div>
                </div>
            </div>

            { sending ? 
                <div className="row">
                    <div className="col-12 d-grid gap-2 d-md-flex justify-content-md-center">
                        <div className="d-flex justify-content-center">
                            <div className="spinner-border text-info" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            : 
                <div className="card">
                    
                    <div className="table-responsive text-nowrap">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>Nombre / Usuario</th>
                                    <th>Perfil</th>
                                    <th>Estado</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody className="table-border-bottom-0">
                                {listUsers.map(user => {
                                    return (
                                        <tr key={user.id}>
                                            <td>
                                                <div className="d-flex justify-content-start align-items-center user-name">
                                                    <div className="avatar-wrapper">
                                                        <div className="avatar avatar-sm me-3">
                                                            <img src={user.profile_photo_url} alt="avatar" className="w-px-40 h-auto rounded-circle" />
                                                        </div>
                                                    </div>
                                                    <div className="d-flex flex-column">
                                                        <a href="app-user-view-account.html" className="text-body text-truncate">
                                                            <span className="fw-medium">{user.full_name}</span>
                                                        </a>
                                                        <small className="text-muted">{user.email}</small>
                                                    </div>
                                                </div>
                                            </td>
                                            <td> {user.roles.map((role) => role.name)}</td>
                                            <td>
                                                {user.email_verified_at === '' && <span className="badge bg-label-warning me-1">Pendiente</span>}
                                                {user.email_verified_at !== '' && <span className="badge bg-label-success me-1">Verificado</span>}
                                            </td>
                                            <td className="text-end">
                                                <div className="dropdown">
                                                <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                                                    <i className="bx bx-dots-vertical-rounded" />
                                                </button>
                                                <div className="dropdown-menu">
                                                    <a className="dropdown-item" href="javascript:void(0);"  onClick={() => {  doEdit(user) } }><i className="bx bx-edit-alt me-1" /> Editar</a>
                                                    <a className="dropdown-item" href="javascript:void(0);"  onClick={() => {  doPassword(user) } }><i className="bx bx-refresh me-1" /> Contraseña</a>
                                                    <a className="dropdown-item" href="javascript:void(0);"  onClick={() => {  doDelete(user) } }>
                                                        <i className="bx bx-trash me-1" /> Eliminar
                                                    </a>
                                                </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}   
                            </tbody>
                        </table>
                    </div>
                </div>
            }
                { listUsersLinks.next !== null && !sending ?
                    <div className="row mt-4">
                        <div className="col d-grid gap-2 d-md-flex justify-content-md-center">
                        { more ? 
                            <div className="d-flex justify-content-center">
                                <div className="spinner-border text-info" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        : 
                            <button type="button" className="btn btn-outline-info" onClick={doPagination}>Mas Información</button>
                        }
                        </div>
                    </div>
                :
                    <span></span>
                }
            
            {/* SEARCH */}
            <UserSearch  handleOpen={doSearch} mainModel={searchModel} handleModel={setSearchModel} roles={listRoles}/>

            {/* MODAL */}
            <UserModalNew  handleOpen={setModalOpen} isOpen={modalOpen} listRoles={listRoles} />
            { userSelected !== '' ?
                <UserModalEdit  
                    handleOpen={setModalEditOpen} 
                    isOpen={modalEditOpen}
                    selected={userSelected}
                    handleSelected={setUserSelected}
                    listRoles={listRoles}
                />
            :
                null
            }
        </div>        
         
    );

}

export default Users;