import api from "../apiapp";

const accountService = {
    getAccountAutocomplete: async function () {
      const response = await api.request({
        url: 'accounts?sort=name&page[size]=100000&filter[status]=1',
        method: "GET",
      })
      
      return response.data
    },
    getAccountSearch: async function (model) {
      let filters = '';
      if(model.name != ""){ filters += '&filter[name]='+model.name; }
      if(model.account != null){ filters += '&filter[account]='+model.account; }
      if(model.status != null){ filters += '&filter[status]='+model.status; }
      const response = await api.request({
        url: 'accounts?page[size]=100'+filters,
        method: "GET",
      })
      
      return response.data
    },
    setAccount: async function (model) {
      const response = await api.request({
        url: 'accounts/',
        method: "POST",
        data: model
      })
      
      return response.data
    },
 

}
  
export default accountService;