import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom"
import Swal from "sweetalert2";
import moment from 'moment';
import { NumericFormat } from 'react-number-format';
import Loading from "../../components/loading";
import Grid from '@mui/joy/Grid';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';

//SERVICES  
import MiddleOperationService from "../../services/middleOperationService";


function ReportOperationMiddle() {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const initialized = useRef(false)
    const [listOperations, setListOperations] = useState([])
    const [listOperationLinks, setListOperationLinks] = useState([])
    const [listOperationMeta, setListOperationMeta] = useState([])

    // Search Form
    let defaultDate = new Date().toISOString().slice(0,10);
    const [searchModel, setSearchModel] = useState({
        // account: '',
        status: '',
        dateFrom: defaultDate,
        // dateTo: defaultDate,
        // user: '',
    })

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true
            doSearch()
        }
    }, [false]);

    const doSearch = async (event) => {
        try {  
            setLoading(true)
            // MANUAL OPERATIONS
            const response = await MiddleOperationService.getMiddleOperations(searchModel)
            setListOperations(response.data)
            setListOperationLinks(response.links)
            setListOperationMeta(response.meta)
        } catch (err) {
            if(err.response.status !== 401){
                Swal.fire({
                    title: 'Ooops?',
                    text: err.response.data.errors[0].detail,
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#f13939'
                })
            }else{
                localStorage.removeItem("userProfile");
                navigate('/')
                window.location.reload();
            }
        } finally{ setLoading(false) }
    }
   
    const doDelete = async (id) => {
        
        Swal.fire({
            title: "Atención!",
            text: "Ud. esta seguro que quiere eliminar la operación?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Eliminar",
            confirmButtonColor: "#83766d",
            reverseButtons: true
        }).then((result) => {if (result.isConfirmed) {
            alert(id)
                /* try {
                    setLoading(true)
                    console.log("ARR: ",arrChecked)
                    // setArrChecked([])
                    // const response =  MiddleOperationService.putChangeAgreementDates({})
                } catch (err) {
                    if(err.response.status !== 401){
                        Swal.fire({
                            title: 'Ooops?',
                            text: err.response.data.errors[0].detail,
                            icon: 'error',
                            confirmButtonText: 'OK',
                            confirmButtonColor: '#f13939'
                        })
                    }else{
                        localStorage.removeItem("userProfile");
                        navigate('/')
                        window.location.reload();
                    }
                } finally{ setLoading(false) } */
            } 
        });
            
    }

    const doPagination = async () => {
        try {  
            setLoading(true)
            const response = await MiddleOperationService.getPagination(listOperationLinks.next)
            response.data.forEach( data => listOperations.push(data) )
            setListOperationLinks(response.links)
            setListOperationMeta(response.meta)
        } catch (err) {
            if(err.response.status !== 401){
                Swal.fire({
                    title: 'Ooops?',
                    text: err.response.data.errors[0].detail,
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#f13939'
                })
            }else{
                localStorage.removeItem("userProfile");
                navigate('/')
                window.location.reload();
            }
        } finally{ setLoading(false) }
    }

    return (

        <div className="container-fluid flex-grow-1 container-p-y">
            <div className="row">
            
                <div className="row">
                    <div className="col">
                        <h4 className="fw-bold py-3 mb-2"><span className="text-muted fw-light">
                        Reportes /</span> Operaciones Mesa
                            {listOperationMeta.total > 0 && <span className="fontMedium">({listOperationMeta.total})</span> }
                        </h4>
                    </div>
                    <div className="col">
                        <div className="btn-group float-end" role="group" aria-label="First group">
                            <button type="button" className="btn btn-outline-secondary" title="Buscar" data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasEnd"
                            aria-controls="offcanvasEnd">
                                <i className="tf-icons bx bx-filter" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {loading ?  
                <Loading></Loading>
            :
            <>
            <div className="row">
                <table className="table table-striped table-hover" >
                    <thead style={{backgroundColor: '#FFFFFF'}}>
                        <tr className="text-nowrap">
                            <th>Fecha</th>
                            <th>Comintente</th>
                            <th>Operación</th>
                            <th>Tipo Operación</th>
                            <th>Ticker</th>
                            <th className="text-end">Cantidad</th>
                            <th className="text-end">Precio</th>
                            <th className="text-end">Neto</th>
                            <th>Estado</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody className="table-border-bottom-0">
                        {listOperations.map(operation => {
                            return (
                                <tr key={operation.id}>  
                                    <td>{moment(operation.created_at).format('DD/MM/YYYY')}</td>
                                    <td>
                                        <div className="d-flex justify-content-start align-items-center user-name">
                                            <div className="d-flex flex-column">
                                                <a className="text-body text-truncate">
                                                    <span className="fw-medium">
                                                        {operation.account.name.length > 30 ?
                                                            `${operation.account.name.substring(0, 30)}...` : operation.account.name
                                                        }
                                                    </span>
                                                </a>
                                                <small className="text-muted"><span className="badge bg-label-dark">{operation.account.account}</span></small>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        {operation.middle_operation_type_id === 1 && <span className="badge bg-primary">{operation.middle_operation_type.name}</span>}
                                        {operation.middle_operation_type_id === 2 && <span className="badge bg-info">{operation.middle_operation_type.name}</span>}
                                    </td>
                                    <td>
                                        {operation.operation_type_id === 1 && <span className="badge bg-success">{operation.operation_type.name}</span>}
                                        {operation.operation_type_id === 2 && <span className="badge bg-danger">{operation.operation_type.name}</span>}
                                    </td>
                                    <td>
                                        <div className="d-flex justify-content-start align-items-center user-name">
                                            <div className="d-flex flex-column">
                                                <a className="text-body text-truncate">
                                                    <span className="fw-medium">{operation.ticker}</span>
                                                </a>
                                                {operation.currency_id === 1 && <span className="badge bg-label-primary me-1">{operation.currency.name}</span>}
                                                {operation.currency_id === 2 && <span className="badge bg-label-info me-1">{operation.currency.name}</span>}
                                                {operation.currency_id === 3 && <span className="badge bg-label-success me-1">{operation.currency.name}</span>}
                                            </div>
                                        </div>
                                    </td>
                                    <td className="text-end">
                                        <NumericFormat 
                                            displayType="text" 
                                            value={operation.qty} 
                                            allowLeadingZeros thousandSeparator="." 
                                            decimalSeparator="," 
                                        /> 
                                    </td>
                                    <td className="text-end">
                                        <NumericFormat 
                                            displayType="text" 
                                            value={operation.price} 
                                            decimalScale={4} 
                                            allowLeadingZeros thousandSeparator="." 
                                            decimalSeparator="," 
                                        /> 
                                    </td>
                                    <td className="text-end">
                                        <NumericFormat 
                                            displayType="text" 
                                            value={operation.price * operation.qty} 
                                            decimalScale={4} 
                                            allowLeadingZeros thousandSeparator="." 
                                            decimalSeparator="," 
                                        /> 
                                    </td>
                                    <td>
                                        {operation.is_available && <span className="badge bg-label-success me-1">DISPONIBLE</span>}
                                        {!operation.is_available && <span className="badge bg-label-danger me-1">NO DISPONIBLE</span>}
                                    
                                    </td>
                                    <td className="text-end">
                                        {operation.is_available && <button type="button" className="btn btn-icon btn-sm  btn-outline-danger" onClick={() => {  doDelete(operation.id) } }><i className='bx bx-x'></i></button> }
                                    </td>
                                    {/* <td className="text-end">
                                        <div className="dropdown">
                                            <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                                                <i className="bx bx-dots-vertical-rounded" />
                                            </button>
                                            <div className="dropdown-menu">
                                                <a className="dropdown-item" href="#"  onClick={() => {  doView(operation) } }>
                                                    <i className="bx bx-eyes me-1" /> Ver
                                                </a>
                                            </div>
                                        </div>
                                    </td> */}
                                </tr>
                                )
                            })
                        }

                    </tbody>
                </table>
            
            </div>
            </>
            }
            { listOperationLinks.next !== null ?
                    <div className="row mt-4">
                        <div className="col d-grid gap-2 d-md-flex justify-content-md-center">
                        { 
                            !loading && <button type="button" className="btn btn-outline-info" onClick={doPagination}>Mas Información</button>
                        }
                        </div>
                    </div>
                :
                    null
            }
            
            {/* SEARCH */}
            <div>
                <div
                    className="offcanvas offcanvas-end"
                    tabIndex="-1"
                    id="offcanvasEnd"
                    aria-labelledby="offcanvasEndLabel"
                >
                    <div className="offcanvas-header">
                        <h5 id="offcanvasEndLabel" className="offcanvas-title">
                            <i className="tf-icons bx bx-filter" /> Buscar
                        </h5>
                        <button
                            type="button"
                            className="btn-close text-reset"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                        ></button>
                    </div>

                    <div className="offcanvas-body ">
                        <form>
                            <Grid xs={12} style={{marginBottom: '10px'}}>
                                <label htmlFor="searchDateFrom" className="form-label">Fecha</label>
                                <input className="form-control" type="date" id="searchDateFrom" 
                                    value={searchModel.dateFrom}
                                    onChange={(e)=> setSearchModel({...searchModel, dateFrom: e.target.value})}
                                />
                            </Grid>
                            <Grid xs={12} style={{marginBottom: '10px'}}>
                                <FormControl>
                                    <FormLabel className="form-labels">Estados</FormLabel>
                                        <select className="form-select" 
                                            value={searchModel.status}
                                            onChange={(e)=> setSearchModel({...searchModel, status: e.target.value})}
                                        >
                                            <option value="">---- Todos los Estados ----</option>
                                            <option value="1">Disponible</option>
                                            <option value="0">No Disponible</option>
                                            
                                        </select> 
                                </FormControl>
                            </Grid>
                           
                        </form>

                        <div className="mt-5">
                            { sending ? 
                                <div className="d-flex justify-content-center">
                                    <div className="spinner-border text-info" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            : 
                                <div>
                                    <button type="button" className="btn btn-info mb-2 d-grid w-100" onClick={doSearch}>Buscar</button>
                                </div>
                            }
                            
                        </div>
                        
                    </div>
                </div>
            </div>
            
            {/* { bonoSelected !== '' ?
                <ModalViewBonos 
                    handleOpen={setModalViewOpen} 
                    isOpen={modalViewOpen} 
                    selected={bonoSelected}
                    fundId={bonoSelected.id}
                />
            :
                null
            } */}

        </div>
     );

}

export default ReportOperationMiddle;