import api from "../apiapp";


const userService = {
    getUsers: async function (email, password, pushToken) {

        const response = await api.request({
          url: "/users?include=statementSignatures,roles&sort=name&page[size]=30&filter[active]=1",
          method: "GET",
        })

        return response.data
    },
    getUserSearch: async function (model) {
      var filters = '';
      if(model.email !== ""){ filters += '&filter[email]='+model.email; }
      if(model.name !== ""){ filters += '&filter[name]='+model.name; }
      if(model.role !== ""){ filters += '&filter[role]='+model.role; }
        
        const response = await api.request({
          url: "/users?include=statementSignatures,roles&sort=name&page[size]=30&filter[active]=1"+filters,
          method: "GET",
        })
    
        return response.data
    },
    getUserSearchPagination: async function (url) {
        const response = await api.request({
          url: url,
          method: "GET",
        })
    
        return response.data
    },
    deleteUser : async function (id) {
      const response = await api.request({
        url: "users/enable-disable/"+id,
        method: "PUT",
        data: {}
      })
  
      return response.data
    },
    putUser : async function (id, model) {
      const response = await api.request({
        url: "users/"+id,
        method: "PUT",
        data: model
      })

      return response.data
    },
    setUsers: async function (model) {
      const response = await api.request({
        url: "users/",
        method: "POST",
        data: model
      })
      return response.data
    },
    setNewPassword: async function (model) {
      const response = await api.request({
        url: "users/change-password",
        method: "POST",
        data: model
      })
      return response.data
    },
    

}
  
export default userService;